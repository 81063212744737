import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import Employeeeditform from "./Employeeeditform";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

Employeeeditform.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Employeeedit(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});

  return (
    <>
    <Button variant="outlined"  onClick={handleClickOpen}  startIcon={<EditIcon />}>
    <div fontSize="15px">&nbsp;EDIT PROFILE</div>
</Button>

      <Employeeeditform
        open={open}
        onClose={handleClose}
        KEYID={props.passdata}
        parentCallback={callback}
      />
    </>
  );
}