const Url = 'http://localhost:5000'
const themeDefault = 'dark'
const brandname= "NISHA NIGHTIES"
const shopshortcode = "NN"
const shop1addressary=[{
    shopname:"NISHA NIGHTIES",
    al1:"No.:H-II 20, Railway Station Road,",
    al2:"Pudukkottai - 622001.",
    mobile:"Mobile: 96292 58131, 93448 23456",
    gstin:"GSTIN: 33BQSPS6489Q1ZE"}]
    const website="https://nishanighties.com"
export { Url, brandname, shopshortcode, shop1addressary, website }