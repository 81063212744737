import { React, useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";
import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc, getDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import PaginationComp from '../components/PaginationComp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { format } from 'date-fns'
import Employeedelete from "./Employeedelete";
import Employeeedit from "./Employeeedit";
import Employeeprofile from "./Employeeprofile";
import { Link } from 'react-router-dom'

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { capitalize } from '@material-ui/core';
import { upperCase,titleCase } from "text-case";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Employeeadd2 from "./Employeeadd2";

function Employeeslist() {



    const [data, setData] = useState([]);

    async function pullDataFromFirebase() {
        const colRef = query(collection(db, "Employees"),orderBy('name'));
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(JSON.stringify(data))
      }
      useEffect(() => {
          pullDataFromFirebase();    
      },[])

      const [currentPage, setCurrentPage] = useState(1)
      const [search, setSearch] = useState('')



    const SearchedEmployeelist = search ? data.filter(item => ((item.name.toLowerCase().includes(search.toLowerCase())))) : data
    const filteredEmployeelist = SearchedEmployeelist
    const pageSize = 15
    const totalItems = filteredEmployeelist.length
    const totalPages = Math.ceil((totalItems / pageSize))
    const startIndex = (currentPage - 1) * pageSize
    let pageNumbers = []
    for (let i = 1; i <= totalPages; i++)
    {
        pageNumbers.push(i)
    }
    const paginatedEmployeelist = filteredEmployeelist.slice(startIndex, startIndex+pageSize)

    const callback = useCallback((c) => {
        console.log(c)
        setCurrentPage(c)
    })
 
    let delay_index = -1;

    return (
        <>
        <Employeeadd2 />
        <div className="py-1 px-4 text-4xl">Employee Search</div>

<div className="px-4 py-2 flex justify-between space-x-4 flex-wrap">
<div className="flex space-x-2 items-center">
<div className="text-lg">Employee Name:</div>
<div>
<TextField size="small" autoComplete="off"  onChange={(e) => setSearch(e.target.value)}
           label="Search Employee" variant="outlined" />
</div>
</div>
</div>





<div className="flex flex-wrap justify-center">
  
    {/* <div>{data.length}</div>
    <div>{data.filter(el => el.employee==="individual").length}</div>
    <div>{data.filter(el => el.employee==="company").length}</div>
    <div>{data.filter(el => el.employeetype==="regular").length}</div>
    <div>{data.filter(el => el.employeetype==="prime").length}</div>
    <div>{data.filter(el => el.employeetype==="institute").length}</div>
    <div>{data.filter(el => el.employeetype==="wholesale").length}</div>
    <div>{paginatedEmployeelist.length}</div>
    <div>{paginatedEmployeelist.filter(el => el.employee==="individual").length}</div>
    <div>{paginatedEmployeelist.filter(el => el.employee==="company").length}</div>
    <div>{paginatedEmployeelist.filter(el => el.employeetype==="regular").length}</div>
    <div>{paginatedEmployeelist.filter(el => el.employeetype==="prime").length}</div>
    <div>{paginatedEmployeelist.filter(el => el.employeetype==="institute").length}</div>
    <div>{paginatedEmployeelist.filter(el => el.employeetype==="wholesale").length}</div> */}

                            {paginatedEmployeelist.map((el, index) => {
                                             delay_index += (index!==0) ? 10 : 0;
                                             const delay = Math.max(0, delay_index*100);
                                            return(
                                                <Grow key={el.KEYID}
                                          in={true}
                                          style={{ transformOrigin: '0 0 0' }}
                                          {...({ timeout: 1000+delay })}
                                        >
                                         <Card key={el.KEYID} className="w-[370px] m-1 border border-indigo-500" >
                                      <CardContent sx={{ p:1, '&:last-child': { pb: 1 }}}>
                                          <div className="flex flex-row justify-between items-center h-[35px]">
                                              <div className="flex flex-row items-center">
                                               <div className="pt-1 pl-2 font-bold text-sm " >{upperCase(el.name)}</div> 
                                                </div>
                                              <div>
                                {/* <Employeeedit index={index} passdata={el.KEYID} /> */}
                                {/* <IconButton sx={{ p:1}}  component={Link} to= {'/employeeprofile/'+el.KEYID }
 color="secondary" aria-label="Edit" size="small">
  <EditIcon fontSize="small" />
</IconButton>  */}
                                {/* <Employeeprofile index={index} passdata={el.KEYID} /> */}
                                <Employeedelete index={index} passdata={el.KEYID} />
                                </div>
                                          </div>
                                          <hr className="my-1"/>
                                          <div className="flex flex-row justify-between">                                         
                                      <div>{capitalize(el.title)+'. '+titleCase(el.name)}</div>
                                      <div>{capitalize(el.gender)}</div>
                                      </div>
                                      {el.dob2}<br/>
                                      <div className="flex flex-row justify-between">
                                      </div>
                                      <div className="flex flex-row justify-between">
                                      <div>{el.mobilenumber}</div>
                                      <div>{el.email}</div>
                                      </div>
                                      </CardContent>
                                      <CardActions>
                                        <div className="flex justify-between w-[100%]">
                                      <Employeeedit index={index} passdata={el.KEYID} />
                                        <Button variant="outlined"  component={Link} to= {'/employeeprofile/'+el.KEYID }  startIcon={<VisibilityIcon />}>
    <div fontSize="15px">&nbsp;VIEW PROFILE</div>
</Button>
</div>
                                      </CardActions>
                                    </Card>
                                        </Grow>
                                
                                            )
                            })}
                        </div>

            <PaginationComp passeddata={pageNumbers.length} parentCallback={callback}/>

            </>
    );
}

export default Employeeslist