import { useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import {db} from "../firebase-config";
import { numbertoCurrency } from "../components/NumbertoCurrency";

function Taxsummarybill(props) {
    const [taxtype, setTaxtype] = useState(props.taxtype);
    const [taxtypezr, setTaxtypezr] = useState("0.00");
    const [taxtypefv, setTaxtypefv] = useState("0.00");
    const [taxtypetw, setTaxtypetw] = useState("0.00");
    const [taxtypeen, setTaxtypeen] = useState("0.00");
    const [taxtypete, setTaxtypete] = useState("0.00");
    const [taxtotal, setTaxtotal] = useState("0.00");

    async function pullDataFromFirebase() {
console.log("Taxsummarybillcalled")

const q = query(collection(db, "SHOP1BILLS",props.billdate,props.billnumber,"CART1","PRODUCTS"));
const unsubscribe = onSnapshot(q, (querySnapshot) => {
  let sum0 = 0;
  let sum5 = 0;
  let sum12 = 0;
  let sum18 = 0;
  let sum28 = 0;
  let sumtot = 0;
  querySnapshot.forEach((doc) => {
    sumtot=sumtot+doc.data().taxamount;
    doc.data().gst ===5 ? sum5=sum5+doc.data().taxamount :
    doc.data().gst ===12 ? sum12=sum12+doc.data().taxamount :
    doc.data().gst ===18 ? sum18=sum18+doc.data().taxamount :
    doc.data().gst ===28 ? sum28=sum28+doc.data().taxamount : sum0=sum0+doc.data().taxamount
  });
  setTaxtypezr(sum0.toFixed(2));
  setTaxtypefv(sum5.toFixed(2));
  setTaxtypetw(sum12.toFixed(2));
  setTaxtypeen(sum18.toFixed(2));
  setTaxtypete(sum28.toFixed(2));
  setTaxtotal(sumtot.toFixed(2));
});

return () => {
  unsubscribe();
}

}

useEffect(() => {
  pullDataFromFirebase();
}, [props.billdate,props.billnumber])


  return (
    <>
  {taxtype==="GST" && <div className="flex flex-col w-[560px]">
  <div className="font-medium">GST Summary</div>
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
      {taxtotal!=="0.00" && <table className="min-w-full border text-center">
          <thead className="border-b">
            <tr>
              <th scope="col" rowSpan="2" className=" border-r">
                GST
              </th>
              <th scope="col" colSpan="2" className=" border-r">
                Central Tax
              </th>
              <th scope="col" colSpan="2" className=" border-r">
                State Tax
              </th>
              <th scope="col" rowSpan="2" className="">
                Total<br/>Tax Amount
              </th>
            </tr>
            <tr>
                        <th scope="col" className="border-r border-t">Rate</th>
                        <th scope="col" className="border-r border-t">Amount</th>
                        <th scope="col" className="border-r border-t">Rate</th>
                        <th scope="col" className="border-r border-t">Amount</th>
                      </tr>
          </thead>
          <tbody>
          {taxtypefv!=="0.00" && <tr className="bg-white border-b">
              <td className=" whitespace-nowrap  border-r">5%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                2.5%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypefv/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               2.5%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypefv/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypefv}
              </td>
            </tr>}
            {taxtypetw!=="0.00" && <tr className="bg-white border-b">
              <td className=" whitespace-nowrap  border-r">12%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                6%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypetw/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               6%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypetw/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypetw}
              </td>
            </tr>}
            {taxtypeen!=="0.00" && <tr className="bg-white border-b">
              <td className=" whitespace-nowrap  border-r">18%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                9%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypeen/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               9%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypeen/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypeen}
              </td>
            </tr>}
            {taxtypete!=="0.00" && <tr className="bg-white border-b">
              <td className=" whitespace-nowrap  border-r">28%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                14%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypete/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               14%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypete/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypete}
              </td>
            </tr>}
            <tr className="bg-white border-b">
              <td colSpan="5" className="text-sm text-gray-900 whitespace-nowrap text-center border-r">
                Total
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtotal}
              </td>
            </tr>
          </tbody>
        </table>}
      </div>
    </div>
  </div>
</div>}

{taxtype==="IGST" && <div className="flex flex-col w-[560px]">
<div className="font-medium">GST Summary</div>
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table style={taxtotal==="0.00" ? {display: 'none'} : {}} className="min-w-full border text-center">
          <thead className="border-b">
            <tr>
              <th scope="col" colSpan="2" className=" border-r">
                IGST
              </th>
              <th scope="col" rowSpan="2" className="">
                Total<br/>Tax Amount
              </th>
            </tr>
            <tr>
                        <th scope="col" className="border-r border-t">Rate</th>
                        <th scope="col" className="border-r border-t">Amount</th>
                      </tr>
          </thead>
          <tbody>
            {taxtypefv!=="0.00" && <tr className="bg-white border-b">
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                5
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypefv}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypefv}
              </td>
            </tr>}
            {taxtypetw!=="0.00" && <tr className="bg-white border-b">
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               12%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypetw}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypetw}
              </td>
            </tr>}
            {taxtypeen!=="0.00" && <tr className="bg-white border-b">
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               18%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypeen}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypeen}
              </td>
            </tr>}
            {taxtypete!=="0.00" && <tr className="bg-white border-b">
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               28%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypete}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypete}
              </td>
            </tr>}
            <tr className="bg-white border-b">
              <td colSpan="2" className="text-sm text-gray-900 whitespace-nowrap text-center border-r">
                Total
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtotal}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>}

</>
  )
}

export default Taxsummarybill;