import { useEffect, useState } from "react";
import { collection, onSnapshot, doc, getDocs, setDoc, updateDoc, deleteDoc, writeBatch, increment, getDoc, Timestamp } from "firebase/firestore";

import { db } from "../firebase-config";
import toast from "react-hot-toast";
import { useRef } from 'react';
import moment from 'moment';
import Billsearch from "../bills/Billsearch";
import Billdialog from "../bills/Billdialog";
import Footnotesinclude from "./Footnotesinclude";

function Billsubmit(props) {
    let btnRef = useRef();

    const batch1 = writeBatch(db);
    const batch2 = writeBatch(db);
    const batch3 = writeBatch(db);


    const DATE = moment().format("DD-MM-YYYY").toString();

    const [taxtype, setTaxtype] = useState('');
    const [customertype, setCustomertype] = useState('');
    const [shoptype, setShoptype] = useState('');
    const [billnumber, setBillnumber] = useState('');
    const [billnumbero, setBillnumbero] = useState('');


    const [billid, setBillid] = useState('');

    const [finished, setFinished] = useState(false);


    async function updateBillid() {

        const querySnapshot = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
        querySnapshot.forEach(async (docz) => {

            const prodRef = doc(db, "Products", docz.id);
            const docSnap = await getDoc(prodRef)
            if (docSnap.exists()) {

                let discval = ((customertype === 'REGULAR') ? (docSnap.data().discountregular) :
                    (customertype === 'PRIME') ? (docSnap.data().discountprime) :
                        (customertype === 'INSTITUTE') ? (docSnap.data().discountinstitute) :
                            (customertype === 'WHOLESALE') ? (docSnap.data().discountwholesale) : 0);

                if (!discval) { discval = 0 } //undefined to 0

                if (docz.data().ismanualdiscount == false) {
                    const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", docz.id);
                    setDoc(docRef, {
                        custtypediscount: discval,
                        amount: (docz.data().rate - discval) * docz.data().qnty,
                        discount: docz.data().qnty * discval,
                        taxamount: ((docz.data().rate - discval) * docz.data().qnty) - ((((docz.data().rate - discval) * docz.data().qnty)) * (100 / (100 + docz.data().gst))),
                        modified: Timestamp.now()
                    }, { merge: true })
                }
                else {
                    const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", docz.id);
                    setDoc(docRef, {
                        custtypediscount: discval,
                    }, { merge: true })
                }
            }
        })

    }

    async function pullBillIdFromFirebase() {
        const docRef = doc(db, "SHOP1LIVECART", "CART1");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setTimeout(() => {
                setShoptype(docSnap.data().shoptype)
                setCustomertype(docSnap.data().customertype)
                setTaxtype(docSnap.data().taxtype)
                //setBillnumber(docSnap.data().billnumber)
                updateBillnumbero();
                updateBillnumber();
                setBillid(shoptype.slice(0, 1) + shoptype.slice(-1) + customertype.slice(0, 1) + taxtype.slice(0, 1) + billnumber)
            }, 2000)

        } else {
            console.log("No such document!")
        }
    }

    async function updateBillnumbero() {
        if (taxtype === 'GST') {
            onSnapshot(doc(db, "Counters", "billcounter"), (doc) => {
                setBillnumbero(doc.data().gbillid);
            });
        }
        else if (taxtype === 'IGST') {
            onSnapshot(doc(db, "Counters", "billcounter"), (doc) => {
                setBillnumbero(doc.data().igbillid);
            });
        }
        else {
            onSnapshot(doc(db, "Counters", "billcounter"), (doc) => {
                setBillnumbero(doc.data().ngbillid);
            });
        }
    }

    async function updateBillnumber() {
        onSnapshot(doc(db, "SHOP1BILLS", moment().format("DD-MM-YYYY").toString()), (doc) => {
            if (doc.data()) {
                setBillnumber(doc.data().totalbills + 1);
            }
            else {
                setBillnumber(1);
            }
        });
    }

    useEffect(() => {
        pullBillIdFromFirebase().then(() => {
            updateBillid();
        });
        console.log(taxtype, customertype, shoptype, billnumbero, billnumber)
    }, [taxtype, customertype, shoptype, billnumber, billnumbero, billid])


    async function increasebillnumbero() {
        if (taxtype === 'GST') {
            const docRef = doc(db, "Counters", "billcounter");
            setDoc(docRef, {
                gbillid: increment(1),
            }, { merge: true });
        }
        else if (taxtype === 'IGST') {
            const docRef = doc(db, "Counters", "billcounter");
            setDoc(docRef, {
                ibillid: increment(1),
            }, { merge: true });
        }
        else {
            const docRef = doc(db, "Counters", "billcounter");
            setDoc(docRef, {
                ngbillid: increment(1),
            }, { merge: true });
        }
    }

    const copyCollection = async () => {
        const documents = await getDocs(collection(db, "SHOP1LIVECART"));
        let i = 0;
        documents.forEach(async (docz) => {
            batch1.set(doc(db, "SHOP1BILLS", DATE, billnumber.toString(), docz.id), docz.data());
            i++;
            if (i > 400) {  // write batch only allows maximum 500 writes per batch
                i = 0;
                console.log('Intermediate committing of batch operation');
                await batch1.commit();
            }




            const docRef = doc(db, "SHOP1BILLS", DATE);
            setDoc(docRef, {
                totalitemsonthisday: increment(docz.data().totalitems) || 0,
                totalqntyonthisday: increment(docz.data().totalqnty) || 0,
                billcostonthisday: increment(docz.data().billcost) || 0,
                totalbills: increment(1) || 0,
            }, { merge: true });


        })
        if (i > 0) {
            console.log('Firebase batch operation completed. Doing final committing of batch operation.');
            await batch1.commit();
        } else {
            console.log('Firebase batch operation completed.');
        }
    }

    const copysubCollection = async () => {
        const documents = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
        let i = 0;
        documents.forEach(async (docz) => {
            batch2.set(doc(db, "SHOP1BILLS", DATE, billnumber.toString(), "CART1", "PRODUCTS", docz.id), docz.data());
            i++;
            if (i > 400) {  // write batch only allows maximum 500 writes per batch
                i = 0;
                console.log('Intermediate committing of batch operation');
                await batch2.commit();
            }
        })
        if (i > 0) {
            console.log('Firebase batch operation completed. Doing final committing of batch operation.');
            await batch2.commit();
        } else {
            console.log('Firebase batch operation completed.');
        }



        const documentsb = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "CUSTOMER"));
        let k = 0;
        documentsb.forEach(async (doczz) => {
            batch3.set(doc(db, "SHOP1BILLS", DATE, billnumber.toString(), "CART1", "CUSTOMER", "CUSTOMERDETAILS"), doczz.data());
            k++;
            if (k > 400) {  // write batch only allows maximum 500 writes per batch
                k = 0;
                console.log('Intermediate committing of batch operation');
                await batch3.commit();
            }
        })
        if (k > 0) {
            console.log('Firebase batch operation completed. Doing final committing of batch operation.');
            await batch3.commit();
        } else {
            console.log('Firebase batch operation completed.');
        }

    }

    const setcustomerbill = async () => {
        const getCustomer = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "CUSTOMER"));
        getCustomer.forEach(async (doczz) => {
            const querySnapshot = await getDocs(collection(db, "SHOP1LIVECART"));
            querySnapshot.forEach((docz) => {
                const docRef = doc(db, "Customers", doczz.data().KEYID, "BILLS", (docz.data().billnumbero - 1).toString());
                setDoc(docRef, {
                    date: DATE,
                    billnumber: docz.data().billnumber - 1,
                    billnumbero: docz.data().billnumbero - 1,
                }, { merge: true });
            })
        })
    }

    const reducequantity = async () => {
        const querySnapshot = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
        querySnapshot.forEach((docz) => {
            const docRef = doc(db, "Products", docz.data().KEYID);
            updateDoc(docRef, {
                qnty: increment(-Number(docz.data().qnty)),
            });
        })
    }


    const renewCartAction = async () => {
        const querySnapshot = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
        querySnapshot.forEach((docz) => {
            deleteDoc(doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", docz.id)).then(() => {
                const docRef = doc(db, "SHOP1LIVECART", "CART1");
                setDoc(docRef, {
                    totalqnty: 0,
                    billcost: 0,
                    isoveralldiscount: false,
                    overalldiscount: 0,
                    overalldiscounttype: "incost",
                    tafteoveralldiscount: 0,
                    iscreditsale: false,
                    isfootnotesincluded: false,
                    paidamount: 0,
                    remainingpay: 0,
                    custid: '',
                    custname: '',
                    pcash: 0,
                    pcard: 0,
                    pupi: 0,
                    pfinance: 0,
                }, { merge: true });
            })
        })

        const querySnapshotb = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "CUSTOMER"));
        querySnapshotb.forEach((docz) => {
            deleteDoc(doc(db, "SHOP1LIVECART", "CART1", "CUSTOMER", "CUSTOMERDETAILS")).then(() => {
            })
        })
    }

    const handleOnClickSubmitbtn = async () => {
        setFinished(false)
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        const docRef = doc(db, "SHOP1LIVECART", "CART1");
        setDoc(docRef, {
            billedon: Timestamp.now()
        }, { merge: true });

        copyCollection()
            .then(() => {
                console.log('copyC complete')
                copysubCollection()
                    .then(() => {
                        console.log('copySC complete')
                        setcustomerbill();
                    }).then(async () => {
                        reducequantity();
                    }).then(async () => {
                        renewCartAction().then(() => {
                            increasebillnumbero();
                        }).then(() => {
                            toast("Billed Successfully");
                        }).then(async () => {
                            //-------------removeall==============
                            const querySnapshot = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
                            querySnapshot.forEach((docz) => {
                                deleteDoc(doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", docz.id)).then(() => {
                                    const docRef = doc(db, "SHOP1LIVECART", "CART1");
                                    setDoc(docRef, {
                                        totalqnty: 0,
                                        billcost: 0,
                                        isoveralldiscount: false,
                                        overalldiscount: 0,
                                        overalldiscounttype: "incost",
                                        tafteoveralldiscount: 0,
                                        iscreditsale: false,
                                        isfootnotesincluded: false,
                                        paidamount: 0,
                                        remainingpay: 0,
                                        custid: '',
                                        custname: '',
                                        pcash: 0,
                                        pcard: 0,
                                        pupi: 0,
                                        pfinance: 0,
                                    }, { merge: true });
                                })
                                //-------------removeall==============
                            })
                            setFinished(true)
                        }).then(() => {
                        });
                    })
                    .catch(error => {
                        console.log('copy failed. ' + error)
                    });
            })
            .catch(error => {
                console.log('copy failed. ' + error)
            });
    }

    return (
        <>
            {/* <div className="flex space-x-2 justify-center">
                {props.paymentmetstatus == true && <Footnotesinclude disabled={props.billcost === 0 ? true : false} />}
                {props.paymentmetstatus == true && <button ref={btnRef} type="button" disabled={props.billcost === 0 ? true : false} onClick={() => handleOnClickSubmitbtn()} className="inline-block px-7 py-3 bg-blue-800 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">SUBMIT</button>}
            </div> */}

            <div className="flex space-x-2 justify-center">
                {props.paymentmetstatus == true && <button ref={btnRef} type="button" disabled={props.billcost === 0 ? true : false} onClick={() => handleOnClickSubmitbtn()} className="inline-block px-7 py-3 bg-blue-800 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">SUBMIT</button>}
            </div>

            {finished && <Billdialog date={DATE} billnumber={Number(billnumber - 1).toString()} />}

        </>
    );
}

export default Billsubmit;
