import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useEffect, useState, useId } from "react";
import { collection, onSnapshot,doc, getDoc, setDoc, deleteDoc} from "firebase/firestore";
import {db} from "../firebase-config";
import { capitalize } from '@material-ui/core';

function Customerautocomplete(props) {
  const id=useId()
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");

    async function pullDataFromFirebase() {
        const colRef = collection(db, "Customers");
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(data)
      }


    useEffect(() => {
       pullDataFromFirebase();
    }, [])
  // note: the id field is mandatory
  const items = data

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    
    console.log(string, results)
    setSearchString(string);
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect  = async(item)  => {
    // the item selected
    console.log(item)
    clearSearchBox("")

    const custRef = doc(db, "SHOP1LIVECART","CART1","CUSTOMER","CUSTOMERDETAILS");
    setDoc(custRef, {      
      KEYID:item.KEYID,
      name: capitalize(item.title)+' '+capitalize(item.name),
   });

   const custRef2 = doc(db, "SHOP1LIVECART","CART1");
   setDoc(custRef2, {      
     custid:item.KEYID,
     custname: capitalize(item.title)+' '+capitalize(item.name),
  },{merge: true});

  props.parentCallback(capitalize(item.title)+' '+capitalize(item.name))
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnClear = () => {
    console.log('cleared')
    const docRef = doc(db, "SHOP1LIVECART", "CART1", "CUSTOMER", "CUSTOMERDETAILS");
    deleteDoc(docRef)

    const custRef2 = doc(db, "SHOP1LIVECART","CART1");
    setDoc(custRef2, {      
      custid:'',
      custname: '',
   },{merge: true});

  }

  const formatResult = (item) => {
    return (
      <div>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.KEYID}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
      </div>
    )
  }

  const clearSearchBox = () => {
    setSearchString("");
  };
  
  return (
      <>
    <div>
      <header className="App-header">
        <div style={{ width: 400 }}>
          <ReactSearchAutocomplete id={id} fuseOptions={{ keys: ["name"] }}
            items={items}
            resultStringKeyName={"name"}
            inputSearchString={searchString}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            formatResult={formatResult}
            styling={{zIndex:"1000"}}
          />
        </div>
      </header>
    </div>
    <div>
    </div>


    </>
  )
}

export default Customerautocomplete