import { useEffect, useState } from "react";

import { collection, onSnapshot, doc, setDoc, getDocs,increment, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


function Creditsale(props) {
  const [paidamount, setPaidamount] = useState(0);
  const [remainingpay, setRemainingpay] = useState(0);


  async function pullDiscountFromFirebase() {
    const docRef = doc(db, "SHOP1LIVECART","CART1");
    onSnapshot(docRef, (doc) => {
        if(doc.exists && doc.data().paidamount!==undefined){
            setPaidamount(doc.data().paidamount);
          }
})
  }
  

  useEffect(() => {
    pullDiscountFromFirebase();
  }, [])

  const handleOnChangeinput = (e) => {

    if(Number(e.target.value)>=0 && Number(e.target.value)<=props.billcost){

        setPaidamount(Number(e.target.value));

    let _manualinput = Number(e.target.value) || 0;

    const docRef = doc(db, "SHOP1LIVECART", "CART1");
    setDoc(docRef, {
        paidamount: _manualinput,
        remainingpay: props.billcost-_manualinput,
      iscreditsale:false
    }, { merge: true });
}
else{
    setPaidamount(0);
}
  }

const handleFocus = (event) => event.target.select();


async function handleOnClickEditokbn(e) {
     const docRefz = doc(db, "SHOP1LIVECART","CART1");
     setDoc(docRefz, {
        iscreditsale: paidamount>=0 ? true : false,
     },{merge:true})
}

async function handleOnClickEditnotokbn(e) {
  const docRefz = doc(db, "SHOP1LIVECART","CART1");
  setDoc(docRefz, {
     iscreditsale: false,
     paidamount: 0,
     remainingpay: 0,
  },{merge:true})
}


  return (
    <div>
          <label htmlFor="exampleNumber0" className="form-label inline-block mb-2 text-gray-700"
          >Number input</label
          >
          <input
            type="number"
            value={paidamount || ''} onFocus={handleFocus}
            onChange={handleOnChangeinput}
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            id="exampleNumber0"
            placeholder="Number input"
          />
                <button type="button" onClick={(e) => handleOnClickEditokbn(e)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <CheckIcon />
        {/* OK */}
      </button>
      <button type="button" onClick={(e) => handleOnClickEditnotokbn(e)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <ClearIcon />
        {/* OK */}
      </button>
      
      </div>
  );

}
export default Creditsale;