import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useEffect, useState, useId } from "react";
import { collection, onSnapshot, doc, getDoc, query, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button } from '@mui/material';

function Paymentmethodcash(props) {

    const [xtotalamount, setXtotalamount] = useState(0);

    // const [pmntmeths, setPmntmeths] = useState(() => ['cash', 'card']);
const [pmntmeths, setPmntmeths] = useState(() => []);

const [cashpay, setCashpay] = useState(0);
const [cardpay, setCardpay] = useState(0);
const [upipay, setUpipay] = useState(0);
const [financepay, setFinancepay] = useState(0);
const [totalpay, setTotalpay] = useState(0);
    const [match, setMatch] = useState(false);


async function pullDataFromFirebase() {
    const unsubscribe2 = onSnapshot(doc(db,"SHOP1LIVECART","CART1"), (doc) => {
        if(doc.exists && doc.data().totalqnty!=undefined){
            setXtotalamount(doc.data().billcost)
            if(xtotalamount !== totalpay) {
                 setMatch(false)
                 //props.parentCallback(false)
                 props.parentCallback(false)
                 setPmntmeths([])
                 } else {
                     setMatch(true)
                     //props.parentCallback(true)
                     props.parentCallback(false)
                    }
        }
      });
      return () => {
        unsubscribe2();
      }
  }
  
//   useEffect(() => {
//       pullDataFromFirebase();
//   }, [xtotalamount])
useEffect(() => {
    pullDataFromFirebase();
}, [xtotalamount])




const handlePmntmeths = (event, newPmntmeths) => {
    setPmntmeths(newPmntmeths);

    if(newPmntmeths.length==1){
    if(newPmntmeths.includes('cash')){
        console.log("cashpaid")
        setCashpay(xtotalamount);
        setCardpay(0);
        setUpipay(0);
        setFinancepay(0);
    }
    else if(newPmntmeths.includes('card')){
        console.log("cardpaid")
        setCardpay(xtotalamount);   
        setUpipay(0);
        setCashpay(0);   
        setFinancepay(0);  
    }
    else if(newPmntmeths.includes('upi')){
        console.log("upipaid")
        setUpipay(xtotalamount);
        setCashpay(0);  
        setCardpay(0);  
        setFinancepay(0);
    }
    else if(newPmntmeths.includes('finance')){
        console.log("financepaid")
        setFinancepay(xtotalamount);
        setCashpay(0);  
        setCardpay(0);  
        setUpipay(0);
    }
    setTotalpay(xtotalamount);       
    setMatch(true)
    //props.parentCallback(true)    
    }
    else if(newPmntmeths.length==2){
        console.log(newPmntmeths)
        if(newPmntmeths.every(i => ['cash','card'].includes(i))){
            console.log("cash & card paid")        
            setUpipay(0);
            setCashpay(xtotalamount);
            setCardpay(0);
            setFinancepay(0);
        }
        else if(newPmntmeths.every(i => ['card','upi'].includes(i))){
            console.log("card & upi paid")
            setCashpay(0);
            setCardpay(xtotalamount);
            setUpipay(0);
            setFinancepay(0);
        }
        else if(newPmntmeths.every(i => ['cash','upi'].includes(i))){
            console.log("cash & upi paid")
            setCardpay(0);
            setCashpay(xtotalamount);
            setUpipay(0);
            setFinancepay(0);
        }
        else if(newPmntmeths.every(i => ['cash','finance'].includes(i))){
            console.log("cash & upi paid")
            setCardpay(0);
            setCashpay(xtotalamount);
            setUpipay(0);
            setFinancepay(0);
        }
        else if(newPmntmeths.every(i => ['card','finance'].includes(i))){
            console.log("card & finance paid")
            setCashpay(0);
            setCardpay(xtotalamount);
            setUpipay(0);
            setFinancepay(0);
        }
        else if(newPmntmeths.every(i => ['upi','finance'].includes(i))){
            console.log("upi & finance paid")
            setCardpay(0);
            setUpipay(xtotalamount);
            setCashpay(0);
            setFinancepay(0);
        }
        }
     else if(newPmntmeths.length==3){
                console.log("3 selected")

            }
            else if(newPmntmeths.length==4){
                console.log("all selected")

            }
            else{
                console.log("No Payment Method Selected")
            setCardpay(0);
            setCashpay(0);
            setUpipay(0);
            setFinancepay(0);
            setTotalpay(0);       
            setMatch(false)
            //props.parentCallback(false)
            }
            props.parentCallback(false)
};

function handleonChangecash(e){

if(pmntmeths.length==2 && pmntmeths.includes('cash')){
    if(pmntmeths.every(i => ['cash','card'].includes(i))){
        setCashpay(e.target.value);
        setCardpay(xtotalamount-e.target.value);
    }
    else if(pmntmeths.every(i => ['cash','upi'].includes(i))){
        setCashpay(e.target.value);
        setUpipay(xtotalamount-e.target.value);
    }
    else if(pmntmeths.every(i => ['cash','finance'].includes(i))){
        setCashpay(e.target.value);
        setFinancepay(xtotalamount-e.target.value);
    }
    setTotalpay(xtotalamount);       
    setMatch(true)
    //props.parentCallback(true)
    }

    if(pmntmeths.lengt>=3 && pmntmeths.includes('cash')){
        setCashpay(e.target.value);
    setTotalpay(Number(e.target.value)+Number(cardpay)+Number(upipay)+Number(financepay));       
    setMatch(xtotalamount === Number(e.target.value)+Number(cardpay)+Number(financepay) ? true : false)
    //props.parentCallback(xtotalamount === Number(e.target.value)+Number(cardpay)+Number(upipay) ? true : false)
}

props.parentCallback(false)
}

function handleonChangecard(e){
    if(pmntmeths.length==2 && pmntmeths.includes('card')){
    if(pmntmeths.every(i => ['cash','card'].includes(i))){
        setCardpay(e.target.value);
        setCashpay(xtotalamount-e.target.value);
    }
    else if(pmntmeths.every(i => ['card','upi'].includes(i))){
        setCardpay(e.target.value);
        setUpipay(xtotalamount-e.target.value);
    }
    else if(pmntmeths.every(i => ['card','finance'].includes(i))){
        setCardpay(e.target.value);
        setFinancepay(xtotalamount-e.target.value);
    }
    setTotalpay(xtotalamount);       
    setMatch(true)
    //props.parentCallback(true)
    }
    if(pmntmeths.length>=3 && pmntmeths.includes('card')){
        setCardpay(e.target.value);
        setTotalpay(Number(cashpay)+Number(e.target.value)+Number(upipay)+Number(financepay));       
        setMatch(xtotalamount === Number(cashpay)+Number(e.target.value)+Number(upipay)+Number(financepay) ? true : false)
        //props.parentCallback(xtotalamount === Number(cashpay)+Number(e.target.value)+Number(upipay) ? true : false)
    }

    props.parentCallback(false)
}

function handleonChangeupi(e){
    if(pmntmeths.length==2 && pmntmeths.includes('upi')){
        if(pmntmeths.every(i => ['cash','upi'].includes(i))){
            setUpipay(e.target.value);
            setCashpay(xtotalamount-e.target.value);
        }
        else if(pmntmeths.every(i => ['card','upi'].includes(i))){
            setUpipay(e.target.value);
            setCardpay(xtotalamount-e.target.value);
        }
        
    else if(pmntmeths.every(i => ['upi','finance'].includes(i))){
        setUpipay(e.target.value);
        setFinancepay(xtotalamount-e.target.value);
    }
        setTotalpay(xtotalamount);       
        setMatch(true)
        //props.parentCallback(true)
        }
        if(pmntmeths.length>=3 && pmntmeths.includes('upi')){
            setUpipay(e.target.value);
            setTotalpay(Number(cashpay)+Number(cardpay)+Number(e.target.value)+Number(financepay));       
            setMatch(xtotalamount === Number(cashpay)+Number(cardpay)+Number(e.target.value)+Number(financepay) ? true : false)
            //props.parentCallback(xtotalamount === Number(cashpay)+Number(cardpay)+Number(e.target.value) ? true : false)
        }
    
        props.parentCallback(false)
}

function handleonChangefinance(e){
    if(pmntmeths.length==2 && pmntmeths.includes('finance')){
        if(pmntmeths.every(i => ['cash','finance'].includes(i))){
            setFinancepay(e.target.value);
            setCashpay(xtotalamount-e.target.value);
        }
        else if(pmntmeths.every(i => ['card','finance'].includes(i))){
            setFinancepay(e.target.value);
            setCardpay(xtotalamount-e.target.value);
        }
        
    else if(pmntmeths.every(i => ['upi','finance'].includes(i))){
        setFinancepay(e.target.value);
        setUpipay(xtotalamount-e.target.value);
    }
        setTotalpay(xtotalamount);       
        setMatch(true)
        //props.parentCallback(true)
        }
        if(pmntmeths.length>=3 && pmntmeths.includes('finance')){
            setFinancepay(e.target.value);
            setTotalpay(Number(cashpay)+Number(cardpay)+Number(upipay)+Number(e.target.value));       
            setMatch(xtotalamount === Number(cashpay)+Number(cardpay)+Number(upipay)+Number(e.target.value) ? true : false)
            //props.parentCallback(xtotalamount === Number(cashpay)+Number(cardpay)+Number(e.target.value) ? true : false)
        }
      
        props.parentCallback(false)
}
const handleFocus = (event) => event.target.select();


    return (
        <>
        {props.billcostdata!==0 && 
        <div className='flex flex-row items-center space-x-4 w-[382px]'>
<div className='w-[200px]'>
<div>
         <ToggleButtonGroup key={"pm"}
      value={pmntmeths}
      onChange={handlePmntmeths}
      aria-label="payment methods"
      size="small"
      color="primary"  toggle required
    >
      <ToggleButton value="cash" aria-label="cash">
        CASH
      </ToggleButton>
      <ToggleButton value="card" aria-label="card">
        CARD
      </ToggleButton>
      <ToggleButton value="upi" aria-label="upi">
        UPI
      </ToggleButton>
      <ToggleButton value="finance" aria-label="finance">
        FINANCE
      </ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div>
            <Box component="form" sx={{'& > :not(style)': { m: 1, width: '25ch' }, }} noValidate autoComplete="off">
                {pmntmeths.length>1 && pmntmeths.includes('cash') && <TextField style = {{width: 100}} value={cashpay} onChange={(e)=>{handleonChangecash(e)}} onFocus={handleFocus} type="number" label="cash"/>}
                {pmntmeths.length>1 && pmntmeths.includes('card') && <TextField style = {{width: 100}} value={cardpay} onChange={(e)=>{handleonChangecard(e)}} onFocus={handleFocus} type="number" label="card"/>}
                {pmntmeths.length>1 && pmntmeths.includes('upi') && <TextField style = {{width: 100}} value={upipay} onChange={(e)=>{handleonChangeupi(e)}} onFocus={handleFocus} type="number" label="upi"/>}
                {pmntmeths.length>1 && pmntmeths.includes('finance') && <TextField style = {{width: 100}} value={financepay} onChange={(e)=>{handleonChangefinance(e)}} onFocus={handleFocus} type="number" label="finance"/>}
            </Box>
            </div>
            {/* <p>CASH SALE</p>
            <p>{xtotalamount}</p>
            <p>{totalpay}</p>
            <p>{JSON.stringify(match)}</p> */}
            {pmntmeths.length>0 && match==true && <Button onClick={()=>{
                  const docRef = doc(db, "SHOP1LIVECART", "CART1");
                  setDoc(docRef, {
                    pcash:Number(cashpay),
                    pcard:Number(cardpay),
                    pupi:Number(upipay),
                    pfinance:Number(financepay)
                  }, { merge: true }).then(()=>{
match==true ? props.parentCallback(true) : props.parentCallback(false)
                  });
                }
            }>OK</Button>}

</div>
{props.billcostdata!==0 && <div>
<table className="min-w-full border text-center">
        <thead className="border-b">
            {Number(cashpay)!==0 && <th className="px-2 py-1 border-r"><td className='text-sm text-center'>CASH PAY</td></th>}
            {Number(cardpay)!==0 && <th className="px-2 py-1 border-r"><td className='text-sm text-center'>CARD PAY</td></th>}
            {Number(upipay)!==0 && <th className="px-2 py-1 border-r"><td className='text-sm text-center'>UPI PAY</td></th>}
            {Number(financepay)!==0 && <th className="px-2 py-1 border-r"><td className='text-sm text-center'>FiNANCE PAY</td></th>}
        </thead>
        <tbody>
            <tr>
            {Number(cashpay)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(cashpay)}</td>}
            {Number(cardpay)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(cardpay)}</td>}
            {Number(upipay)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(upipay)}</td>}
            {Number(financepay)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(financepay)}</td>}
            </tr>
        </tbody>
    </table>
            </div>}
</div>
}
        </>
    )
}

export default Paymentmethodcash