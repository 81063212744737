import { React, useState, useEffect, useCallback, forwardRef } from "react";
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import defaultavatarmale from "../assets/images/maleicon.png"
import defaultavatarfemale from "../assets/images/femaleicon.png"
import defaultavatarbusiness from "../assets/images/businessicon.png"
import Dldatepick from "../components/Dldatepick";
import ImageConverter from "../components/ConvertImageToBase64";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useForm, Controller } from "react-hook-form";

import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc,limit, getDoc, addDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import Addressadd from "./Addressadd";
import Addresseslist from "./Addresseslist";
import moment from "moment";
import { format } from 'date-fns'

const schema = z.object({
    name: z.string()
    .min(3, { message: 'Name must be greater than 3 characters' }),
    mobilenumber: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid phone number")
    .length(10, { message: 'Required' }),
    //email: z.string().email({ message: "Invalid email address" }),
    gender:z.string(),
    customer:z.string(),
    customertype:z.string(),
    title:z.string(),
    email:z.string(),
    gstnumber:z.string(),
    info:z.string(),
  });

export default function Customeraddform(props) {
  const { open,onClose } = props;

  const [bdate, setBdate] = useState(new Date('2000-01-01'));
  const [aimage, setAimage] = useState(defaultavatarmale);
  const [gender, setGender] = useState('male');
  const [customer, setCustomer] = useState('individual');
  const [title, setTitle] = useState('mr');


  const [customertype, setCustomertype] = useState('regular');
  const [gstnumber, setGstnumber] = useState('');

  const [age, setAge] = useState(22);

  const handleChange = (newValue) => {
    setBdate(newValue);
    calculate_age(newValue);
  };

const { control, formState: { isSubmitSuccessful,errors }, reset, handleSubmit, register,setValue, getValues} = useForm({
  resolver: zodResolver(schema),
  defaultValues: {
    name: "",
    mobilenumber: "",
    email:"",
    gender: "male",
    customer: "individual",
    customertype: "regular",
    title: "mr",
    dob:bdate,
    gstnumber:"",
    info:""
  },
});

useEffect(() => {

  if (isSubmitSuccessful) {
    reset();
    setBdate(new Date('2000-01-01'));
    setAimage(defaultavatarmale);

    setGender("male");
    setCustomer("individual");
    setCustomertype("regular");
    setTitle("mr");
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isSubmitSuccessful]);

 //const onSubmit = data => console.log(data);
const onSubmit = async data => {
    const newId = doc(collection(db, 'Customers')).id;
    const docRef = query(doc(db, "Customers",newId));
    setDoc(docRef, {
      KEYID: newId,
      name: data.name,
      mobilenumber: data.mobilenumber,
      email: data.email || "",
      gender: gender,
      customer: customer,
      customertype: customertype,
      title: title,
      dob: bdate,
      dob2:format(new Date((moment(bdate, 'DD-MM-YYYY').toISOString())), 'dd-MM-yyyy'),
      gstnumber:data.gstnumber || "",
      info:data.info || "",
      image:aimage
    },{merge:true}).then(()=>{
      console.log("submitted");
    })
};
console.log(errors);


const callbackimg = useCallback((i) => {
console.log(i)
setAimage(i)
})


function calculate_age(newValue) {
    console.log( new Date())
    console.log(newValue)
    var today = new Date();
    var birthDate = new Date(newValue);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    console.log(age_now);
    setAge(age_now);
    return age_now;
  }

  return (
    <Dialog open={open} PaperProps={{
      style: { borderRadius: 10 } }}>
      <DialogTitle sx={{px:2,py:1, backgroundColor:"blue", color:"white"}}>
        <div className='flex flex-row justify-between items-center'>
        <div>Add New Customer</div>
        <div> 
      <IconButton onClick={onClose} aria-label="close"  style={{ color: 'white' }}>
  <CloseIcon />
</IconButton>
      </div>
      </div>
        </DialogTitle>

      <div className='p-3'>

           <form onSubmit={handleSubmit(onSubmit)}>

           <div className=" flex flex-row justify-between items-center flex-wrap">
           {<Controller
          name="customer" {...register("customer")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='company'){
        setCustomer('company')
        setAimage(defaultavatarbusiness);
        setTitle('m/s')
          }
       else{ 
        setCustomer('individual')
        setAimage(defaultavatarmale);
        setGender('male')
        setTitle('mr')
       }
        }
        }
    >
      <ToggleButton selected={customer=='individual' ? true : false} value="individual">Individual</ToggleButton>
      <ToggleButton selected={customer=='company' ? true : false} value="company">Company</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}

{customer=='individual' && <Controller
          name="gendertype" {...register("gendertype")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='female'){
      setAimage(defaultavatarfemale);
      setGender('female')
      setTitle('mrs')
          }
       else{ 
         setAimage(defaultavatarmale);
         setGender('male')
         setTitle('mr')
        }
        }
        }
    >
      <ToggleButton selected={gender=='female' ? true : false} value="female">Female</ToggleButton>
      <ToggleButton selected={gender=='male' ? true : false} value="male">Male</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}
</div>

<div className="pt-4 flex flex-row justify-between flex-wrap">
 <div>
<div className="flex flex-col justify-between flex-wrap">

{gender=='male' && customer=='individual' && <span className="h-[38.75px] flex flex-col justify-end">
Mr.
</span>}
{customer=='company' && <span className="h-[38.75px] flex flex-col justify-end">
M/s.
</span>}
{gender=='female' && customer=='individual' && <Controller
          name="title" {...register("title")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='mrs'){
      setTitle('mrs')
          }
       else{ 
        setTitle('ms')}
        }
        }
    >
      <ToggleButton selected={title=='mrs' ? true : false} value="mrs">Mrs.</ToggleButton>
      <ToggleButton selected={title=='ms' ? true : false} value="ms">Ms.</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}

<div className="pt-[5px] flex flex-row justify-between flex-wrap">
<div className="pr-2 w-[500%]">
            <Controller
        name="name" {...register("name")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[102%]" autoComplete="off"
        error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
           label="Name" variant="outlined" {...field} />}
      />
      </div>
      </div>
      <div className="pt-4">
      {<Controller
          name="customertype" {...register("customertype")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='regular'){
        setCustomertype('regular')
          }
       else if(e.target.value=='prime'){
        setCustomertype('prime')
       }
       else if(e.target.value=='institute'){
        setCustomertype('institute')
       }
       else if(e.target.value=='wholesale'){
        setCustomertype('wholesale')
       }
        }
        }
    >
      <ToggleButton selected={customertype=='regular' ? true : false} value="regular">regular</ToggleButton>
      <ToggleButton selected={customertype=='prime' ? true : false} value="prime">prime</ToggleButton>
      <ToggleButton selected={customertype=='institute' ? true : false} value="institute">institute</ToggleButton>
      <ToggleButton selected={customertype=='wholesale' ? true : false} value="wholesale">wholesale</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}
      </div>
</div>
</div>   

    <div className="flex flex-col justify-start items-center">
        <div>
<img className="p-1 w-20 h-20 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
 src={aimage}
  alt="avatar" />
  </div>
  <div className="pt-1">
<Controller
        name="imgcnt" 
        control={control} innerRef={register}
        render={() =>(<ImageConverter  parentCallback={callbackimg} />)}
/></div>
</div>
</div>

{customer=='individual' && <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[41.35%]">
    <LocalizationProvider dateAdapter={AdapterDateFns}>
   <MobileDatePicker
          label="Date of Birth"
          inputFormat="dd-MM-yyyy"
          value={bdate}
          onChange={handleChange}
          maxDate={new Date()}
          disableFuture={true}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        </LocalizationProvider>
</div>
<div className="w-[56.65%]">
<TextField size="small" className="w-[100%]" autoComplete="off" value={age+" years old"} variant="outlined" disabled={true} />

      </div>
      </div>}

      {customer=='company' && <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[100%]">
          <Controller
        name="gstnumber" {...register("gstnumber")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
        error={!!errors.gstnumber}
        helperText={errors.gstnumber ? errors.gstnumber.message : ''}
          label="GST Number" variant="outlined" {...field} inputProps={{ maxLength:15 }}/>}
      />
</div>

      </div>}

      
<div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[41.35%]">
<Controller
        name="mobilenumber" {...register("mobilenumber")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" size="small" autoComplete="off" 
        error={!!errors.mobilenumber}
        helperText={errors.mobilenumber ? errors.mobilenumber.message : ''}
          label="Mobile Number" variant="outlined" {...field} inputProps={{ maxLength:10 }}/>}
      />
</div>
<div className="w-[56.65%]">
<Controller
        name="email" {...register("email")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="text" size="small" className="w-[100%]" autoComplete="off" 
        // error={!!errors.email}
        // helperText={errors.email ? errors.email.message : ''}
          label="email address" variant="outlined" {...field}/>}
      />
      </div>
      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[100%]">
    <Controller
        name="info" {...register("info")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="Info" variant="outlined" {...field} inputProps={{ maxLength:40 }}/>}
      />
</div>

      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
</div>

    </form>
    {/* <div className="flex flex-row justify-start">
    <div>
<Addressadd />
</div>
<div className="m-1  w-[100%] border-dashed border-2 border-indigo-600">
<div className="h-[100%] w-[100%] flex justify-center items-center">
    No Address
</div>
</div>
</div> */}
    </div>
    </Dialog>
  );
}
