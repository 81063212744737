import { Scrollbars } from 'react-custom-scrollbars-2';
import Taxsummarybill from './Taxsummarybill';
import Taxsummaryrange from './Taxsummaryrange';

function Gstsummary() {
  return (
    <>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
<Taxsummaryrange />
        </div>
      </Scrollbars>
    </>
  );
}

export default Gstsummary;
