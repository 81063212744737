import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Supplieraddform from "./Supplieraddform";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useHotkeys } from 'react-hotkeys-hook'
import Chip from '@mui/material/Chip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

Supplieraddform.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Supplieradd2() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});


  return (
    <>
    {/* <IconButton color="primary" onClick={handleClickOpen} aria-label="add to shopping cart">
  <AddIcon />
</IconButton> */}
<Chip icon={<PersonOutlineIcon />} label="Add New Supplier"  onClick={handleClickOpen}/>

      <Supplieraddform
        open={open}
        onClose={handleClose}
        parentCallback={callback}
      />
    </>
  );
}