import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tw-elements';
import 'react-dates/initialize';
import { BrowserRouter } from "react-router-dom"

import { createTheme, ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
    typography: {
      titletxt1: {
        fontFamily: 'raleway',
        fontSize: 18,
  },
         menugrouptxt: {
            fontFamily: 'raleway',
            fontSize: 18,
      },
        menugrouptxt: {
            fontFamily: 'raleway',
            fontSize: 18,
      },
      menutxt: {
        fontFamily: 'raleway',
        fontSize: 14,
  },
    },
  });

root.render(
    <BrowserRouter>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
