import { useEffect, useState, useRef } from "react";
import { collection, onSnapshot, query, doc, getDoc } from "firebase/firestore";
import {db} from "../firebase-config";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { numbertoCurrency } from "../components/NumbertoCurrency";

export default function Getbilldetailsbydatenumber(props) {

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${
            props.value
          }%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };


    const date=props.date;
    const billnumber=props.billnumber;

    const [billdetails,setBilldetails] = useState([]);

    const [totalbillcost,setTotalbillcost] = useState([]);


    async function pullDataFromFirebase() {
          const docRefBills = collection(db, "SHOP1BILLS",date,billnumber.toString());
          onSnapshot(docRefBills, (snapshot) => {
              setBilldetails(snapshot.docs.map(doc => (doc.data())))
              })   

              const docRefDay =doc(db, "SHOP1BILLS",date);
              onSnapshot(docRefDay, (doc) => {
                setTotalbillcost(doc.data().billcostonthisday);
            });
              console.log("hello")
      }
      
  useEffect(() => {
      pullDataFromFirebase();
    }, [date])


  return (
    <>
        {billdetails.map((row,i) => {
return (
    <div className="flex justify-between">
    <div>
    <div>Bill No.{row.billnumbero}/Bill Id:{row.billid}</div>
    {/* <div>{Number(Number(row.billcost)*100/Number(totalbillcost)).toFixed(2)+"% of total sale"}</div> */}
    <LinearProgressWithLabel value={Number(Number(row.billcost)*100/Number(totalbillcost)).toFixed(2)} />

      </div>
    <div className="flex items-center text-xl text-green-600"	>{numbertoCurrency(row.billcost)}</div>
    </div>
)})}
    </>
  )
}
