import { useEffect, useState, useCallback } from "react";
import SimpleToast from '../components/Toast/SimpleToast';
import toast from 'react-hot-toast';
import { onSnapshot, doc, setDoc, collection, getDoc, getDocs, deleteDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";
import Autocomplete from '../components/Autocomplete';
import Customerautocomplete from '../components/Customerautocomplete';
import Customeradd2 from '../customers/Customeradd2';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const ToggleButton = styled(MuiToggleButton)(() => ({
  "&.MuiToggleButtonGroup-grouped": {
    width: "115px",
    height: "35px",
    margin: "0px",
    letterSpacing: " 0.5px",
    backgroundColor: "#E0314B",
    color: "white",
    'fontSize': "16px",
    'borderRadius': "10px",
    cursor: "pointer",
    transition: ".35s ease-out",
    'fontweight': "400",
    padding: '0px 5px 0px 5px',
    borderRight: "2px solid rgb(25, 26, 24)"

  },
  "&.MuiToggleButtonGroup-grouped:last-child": {
    borderRight: "none",
    width: "125px",
  },
  "&.Mui-selected": {
    color: "white",
    'fontweight': "400",
    backgroundColor: "black"
  },
  "&.MuiToggleButtonGroup-grouped:hover": {
    color: "white",
    backgroundColor: "black"
  }
}));

function Currentbillid() {
  const [taxtype, setTaxtype] = useState('');
  const [customertype, setCustomertype] = useState('');
  const [shoptype, setShoptype] = useState('');
  const [billnumbero, setBillnumbero] = useState('');
  const [billnumber, setBillnumber] = useState('');
  const [billid, setBillid] = useState('');
  const [custname, setCustname] = useState('');

  async function pullBillIdFromFirebase() {
    const docRef = doc(db, "SHOP1LIVECART", "CART1");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setTimeout(() => {
        setShoptype(docSnap.data().shoptype)
        setCustomertype(docSnap.data().customertype)
        setTaxtype(docSnap.data().taxtype)
        //setBillnumber(docSnap.data().billnumber)
          setCustname(docSnap.data().custname)

        updateBillnumbero()
        updateBillnumber()
        setBillid(shoptype.slice(0, 1) + shoptype.slice(-1) + customertype.slice(0, 1) + taxtype.slice(0, 1) + billnumber)
      }, 2000)

    } else {
      console.log("No such document!")
    }

  }

  async function updateBillid() {

    const querySnapshot = await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
    querySnapshot.forEach(async (docz) => {

      const prodRef = doc(db, "Products", docz.id);
      const docSnap = await getDoc(prodRef)
      if (docSnap.exists()) {

        let discval = ((customertype === 'REGULAR') ? (docSnap.data().discountregular) :
          (customertype === 'PRIME') ? (docSnap.data().discountprime) :
            (customertype === 'INSTITUTE') ? (docSnap.data().discountinstitute) :
              (customertype === 'WHOLESALE') ? (docSnap.data().discountwholesale) : 0);

        if (!discval) { discval = 0 } //undefined to 0

        if (docz.data().ismanualdiscount === false) {
          const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", docz.id);
          setDoc(docRef, {
            custtypediscount: discval,
            amount: (docz.data().rate - discval) * docz.data().qnty,
            discount: docz.data().qnty * discval,
            taxamount: ((docz.data().rate - discval) * docz.data().qnty) - ((((docz.data().rate - discval) * docz.data().qnty)) * (100 / (100 + docz.data().gst))),
            modified: Timestamp.now()
          }, { merge: true })
        }
        else {
          const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", docz.id);
          setDoc(docRef, {
            custtypediscount: discval,
          }, { merge: true })
        }
      }
    })

  }

  async function updateBillnumbero() {
    if (taxtype === 'GST') {
      onSnapshot(doc(db, "Counters", "billcounter"), (doc) => {
        setBillnumbero(doc.data().gbillid);
      });
    }
    else if (taxtype === 'IGST') {
      onSnapshot(doc(db, "Counters", "billcounter"), (doc) => {
        setBillnumbero(doc.data().igbillid);
      });
    }
    else {
      onSnapshot(doc(db, "Counters", "billcounter"), (doc) => {
        setBillnumbero(doc.data().ngbillid);
      });
    }
  }

  async function updateBillnumber() {
    onSnapshot(doc(db, "SHOP1BILLS", moment().format("DD-MM-YYYY").toString()), (doc) => {
      if (doc.data()) {
        setBillnumber(doc.data().totalbills + 1);
      }
      else {
        setBillnumber(1);
      }
    });
  }

  useEffect(() => {
    pullBillIdFromFirebase().then(() => {
      updateBillid();
      const docRef = doc(db, "SHOP1LIVECART", "CART1");
      setDoc(docRef, {
        date: moment().format("DD-MM-YYYY").toString(),
        billnumbero: billnumbero,
        billnumber: billnumber,
        billid: billid,
      }, { merge: true })
    });
    console.log(taxtype, customertype, shoptype, billnumbero, billnumber)
  }, [taxtype, customertype, shoptype, billnumbero, billnumber, billid])


  async function handleCustomertypechange(e) {
    const docRef = doc(db, "SHOP1LIVECART", "CART1");
    setDoc(docRef, {
      customertype: e.target.value,
    }, { merge: true }).then(() => {
      const docRef = doc(db, "SHOP1LIVECART", "CART1");
      onSnapshot(docRef, (snapshot) => {
        setCustomertype(snapshot.data().customertype)
      })
    }).then(() => {
      toast(e.target.value + " Selected");
    })
  }

  const custclear = () => {
    console.log('cleared')
    const docRef = doc(db, "SHOP1LIVECART", "CART1", "CUSTOMER", "CUSTOMERDETAILS");
    deleteDoc(docRef)

    const custRef2 = doc(db, "SHOP1LIVECART", "CART1");
    setDoc(custRef2, {
      custid: '',
      custname: '',
    }, { merge: true });

    setCustname('')
  }

  const callback = useCallback((c) => {
    setCustname(c);
  });


  return (
    <>
      <SimpleToast />
      <div className="py-2 pr-2 min-h-[70px] flex flex-row justify-between items-center">
        <div>
          <ToggleButtonGroup
            value={customertype}
            exclusive
            onChange={(e) => { handleCustomertypechange(e) }}
            aria-label="text alignment">
            <ToggleButton value="REGULAR">REGULAR</ToggleButton>
            <ToggleButton value="PRIME">PRIME</ToggleButton>
            <ToggleButton value="INSTITUTE">INSTITUTE</ToggleButton>
            <ToggleButton value="WHOLESALE">WHOLESALE</ToggleButton>
          </ToggleButtonGroup>
        </div>
        {(billid !== '') && <div className="text-5xl font-bold">{billid + "/" + billnumbero}</div>}
        <Skeleton style={(billid !== '') ? { display: 'none' } : {}} variant="rectangular" width={200} height={70} />
      </div>

      <div className="py-2 flex flex-row justify-between max-w-[1350px]">
        <div className="flex flex-row items-center">
          <div className="pr-2 text-lg">Product search: </div>
          <Autocomplete passdata={customertype} />
        </div>
        {!custname ?
          (<div className="flex flex-row items-center gap-2 min-h-[52px]">
            <div className="pr-2 text-lg">Customer search: </div>
            <Customerautocomplete parentCallback={callback} />
            <Customeradd2 />
          </div>) :
          (<div className="flex flex-row items-center gap-2 min-h-[52px]">
            <div className="pr-2">Customer Name: </div>
            <div>{(custname)}</div>
            <div className="pr-1">
              <IconButton onClick={custclear} component="span" sx={{ m: 1, border: 1, borderStyle: "dashed", borderColor: "indigo" }} color="secondary" aria-label="Add" size="small">
                <ClearIcon />
              </IconButton>
            </div>
            <Customeradd2 />
          </div>
          )}
      </div>
    </>
  );
}

export default Currentbillid;
