import { React, useState, useEffect, useCallback, forwardRef } from "react";
import { useParams } from "react-router-dom"
import { Scrollbars } from 'react-custom-scrollbars-2';
import Brandautocomplete from '../components/Autocompletebrand'
import Categoryautocomplete from '../components/Autocompletecategory'
import Colorautocomplete from '../components/Autocompletecolor'
import Sizeautocomplete from '../components/Autocompletesize'

import defaultprdimageproduct from "../assets/images/producticon.png"
import ImageConverter from "../components/ConvertImageToBase64";
import { useForm, Controller } from "react-hook-form";

import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc,limit, getDoc, addDoc, updateDoc, setDoc,orderBy, increment, Timestamp, where} from "firebase/firestore";
import {db} from "../firebase-config";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate"  {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const schema = z.object({
    name: z.string()
    .min(3, { message: 'Name must be greater than 3 characters' }),
    brand: z.string(),
    category: z.string(),
    size: z.string(),
    color: z.string(),
    modelno: z.string(),
    hsncode: z.string(),
    labelcode: z.string(),
    mrprate: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    purchaserate: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    regularrate: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    primerate: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    instituterate: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    wholesalerate: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    qnty: z.string()
    .regex(new RegExp("[0-9]*"), "Invalid number").min(1, { message: 'Required' }),
    gst: z.string(),
    info:z.string(),
  });

export default function Billdescription(props) {
    const params = useParams()

    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [color, setColor] = useState('');
    const [size, setSize] = useState('');
    const [gst, setGst] = useState('');
    const [pimage, setPimage] = useState(defaultprdimageproduct);

    const [billentrydetails, setBillentrydetails] = useState([]);
    const [billcurrentdetails, setBillcurrentdetails] = useState([]);
    const [result, setResult] = useState([]);

    const { control, formState: { isSubmitSuccessful,errors }, reset, handleSubmit, register,setValue, getValues} = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            brand:"",
            category:"",
            color:"",
            size:"",
            name: "",
            modelno: "",
            hsncode: "",
            labelcode: "",
            mrprate:"",
            purchaserate: "",
            regularrate: "",
            primerate: "",
            instituterate: "",
            wholesalerate: "",
            qnty: "",
            gst: "",
            info: "",
        },
      });

      useEffect(() => {

        if (isSubmitSuccessful) {
          reset();
          setPimage(defaultprdimageproduct);
          setGst("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isSubmitSuccessful]);

       //const onSubmit = data => console.log(data);
const onSubmit = async data => {
    const newId = doc(collection(db, 'Products')).id;
    const docRef1 = query(doc(db, "Products",newId));
    const docRef2 = query(doc(db, "Billentry",params.id,"Products",newId));
    setDoc(docRef1, {
      KEYID: newId,
      billreference:params.id,
      name: data.name,
      modelno: data.modelno,
      hsncode: data.hsncode,
      labelcode: data.labelcode,
      rate: Number(data.mrprate),
      purchaserate: Number(data.purchaserate),
      discountregular: Number(data.regularrate),
      discountprime: Number(data.primerate),
      discountinstitute: Number(data.instituterate),
      discountwholesale: Number(data.wholesalerate),
      qnty: Number(data.qnty),
      brand:brand || "unknown",
      category:category || "unknown",
      color:color || "unknown",
      size:size || "unknown",
      isgstincl: true,
      gst: Number(gst) || 0,
      info:data.info || "",
      image:pimage
    },{merge:true}).then(()=>{
        setDoc(docRef2, {
            KEYID: params.id,
            productreference:newId,
            name: data.name,
            modelno: data.modelno,
            hsncode: data.hsncode,
            labelcode: data.labelcode,
            rate: Number(data.mrprate),
            purchaserate: Number(data.purchaserate),
            discountregular: Number(data.regularrate),
            discountprime: Number(data.primerate),
            discountinstitute: Number(data.instituterate),
            discountwholesale: Number(data.wholesalerate),
            totqnty: Number(data.qnty),
            brand:brand || "unknown",
            category:category || "unknown",
            color:color || "unknown",
            size:size || "unknown",
            isgstincl: true,
            gst: Number(gst) || 0,
            info:data.info || "",
            image:pimage
          },{merge:true})
        }).then(()=>{
      console.log("submitted");
    })
};
console.log(errors);


const callbackimg = useCallback((i) => {
console.log(i)
setPimage(i)
})

const callbackbrand = useCallback((d) => {
    console.log(d)
    setBrand(d)
    })

    const callbackcategory = useCallback((d) => {
        console.log(d)
        setCategory(d)
        })

        const callbacksize = useCallback((d) => {
            console.log(d)
            setSize(d)
            })

            const callbackcolor = useCallback((d) => {
                console.log(d)
                setColor(d)
                })

                async function pullDataFromFirebase() {

                    const q = query(collection(db, "Billentry",params.id,"Products"));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        setBillentrydetails(querySnapshot.docs.map(doc => (doc.data())))

                })    
                        const q2 = query(collection(db, "Products"),where('billreference','==',params.id));
                        const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
                                setBillcurrentdetails(querySnapshot.docs.map(doc => (doc.data())))
                      })
            
                return () => {
                  unsubscribe();
                  unsubscribe2();
                }
              }
              useEffect(() => {
                pullDataFromFirebase();
              }, [])
 

              async function mergeArrays(){
                const a = billentrydetails;
                const b = billcurrentdetails;
                console.log(a)
                console.log(b)
                setResult(a.map(t1 => ({...t1, ...b.find(t2 => t2.KEYID === t1.productreference)})))
                console.log(result);
              }
              useEffect(() => {
                mergeArrays();
              }, [billcurrentdetails,billentrydetails])

  return (
      <>
       <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
 <div className="flex justify-around">
         <div className="w-[49%]">
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className='pt-4 flex items-center justify-between'>
   <div className="flex flex-row items-center"><div className="pr-2">Brand: </div><Brandautocomplete parentCallback={callbackbrand}/></div>
   <div className="flex flex-row items-center"><div className="pr-2">Category: </div><Categoryautocomplete parentCallback={callbackcategory}/></div>
</div>

<div className='pt-4 flex items-center justify-between'>
   <div className="flex flex-row items-center"><div className="pr-2">Color: </div><Colorautocomplete parentCallback={callbackcolor} /></div>
   <div className="flex flex-row items-center"><div className="pr-2">Size: </div><Sizeautocomplete parentCallback={callbacksize} /></div>
</div>
<div className="pt-4 flex flex-row justify-between flex-wrap">
            <Controller
        name="name" {...register("name")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[102%]" autoComplete="off"
        error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
           label="Name" variant="outlined" {...field} />}
      />
      </div>

    <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[25%]">
    <Controller
        name="modelno" {...register("modelno")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="Model No" variant="outlined" {...field} inputProps={{ maxLength:7 }}/>}
      />
</div>

    <div className="w-[25%]">
    <Controller
        name="hsncode" {...register("hsncode")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="HSN Code" variant="outlined" {...field} inputProps={{ maxLength:7 }}/>}
      />
</div>

    <div className="w-[25%]">
    <Controller
        name="labelcode" {...register("labelcode")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="Label Code" variant="outlined" {...field} inputProps={{ maxLength:7 }}/>}
      />
</div>

      <div className="w-[17.5%]">
      {<Controller
          name="gst" {...register("gst")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      color="primary"
      size="small"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='5'){
        setGst('5')
          }
       else if(e.target.value=='12'){
        setGst('12')
       }
       else if(e.target.value=='18'){
        setGst('18')
       }
       else if(e.target.value=='28'){
        setGst('28')
       }
        }
        }
    >
      <ToggleButton selected={gst=='5' ? true : false} value="5">5</ToggleButton>
      <ToggleButton selected={gst=='12' ? true : false} value="12">12</ToggleButton>
      <ToggleButton selected={gst=='18' ? true : false} value="18">18</ToggleButton>
      <ToggleButton selected={gst=='28' ? true : false} value="28">28</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}
      </div>
      </div>


      <div className="flex flex-col justify-start items-center">
        <div>
<img className="p-1 w-20 h-20 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
 src={pimage}
  alt="prdimage" />
  </div>
  <div className="pt-1">
<Controller
        name="imgcnt" 
        control={control} innerRef={register}
        render={() =>(<ImageConverter  parentCallback={callbackimg} />)}
/></div>
</div>


<div className="pt-4 flex flex-row justify-start gap-2 flex-wrap">
<div className="w-[24%]">
<Controller
        name="purchaserate" {...register("purchaserate")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.purchaserate}
        helperText={errors.purchaserate ? errors.purchaserate.message : ''}
          label="Purchase rate" variant="outlined" {...field}/>}
      />
      </div>
<div className="w-[24%]">
<Controller
        name="mrprate" {...register("mrprate")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.mrprate}
        helperText={errors.mrprate ? errors.mrprate.message : ''}
          label="MRP rate" variant="outlined" {...field}/>}
      />
</div>
<div className="w-[24%]">
<Controller
        name="qnty" {...register("qnty")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.qnty}
        helperText={errors.qnty ? errors.qnty.message : ''}
          label="Qnty" variant="outlined" {...field}/>}
      />
</div>
</div>

<div className="pt-4 flex flex-row justify-between flex-wrap">
<div className="w-[24%]">
<Controller
        name="regularrate" {...register("regularrate")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.regularrate}
        helperText={errors.regularrate ? errors.regularrate.message : ''}
          label="Regular rate" variant="outlined" {...field}/>}
      />
</div>
<div className="w-[24%]">
<Controller
        name="primerate" {...register("primerate")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.primerate}
        helperText={errors.primerate ? errors.primerate.message : ''}
          label="Prime rate" variant="outlined" {...field}/>}
      />
</div>
<div className="w-[24%]">
<Controller
        name="instituterate" {...register("instituterate")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.instituterate}
        helperText={errors.instituterate ? errors.instituterate.message : ''}
          label="Institute rate" variant="outlined" {...field}/>}
      />
</div>
<div className="w-[24%]">
<Controller
        name="wholesalerate" {...register("wholesalerate")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="number" size="small" autoComplete="off" 
        error={!!errors.wholesalerate}
        helperText={errors.wholesalerate ? errors.wholesalerate.message : ''}
          label="Wholesale rate" variant="outlined" {...field}/>}
      />
</div>
</div>


      <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[100%]">
    <Controller
        name="info" {...register("info")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="Info" variant="outlined" {...field} inputProps={{ maxLength:40 }}/>}
      />
</div>
      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
</div>

    </form>
    </div>
    <div className="w-[49%]">

      <table className="border text-center">
        <thead className="border-b">
          <tr>
            {/* <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Bill ref</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>prod ref</td>
            </th> */}
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Brand</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Category</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Name</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Model No.</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Rate</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Qnty</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Sold Out</td>
            </th>
            <th className="px-1 py-1 border-r">
            <td className='text-sm text-center'>Stock</td>
            </th>
          </tr>
        </thead>
        <tbody>
        {result.map((row,i) => {
          return (
       <tr key={i}>
            {/* <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.billreference}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.productreference}
            </td> */}
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.brand}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.category}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.name}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.modelno}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.rate}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.totqnty}
            </td> 
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.totqnty-row.qnty}
            </td>
            <td className='px-1 py-1 text-sm text-center text-gray-900 whitespace-nowrap border-r border-b'>
            {row.qnty}
            </td>
          </tr>
          )})}

        </tbody>
      </table>
      <div>Purchased Qnty:{result.reduce((a,b)=>a+b.totqnty,0)}</div>
      <div>Sold out:{result.reduce((a,b)=>a+(b.totqnty-b.qnty),0)}</div>
      <CircularProgressWithLabel value={((result.reduce((a,b)=>a+(b.totqnty-b.qnty),0)*100) / result.reduce((a,b)=>a+b.totqnty,0)) || 0} />
    </div>
    </div>
    </div>
    </Scrollbars>
    </>
  )
}
