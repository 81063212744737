import { React, useState, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Getweeklysales from "../components/Getweeklysales";
import Getdailysales from "../components/getdailysales";
import Footnotesinclude from "./Footnotesinclude";

import { Scrollbars } from 'react-custom-scrollbars-2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import NotesIcon from '@mui/icons-material/Notes';
import DiscountIcon from '@mui/icons-material/Discount';
import Cartdiscountedit from "./Cartdiscountedit";
import Cartdiscountcol from "./Cartdiscountcol";
import Cartdiscountrestore from "./Cartdiscountrestore";
import Cartqntypm from "./Cartqntypm";
import Cartqntyedit from "./Cartqntyedit";
import Cartqntycol from "./Cartqntycol";
import Cartsoldbycol from "./Cartsoldbycol";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

export default function Settings() {
    const [chartdata, setChartdata] = useState({
        datasets: [],
      });

    async function pullDataFromFirebase() {
    setChartdata({
            labels: ['Boston', 'Worcester', 'Springfield', 'Lowel', 'Cambridge', 'New Bedford'],
            datasets:[
              {
                label: 'Population',
                data:[
                      617594,
                      18045,
                      153060,
                      106519,
                      105162,
                      95072
                    ],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.6)',
                  'rgba(54, 162, 235, 0.6)',
                  'rgba(255, 206, 86, 0.6)',
                  'rgba(75, 192, 192, 0.6)',
                  'rgba(153, 102, 255, 0.6)',
                  'rgba(255, 159, 64, 0.6)',
                  'rgba(255, 99, 132, 0.6)'
                ]
              }       
    
            ],   
        })
    }
    useEffect(() => {
        pullDataFromFirebase();    
    },[])

      
  return (
      <>
      {/* <div className="max-w-[500px]">
        <Bar data={chartdata}  options={options} />
        </div> */}
        {/* <div className="mt-[200px] max-w-[500px]"> */}
        {/* <Getdailysales /> */}
        {/* <Getweeklysales /> */}
        {/* </div> */}
        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}


        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Footnotes</ListSubheader>}
    >
      <ListItem>
      <ListItemIcon>
          <NotesIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-footnotes" primary="Footnotes" />
      <Footnotesinclude />
    </ListItem>
</List>

        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Discount Column</ListSubheader>}
    >

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-discountcol" primary="Discount Column" />
      <Cartdiscountcol />
    </ListItem>

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-discountedit" primary="Discount Edit" />
      <Cartdiscountedit />
    </ListItem>

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-discountrestore" primary="Discount Restore" />
      <Cartdiscountrestore />
    </ListItem>

      </List>

      <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Quantity Column</ListSubheader>}
    >

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-quantitycol" primary="Quantity Column" />
      <Cartqntycol />
    </ListItem>

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-quantityedit" primary="Quantity Edit" />
      <Cartqntyedit />
    </ListItem>

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-quantitypm" primary="Quantity Inc/Dec" />
      <Cartqntypm />
    </ListItem>

      </List>


      <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Discount Column</ListSubheader>}
    >

    <ListItem>
      <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-soldbycol" primary="Soldby Column" />
      <Cartsoldbycol />
    </ListItem>
    </List>
    
        </div>
            </Scrollbars>
     </>
    )
}
