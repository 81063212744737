import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyAqLcogNpgUwDyBzS9u77C8pL_YDYztDCI",
    authDomain: "newnishanightiesdl.firebaseapp.com",
    projectId: "newnishanightiesdl",
    storageBucket: "newnishanightiesdl.appspot.com",
    messagingSenderId: "534287381706",
    appId: "1:534287381706:web:012997669dff678bad7033",
    measurementId: "G-1WBQJGXPC2"  
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
