import React, { useMemo, useState,useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { collection, query, onSnapshot,doc, getDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import { ExportToCsv } from 'export-to-csv';

import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import Scrollbars from 'react-custom-scrollbars-2';

function Productoverview() {

  const [data, setData] = useState([]);


  async function pullDataFromFirebase() {
    const colRef = collection(db, "Products");
    onSnapshot(colRef, (snapshot) => {
      setData(snapshot.docs.map(doc => (doc.data())))
    })
    console.log(data)
  }
    useEffect(() => {
        pullDataFromFirebase();    
    },[])

  const tcolumns = [
      {
        columnDefType: 'group',
        columns: [
          {
            _filterFn: 'fuzzy',
            accessorKey: 'name',
            columnDefType: 'data',
            filterFn: 'fuzzy',
            //footer: 'Name',
            header: 'Name',
            id: 'name'
          },
          {
            _filterFn: 'fuzzy',
            accessorKey: 'modelno',
            columnDefType: 'data',
            filterFn: 'fuzzy',
            //footer: 'Model',
            header: 'Model',
            id: 'modelno'
          },
        ],
        //footer: 'Info',
        header: 'Info',
        id: 'info'
      },
      {
        columnDefType: 'group',
        columns: [
          {
            filterFn: 'fuzzy',
            accessorKey: 'brand',
            columnDefType: 'data',
            //footer: 'Brand',
            header: 'Brand',
            id: 'brand'
          },
          {
            filterFn: 'startsWith',
            accessorKey: 'category',
            columnDefType: 'data',
            //footer: 'Category',
            header: 'Category',
            id: 'category'
          },
          {
            filterFn: 'fuzzy',
            accessorKey: 'size',
            columnDefType: 'data',
            //footer: 'Size',
            header: 'Size',
            id: 'size'
          },
          {
            filterFn: 'fuzzy',
            accessorKey: 'color',
            columnDefType: 'data',
            //footer: 'Color',
            header: 'Color',
            id: 'color'
          }
        ],
        //footer: 'Variants',
        header: 'Variants',
        id: 'variants'
      },
      {
        columnDefType: 'group',
        columns: [
          {
            filterFn: 'fuzzy',
            accessorKey: 'rate',
            columnDefType: 'data',
            //footer: 'Rate',
            header: 'Rate',
            id: 'rate'
          },
          {
            filterFn: 'fuzzy',
            accessorKey: 'qnty',
            columnDefType: 'data',
            //footer: 'Qnty',
            header: 'Qnty',
            id: 'qnty'
          },
        ],
        //footer: 'Details',
        header: 'Details',
        id: 'details'
      }
    ];

    const tdata = 
    data

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: tcolumns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    
    const handleExportRows = (rows) => {
      csvExporter.generateCsv(rows.map((row) => row.original));
    };
    

  return (
    <>
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
            <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
    <div className="py-1 px-4 text-4xl">Products Overview </div>
<MaterialReactTable
  columns={tcolumns}
  data={tdata}
  initialState={{ density: 'compact' }}
  enableColumnOrdering
  enableColumnResizing
  enableColumnFilterChangeMode 
  // enableGrouping
  enablePinning
  positionToolbarAlertBanner="bottom"
  renderRowActionMenuItems={function noRefCheck(){}}
  //renderTopToolbarCustomActions={function noRefCheck(){}}
  renderTopToolbarCustomActions={({table}) => (
    <>
    <Box
      sx={{ display: 'flex', justifyContent:'end',width:'100%', gap: '1rem', px: '0.5rem', py: '0.5rem', flexWrap: 'wrap' }}
    >    
    {/* <div className="py-1 px-4 text-2xl">Number Of Products: {table.getPrePaginationRowModel().rows.length || "--"}</div> */}

                  {/* <Tooltip title="Export" placement="bottom" arrow>
          <FileDownloadIcon size="medium" className='cursor-pointer' disabled={table.getPrePaginationRowModel().rows.length === 0} onClick={() =>handleExportRows(table.getPrePaginationRowModel().rows)}  />
      </Tooltip> */}
      </Box>
      </>
  )}
/>
</div>
</Scrollbars>
</>
  );
};

export default Productoverview;
