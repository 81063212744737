import { useEffect, useState, useCallback, useRef } from "react";
import { db } from "../firebase-config";
import SimpleToast from '../components/Toast/SimpleToast';
import { collection, query, where, onSnapshot, doc, getDoc, getDocs, setDoc, deleteDoc, orderBy, increment, Timestamp } from "firebase/firestore";
import Finaldiscount from "./Finaldiscount";

import Paymentmethods from '../components/Paymentmethods';
import Paymenttender from '../components/Paymenttender';
import Billsubmit from './Billsubmit';
import { numbertoCurrency } from "../components/NumbertoCurrency";
import Creditsale from "./Creditsale";
import Paymentmethodcash from './Paymentmethodcash';
import Paymentmethodcredit from './Paymentmethodcredit';

function Billpay() {
  const [subtotalbillcost, setSubtotalbillcost] = useState(0);
  const [totalbillcost, setTotalbillcost] = useState(0);
  const [totaldiscount, setTotaldiscount] = useState(0);
  const [overalldiscount, setOveralldiscount] = useState(0);
  const [isoveralldiscount, setisoveralldiscount] = useState(false);

  const [isbillcostEPaymet,setIsbillcostEPaymet]= useState(false); // E-Equal
  const [billcostEPaytend,setBillcostEPaytend]= useState();

  const [totalqnty, setTotalqnty] = useState(0);
  const [totalitems, setTotalitems] = useState(0);

  const [iscreditsale, setiscreditsale] = useState(false);
  const [paidamount, setPaidamount] = useState(0);

  async function pullDataFromFirebase() {

    const q = query(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let subtotal=0;
      let total=0;
      let totalqntyv=0;
      setTotalbillcost(0)
      querySnapshot.forEach((doc) => {
        subtotal=(subtotal + (doc.data().rate*doc.data().qnty));
        total=(total + (doc.data().amount));
        totalqntyv = (totalqntyv + (doc.data().qnty));
      });
      setSubtotalbillcost(subtotal)
      setTotalbillcost(total)
      setTotaldiscount(subtotal-total)
      setTotalqnty(totalqntyv)
      setTotalitems(querySnapshot.docs.length)
    });

    const unsubscribe2 = onSnapshot(doc(db,"SHOP1LIVECART","CART1"), (doc) => {
      if(doc.exists && doc.data().totalqnty!=undefined){
      //setQnty(doc.data().totalqnty);
      setOveralldiscount(doc.data().overalldiscount);
      setisoveralldiscount(doc.data().isoveralldiscount);
      setiscreditsale(doc.data().iscreditsale);
      setPaidamount(doc.data().paidamount)
      }
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    }
  }
  useEffect(() => {
    pullDataFromFirebase();
  }, [])

  const docRef = doc(db, "SHOP1LIVECART", "CART1");
  setDoc(docRef, {
    billcostsub:subtotalbillcost,
    billcost: totalbillcost,
    totaldiscount:totaldiscount,
    totalqnty: totalqnty,
    totalitems: totalitems,
  }, { merge: true });
  

  const callbackpm = useCallback((c) => {
    console.log(c)
    setIsbillcostEPaymet(c)
})

const callbackpt = useCallback((c) => {
  console.log(c)
  setBillcostEPaytend(c)
})


  return (
    <>
<div className="w-[550px]">
    <div className="flex flex-row justify-center flex-wrap">
      <div className="m-3 h-[100px] w-[225px]  rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  hover:from-pink-500 hover:to-yellow-500  hover:text-black">
      <div className="p-2 text-white">Sub Total</div>
      <div className="flex justify-end py-2 px-2 text-3xl text-white">{numbertoCurrency(subtotalbillcost)}</div>
      </div>
      <div className="m-3 h-[100px] w-[225px]  rounded-lg bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500">
      <div className="p-2 text-white">Total Discount</div>
      <div className="flex justify-end py-2 px-2 text-3xl text-white">{numbertoCurrency(totaldiscount)}</div>
      </div>
      <div className="m-3 h-[100px] w-[225px]  rounded-lg bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500">
      <div className="flex justify-center items-center">
      <div className="p-2 text-white w-[50%] border-r-2 border-white">
        <div className="flex flex-col justify-center items-center">
        <div className="text-white">Items</div>
        <div className="p-3 text-3xl text-white">{totalitems}</div>
        </div>
        </div>
        <div className="p-2 text-white w-[50%]">
        <div className="flex flex-col justify-center items-center">
        <div className="text-white">Quantities</div>
        <div className="p-3 text-3xl text-white">{totalqnty}</div>
        </div>
        </div>
      </div>
      </div>
      <div className="m-3 h-[100px] w-[225px]  rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  hover:from-pink-500 hover:to-yellow-500">
      <div className="p-2 text-white">Total Amount</div>
      <div className="flex justify-end py-2 px-2 text-3xl text-white">{numbertoCurrency(totalbillcost)}</div>
      </div>
    </div>
 

 <div className="hidden  flex flex-row justify-center flex-wrap">
<div>
        <Finaldiscount billcostsub={subtotalbillcost} billcostdata={Math.round(totalbillcost)}  overalldiscount={overalldiscount} />
        </div>
        <div>
        <Creditsale billcost={totalbillcost}/>
        </div>
        </div>

        <div className="p-4 w-full flex flex-row items-center">
        <Finaldiscount billcostsub={subtotalbillcost} billcostdata={Math.round(totalbillcost)}  overalldiscount={overalldiscount} />
</div>

<div className="p-4 w-[550px] flex flex-col items-center">
  {/* <Paymentmethods parentCallback={callbackpm} /> */}
  <Paymentmethodcash billcostdata={Math.round(totalbillcost)} parentCallback={callbackpm} />
  {/* { iscreditsale===false ? <Paymentmethodcash billcostdata={Math.round(totalbillcost)} parentCallback={callbackpm} /> : <Paymentmethodcredit parentCallback={callbackpm} />} */}
    {/* <Paymenttender  parentCallback={callbackpt} /> */}
    </div>

    <Billsubmit billcost={totalbillcost} paymentmetstatus={isbillcostEPaymet}/>

</div>

    </>
  )
}
export default Billpay