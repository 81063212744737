import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useEffect, useState, useId } from "react";
import { collection, onSnapshot,doc, getDoc, setDoc, deleteDoc} from "firebase/firestore";
import {db} from "../firebase-config";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

function Categoryautocomplete(props) {
  const id=useId()
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [zIndex, setZindex] = useState(3);

    async function pullDataFromFirebase() {
        const colRef = collection(db, "Category");
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(data)
      }


    useEffect(() => {
       pullDataFromFirebase();
    }, [])
  // note: the id field is mandatory
  const items = data

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    
    console.log(string, results)
    setSearchString(string);
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect  = async(item)  => {
    // the item selected
    console.log(item)
    props.parentCallback(item.category)

  }

  const handleOnFocus = (e) => {
    console.log('Focused');
    e.target.select();
    setZindex((zIndex) => zIndex + 1);
  }

  const handleOnClear = async() => {
    console.log('cleared')

  }

  const formatResult = (item, index) => {
    return (
      <div key={index}>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.KEYID}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>name: {item.category}</span>
      </div>
    )
  }

  return (
      <>
        <div style={{ width: 250 }}>
        <ReactSearchAutocomplete id={id} fuseOptions={{ keys: ["category"] }} 
            items={items}
            resultStringKeyName={"category"}
            inputSearchString={searchString}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={(e)=>{handleOnFocus(e)}}
            onClear={handleOnClear}
            showNoResults={false}
            formatResult={formatResult}
            styling={{zIndex:zIndex,height: "40px"}}
          /> 
          </div>
    </>
  )
}

export default Categoryautocomplete