
import { Toaster } from 'react-hot-toast';

function SimpleToast() {

  return (
    <>
      <Toaster  />
    </>
  )
}

export default SimpleToast;
