import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Customeraddform from "./Customeraddform";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useHotkeys } from 'react-hotkeys-hook'
import Chip from '@mui/material/Chip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

Customeraddform.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Customeradd2() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});


useHotkeys('ctrl+q', () => {
  setOpen(prevState =>
    prevState == false ? true : false
  )
},// Add your hotkeys options in here
  { enableOnTags: ["TEXTAREA", "INPUT", "SELECT"] } // To enable on ['TEXTAREA', 'INPUT'] 
)

  return (
    <>
    {/* <IconButton color="primary" onClick={handleClickOpen} aria-label="add to shopping cart">
  <AddIcon />
</IconButton> */}
<Chip icon={<PersonOutlineIcon />} label="Add New Customer"  onClick={handleClickOpen}/>

      <Customeraddform
        open={open}
        onClose={handleClose}
        parentCallback={callback}
      />
    </>
  );
}