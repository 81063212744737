import { React, useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";
import { TextField, Button, CardActionArea } from "@mui/material";
import { collection, query, onSnapshot,doc, getDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import PaginationComp from '../components/PaginationComp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { format } from 'date-fns'
import Customerdelete from "./Customerdelete";
import Customeredit from "./Customeredit";
import Customerprofile from "./Customerprofile";
import { Link } from 'react-router-dom'

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { capitalize } from '@material-ui/core';
import { upperCase,titleCase } from "text-case";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Billdialog from "../bills/Billdialog";

function Customerbills(props) {


    const [showbill, setShowbill] = useState(false);
    const [billdate, setBilldate] = useState('');
    const [billnumber, setBillnumber] = useState('');

    const [data, setData] = useState([]);

    async function pullDataFromFirebase() {
        const colRef = query(collection(db, "Customers",props.customerid,"BILLS"),orderBy('billnumbero','desc'));
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(JSON.stringify(data))
      }
      useEffect(() => {
          pullDataFromFirebase();    
      },[])

      const [currentPage, setCurrentPage] = useState(1)

      
    const pageSize = 15
    const totalItems = data.length
    const totalPages = Math.ceil((totalItems / pageSize))
    const startIndex = (currentPage - 1) * pageSize
    let pageNumbers = []
    for (let i = 1; i <= totalPages; i++)
    {
        pageNumbers.push(i)
    }
    const paginatedCustomerlist = data.slice(startIndex, startIndex+pageSize)

    const callback = useCallback((c) => {
        console.log(c)
        setCurrentPage(c)
    })
 
    let delay_index = -1;

    const callback2 = useCallback((c) => {
        setShowbill(c);
        setBilldate('');
        setBillnumber('');
    });

    function handleCardClick(el){
    setShowbill(true);
     setBilldate(el.date); 
     setBillnumber(el.billnumber)
    }

    return (
        <>
        <div>Totalbills: {paginatedCustomerlist.length}</div>
        {/* <div>Purchased Amount:{paginatedCustomerlist.reduce((a,b)=>a+(b.billcost || 0),0)}</div> */}

        {/* <div className="py-1 px-4 text-4xl">Customer Search</div> */}


<div className="flex flex-wrap justify-center">


                            {paginatedCustomerlist.map((el, index) => {
                                             delay_index += (index!==0) ? 10 : 0;
                                             const delay = Math.max(0, delay_index*100);
                                            return(
                                                <Grow key={el.KEYID}
                                          in={true}
                                          style={{ transformOrigin: '0 0 0' }}
                                          {...({ timeout: 1000+delay })}
                                        >
                                         <Card key={el.KEYID} className="w-[270px] m-1 border border-indigo-500" >
                                     <CardActionArea onClick={()=>{handleCardClick(el)}}>
                                      <CardContent sx={{ p:1, '&:last-child': { pb: 1 }}}>
                                          <div className="px-2 flex flex-row justify-between items-center h-[30px]">
                                          <div className="flex flex-row items-center">
                                               <div className="font-bold text-sm " >{el.date}</div> 
                                                </div>
                                              <div className="flex flex-row items-center">
                                               <div className="font-bold text-sm " >Bill Id:{el.billnumber}/</div> 
                                               <div className="font-bold text-sm " >Bill No:{el.billnumbero}</div> 
                                                </div>
                                          </div>  
                                          {/* <div>
                                                <div className="font-bold text-sm " >{el.billcost}</div> 
                                                </div>                               */}
                                      </CardContent>
                                      </CardActionArea>
                                    </Card>

                                        </Grow>
                                
                                            )
                            })}
                        </div>

            <PaginationComp passeddata={pageNumbers.length} parentCallback={callback}/>
            {showbill==true && <Billdialog date={format(new Date((moment(billdate, 'DD-MM-YYYY').toISOString())), 'dd-MM-yyyy')} billnumber={Number(billnumber).toString()} parentCallback2={callback2} />}

            </>
    );
}

export default Customerbills