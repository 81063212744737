
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale.css';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { doc, setDoc, deleteDoc, increment, getDocs, collection, query } from "firebase/firestore";
import { db } from "../firebase-config";
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';

function Addresssetasprimary(props) {


    const handleOnClickDelete = async(e) => {
        const querySnapshot = await getDocs(collection(db, "Employees",props.employeeid,"addresses"));
        querySnapshot.forEach((docz) => {
          console.log(docz.data().KEYID,props.passdata)
          const docRef = query(doc(db, "Employees",props.employeeid,"addresses",docz.data().KEYID));
          setDoc(docRef, {
            isprimary:(docz.data().KEYID === props.passdata) ? "yes" : "no"
          }, { merge: true });
        });
    }

    return (
        <Tooltip title="Mark as Primary" placement="top">
            <IconButton className='bg-green' aria-label="clear" onClick={(e)=>handleOnClickDelete(e)}>
                <CheckCircleOutlineIcon />
            </IconButton>
            </Tooltip>
    );
}

export default Addresssetasprimary;
