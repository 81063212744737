import React from 'react'

export default function Footnotes() {
  return (
    <div>
                        <div className="px-[10px] py-[5px]">

  <table className="border border-collapse w-[100%] text-sm  p-[20px]">
<tbody>
  <tr>
    <td className="text-center text-xs p-[4px]">SUBJECT TO PUDUKKOTTAI JURISDICTION. THIS IS COMPUTER GENERATED INVOICE.</td>
  </tr>
  </tbody>

  </table>
                </div>
    </div>
  )
}
