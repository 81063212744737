import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useEffect, useState, useId } from "react";
import { collection, onSnapshot, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function Paymenttender(props) {

    const [ytotalamount, setYtotalamount] = useState(0);


    async function pullDataFromFirebase() {
        const unsubscribe2 = onSnapshot(doc(db,"SHOP1LIVECART","CART1"), (doc) => {
            if(doc.exists && doc.data().totalqnty!=undefined){
                setYtotalamount(doc.data().billcost)
            }
          });
          return () => {
            unsubscribe2();
          }
      }
      
      useEffect(() => {
          pullDataFromFirebase();
      }, [ytotalamount])
    


const [receivedamnt, setReceivedamnt] = useState(0);
const [amounttend, setAmounttend] = useState(0);

function handleonChangecash(e){
    setReceivedamnt(e.target.value);

    (e.target.value==ytotalamount) ? props.parentCallback(true) : props.parentCallback(false)

}


const handleFocus = (event) => event.target.select();


    return (
        <>
            <Box component="form" sx={{'& > :not(style)': { m: 1, width: '25ch' }, }} noValidate autoComplete="off">
                <TextField value={receivedamnt} onChange={(e)=>{handleonChangecash(e)}} onFocus={handleFocus} type="number" label="Amount Received"/>
                {
                ytotalamount > receivedamnt ? 
                <p>Amount to be Collected {ytotalamount - receivedamnt}</p> :
                <p>Amount to be Returned {receivedamnt - ytotalamount}</p>
            }

            </Box>
        </>
    )
}

export default Paymenttender