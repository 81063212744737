import { React, useState, useEffect } from "react";
import { Pagination } from "react-headless-pagination";

function PaginationComp(props) {

    const [page, setPage] = useState(0);

const handlePageChange = (page) => {
  setPage(page);
  props.parentCallback(page+1)
};

    return (
        <>
        {props.passeddata>0 && <div className="flex justify-center w-[100%]">
          <Pagination
            currentPage={page}
            setCurrentPage={handlePageChange}
            totalPages={props.passeddata}
            edgePageCount={2}
            middlePagesSiblingCount={2}
            className="cursor-pointer	flex w-fit m-2 border-solid border-2 border-indigo-600 rounded"
            truncableText="..."
            truncableClassName="px-4 py-2 border-x border-indigo-600"
          >
            <Pagination.PrevButton className="p-2 w-[100px] border-r border-indigo-600">Previous</Pagination.PrevButton>
      
            <div className="flex items-center justify-center flex-grow">
              <Pagination.PageButton
                activeClassName="px-4 py-2 border-x border-indigo-600 bg-indigo-600 text-white"
                inactiveClassName="px-4 py-2 border-x border-indigo-600"
                className="px-4 py-2 border-x border-indigo-600"
              />
            </div>
      
            <Pagination.NextButton className="p-2 w-[100px] border-l border-indigo-600">Next</Pagination.NextButton>
          </Pagination>
          </div>
          }
        </>
      );
}

export default PaginationComp