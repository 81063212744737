import { useEffect, useState, useCallback } from "react";
import { collection, onSnapshot, query, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { TextField, Button } from "@mui/material";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { upperCase,titleCase } from "text-case";
import { numbertoCurrency } from "../components/NumbertoCurrency";
import VisibilityIcon from '@mui/icons-material/Visibility';


import moment from "moment";
import { format } from 'date-fns'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { el } from "date-fns/locale";
import Billdialog from "../bills/Billdialog";

export default function Billglance() {

  const [totbills, setTotbills] = useState(0);
  const [deletedbills, setDeletedbills] = useState([]);

  const [billdetails, setBilldetails] = useState([]);

  const [date, setDate] = useState(moment(new Date(), 'DD-MM-YYYY'));

  const [showbill, setShowbill] = useState(false);
  const [billdate, setBilldate] = useState('');
  const [billnumber, setBillnumber] = useState('');

  async function pullDataFromFirebase() {
    setBilldetails([])
  const docRefBills =doc(db, "SHOP1BILLS",format(new Date((moment(date, 'DD-MM-YYYY').toISOString())), 'dd-MM-yyyy'));
  onSnapshot(docRefBills, (doc) => {
    setTotbills(doc.data().totalbills);
    doc.data().deletedbills!=undefined ? setDeletedbills(doc.data().deletedbills) :setDeletedbills([]);
});

  }
  useEffect(() => { getBilldetails() }, [totbills])

  async function getBilldetails(){
    setBilldetails([])

    for (let i = 1; i <= totbills; i++) {
      if(!deletedbills.includes(i)){
      const colRef = collection(db, "SHOP1BILLS",format(new Date((moment(date, 'DD-MM-YYYY').toISOString())), 'dd-MM-yyyy'),i.toString());
      onSnapshot(colRef, (snapshot) => {
        const ss = snapshot.docs.map(doc => doc.data())
        setBilldetails(billdetails => [...billdetails, ss])
      })
     // console.log(JSON.stringify(billdetails))
    }
    }
  }

  useEffect(() => {
    pullDataFromFirebase();
  }, [date])

  useEffect(() => { console.log(billdetails) }, [billdetails])


  const handleOnClick = (e,bd,bno) => {
    setShowbill(true);
    setBilldate(bd)
    setBillnumber(bno)
  };

  
  const handleChange = (newValue) => {
    setDate(newValue);
  };
  let delay_index = -1;

  const callback2 = useCallback((c) => {
    setShowbill(c);
});
  return (
    <>
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}

<div className="p-2">
<LocalizationProvider dateAdapter={AdapterDateFns}>
   <MobileDatePicker
          label="Select Date"
          inputFormat="dd-MM-yyyy"
          value={date}
          onChange={handleChange}
          maxDate={new Date()}
          disableFuture={true}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        </LocalizationProvider>
        </div>

        <div className="flex flex-wrap justify-center">
{Object.keys(billdetails).map((index) => {
  
         return (
           <div key={index}>
              {billdetails[index].map((dataItem) => {
                delay_index += (index!==0) ? 1 : 0;
                const delay = Math.max(0, delay_index*100);
               return(
                   <Grow key={dataItem.KEYID}
             in={true}
             style={{ transformOrigin: '0 0 0' }}
             {...({ timeout: 1000+delay })}
           >
            <Card key={dataItem.KEYID} className="w-[270px] m-1 border border-indigo-500" >
         <CardContent sx={{ p:1, '&:last-child': { pb: 1 }}}>
             <div className="flex flex-row justify-between items-center h-[35px]">
                 <div className="flex flex-row items-center">
                  <div className="pt-1 pl-2 font-bold text-sm " >{dataItem.billid+"/"+dataItem.billnumbero}</div> 
                   </div>
                 <div>
   </div>
             </div>
             <hr className="my-1"/>
             <div className="flex flex-row justify-between">                                         
<div>{dataItem.shoptype}</div>
<div>{dataItem.customertype}</div>
         </div>
             <div className="flex flex-row justify-between">                                         
<div>Payment Methods:{(dataItem.pcash>0 && 'CASH')} {(dataItem.pcard>0 && 'CARD')} {(dataItem.pupi>0 && 'UPI')} {(dataItem.pfinance>0 && 'FINANCE')}</div>
         </div>
         <div className="text-center text-lg font-medium	text-red-600">{numbertoCurrency(dataItem.billcost)}</div>
         </CardContent>
         <CardActions>
           <div className="flex justify-between w-[100%]">
           <Button variant="outlined" onClick={(e)=>handleOnClick(e,dataItem.date,dataItem.billnumber)}>
<div fontSize="15px">&nbsp;VIEW BILL</div>
</Button>
</div>
         </CardActions>
       </Card>
           </Grow>
   
               )
               })}
           </div>
         )
       })}
       </div>
       </div>
       {showbill==true && <Billdialog date={billdate} billnumber={(billnumber).toString()} parentCallback2={callback2} />}

       </Scrollbars>
    </>
  )
}
