import { useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import {db} from "../../firebase-config";
import { numbertoCurrency } from "../../components/NumbertoCurrency";

function Taxsummarybill(props) {
    const [taxtypezr, setTaxtypezr] = useState("0.00");
    const [taxtypefv, setTaxtypefv] = useState("0.00");
    const [taxtypetw, setTaxtypetw] = useState("0.00");
    const [taxtypeen, setTaxtypeen] = useState("0.00");
    const [taxtypete, setTaxtypete] = useState("0.00");
    const [taxtotal, setTaxtotal] = useState("0.00");

    async function pullDataFromFirebase() {
console.log("Taxsummarybillcalled")
console.log(props.billdate,props.billnumber)

const q = query(collection(db, "SHOP1BILLS",props.billdate,props.billnumber,"CART1","PRODUCTS"));
const unsubscribe = onSnapshot(q, (querySnapshot) => {
  let sum0 = 0;
  let sum5 = 0;
  let sum12 = 0;
  let sum18 = 0;
  let sum28 = 0;
  let sumtot = 0;
  querySnapshot.forEach((doc) => {
    sumtot=sumtot+doc.data().taxamount;
    doc.data().gst ===5 ? sum5=sum5+doc.data().taxamount :
    doc.data().gst ===12 ? sum12=sum12+doc.data().taxamount :
    doc.data().gst ===18 ? sum18=sum18+doc.data().taxamount :
    doc.data().gst ===28 ? sum28=sum28+doc.data().taxamount : sum0=sum0+doc.data().taxamount
  });
  setTaxtypezr(sum0.toFixed(2));
  setTaxtypefv(sum5.toFixed(2));
  setTaxtypetw(sum12.toFixed(2));
  setTaxtypeen(sum18.toFixed(2));
  setTaxtypete(sum28.toFixed(2));
  setTaxtotal(sumtot.toFixed(2));
});

return () => {
  unsubscribe();
}

}

useEffect(() => {
  pullDataFromFirebase();
}, [props.billdate,props.billnumber])


  return (
    <>

          {taxtypefv!=="0.00" && <tr className="bg-white border-b">
          <td className=" whitespace-nowrap  border-r">{props.billdate}</td>

              <td className=" whitespace-nowrap  border-r">{props.billid}/{props.billnumbero}</td>

              <td className=" whitespace-nowrap  border-r">5%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                2.5%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypefv/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               2.5%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypefv/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypefv}
              </td>
            </tr>}
            {taxtypetw!=="0.00" && <tr className="bg-white border-b">
            <td className=" whitespace-nowrap  border-r">{props.billdate}</td>

<td className=" whitespace-nowrap  border-r">{props.billid}/{props.billnumbero}</td>
              <td className=" whitespace-nowrap  border-r">12%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                6%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypetw/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               6%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypetw/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypetw}
              </td>
            </tr>}
            {taxtypeen!=="0.00" && <tr className="bg-white border-b">
            <td className=" whitespace-nowrap  border-r">{props.billdate}</td>

<td className=" whitespace-nowrap  border-r">{props.billid}/{props.billnumbero}</td>
              <td className=" whitespace-nowrap  border-r">18%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                9%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypeen/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               9%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypeen/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypeen}
              </td>
            </tr>}
            {taxtypete!=="0.00" && <tr className="bg-white border-b">
            <td className=" whitespace-nowrap  border-r">{props.billdate}</td>

<td className=" whitespace-nowrap  border-r">{props.billid}/{props.billnumbero}</td>
              <td className=" whitespace-nowrap  border-r">28%</td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
                14%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypete/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
               14%
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap border-r">
              {taxtypete/2}
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtypete}
              </td>
            </tr>}
            {/* <tr className="bg-white border-b">
              <td colSpan="5" className="text-sm text-gray-900 whitespace-nowrap text-center border-r">
                Total
              </td>
              <td className="text-sm text-gray-900 whitespace-nowrap">
              {taxtotal}
              </td>
            </tr> */}


</>
  )
}

export default Taxsummarybill;