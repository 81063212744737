import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Addressaddform from "./Addressaddform";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';

Addressaddform.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Addressadd(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});

  return (
    <>
      <Card className="h-[205px] w-[150px] m-1 border-2 border-indigo-500">
        <CardActionArea onClick={handleClickOpen} >
<CardContent sx={{ p:1, '&:last-child': { pb: 1 }}} className="h-[205px] flex flex-col justify-center items-center">
<IconButton component="span" sx={{ m:1, border: 2, borderStyle:"dashed", borderColor:"indigo"}} color="secondary" aria-label="Add" size="small">
  <AddIcon fontSize="large" />
</IconButton>

<p className="m-0 ">Add Address</p>
</CardContent>
</CardActionArea>
</Card>
      <Addressaddform employeeid={props.employeeid}
        open={open}
        onClose={handleClose}
        parentCallback={callback}
      />
    </>
  );
}