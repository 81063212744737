import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useEffect, useState, useId } from "react";
import { collection, onSnapshot,doc, getDoc, setDoc, deleteDoc} from "firebase/firestore";
import {db} from "../firebase-config";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

function Soldbyemp(props) {
  const id=useId()
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [showsearchbox, setShowsearchbox] = useState(true);
    const [zIndex, setZindex] = useState(3);

    async function pullDataFromFirebase() {
        const colRef = collection(db, "Employees");
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(data)
      }


    useEffect(() => {
       pullDataFromFirebase();
    }, [])
  // note: the id field is mandatory
  const items = data

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    
    console.log(string, results)
    setSearchString(string);
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect  = async(item)  => {
    // the item selected
    console.log(item)

    const custRef = doc(db, "SHOP1LIVECART","CART1","PRODUCTS",props.passdata.KEYID); //props.passdata cart product id
    setDoc(custRef, {      
      soldbyKEYID:item.KEYID,
      soldbyname: item.name,
   },{merge:true});

  }

  const handleOnFocus = (e) => {
    console.log('Focused');
    e.target.select();
    setZindex((zIndex) => zIndex + 1);
  }

  const handleOnClear = async() => {
    console.log('cleared')
    const custRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", props.passdata.KEYID);
    setDoc(custRef, {      
      soldbyKEYID:'unknown',
      soldbyname: 'unknown',
   },{merge:true});
  }

  const formatResult = (item, index) => {
    return (
      <div key={index}>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.KEYID}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
      </div>
    )
  }


  function handleOnclickedit(e){
    setShowsearchbox(true);
  }

  function handleOnclickdone(e){
    setShowsearchbox(false);
  }

  return (
      <>
        <div style={{ width: 250 }}>
        { showsearchbox? 
        <>
        <div className="grid grid-cols-3 gap-7 content-between	 items-center">
          <div className="col-span-2" style={{ width: 200 }}>
        <ReactSearchAutocomplete id={id} fuseOptions={{ keys: ["name"] }} 
            items={items}
            resultStringKeyName={"name"}
            inputSearchString={props.passdata ? props.passdata.soldbyname : searchString}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={(e)=>{handleOnFocus(e)}}
            onClear={handleOnClear}
            showNoResults={false}
            formatResult={formatResult}
            styling={{zIndex:zIndex,height: "40px"}}
          /> 
          </div>
          <div><DoneIcon onClick={(e)=>{handleOnclickdone(e)}}/></div>
          </div>
          </>
          : <div>{props.passdata.soldbyname}<EditIcon className='px-1' onClick={(e)=>{handleOnclickedit(e)}}/></div>}
        </div>
    </>
  )
}

export default Soldbyemp