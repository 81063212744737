import React from 'react'
import { useParams } from "react-router-dom"
import Addressadd from './Addressadd'
import Addresseslist from './Addresseslist'
import { Scrollbars } from 'react-custom-scrollbars-2';

export default function Supplierprofile(props) {
    const params = useParams()

  return (
      <>  
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
    <Addresseslist supplierid={params.id} />
    <Addressadd supplierid={params.id} />
        </div>
      </Scrollbars>
      </>
  )
}
