import { useEffect, useState, useMemo } from "react";
import { collection, query, onSnapshot,doc, getDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
    GroupingState,
    useReactTable,
    getPaginationRowModel,
    getFilteredRowModel,
    getCoreRowModel,
    getGroupedRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
  } from '@tanstack/react-table'
  import { IconButton } from '@mui/material';
  
  import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
  import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
  import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
  import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
  
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
  import GroupWorkIcon from '@mui/icons-material/GroupWork';
  import WorkspacesIcon from '@mui/icons-material/Workspaces';

  import Box from '@mui/material/Box';
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from "react";

  
export default function Productaggregation() {

  const columns = [
      {
        header: 'Variants',
        columns: [
          {
            accessorKey: 'brand',
            header: 'Brand',
            cell: info => info.getValue(),
          },
          {
            accessorFn: row => row.category,
            id: 'category',
            header: () => <span>Category</span>,
            cell: info => info.getValue(),
          },
          {
            accessorFn: row => row.color,
            id: 'color',
            header: () => <span>Color</span>,
            cell: info => info.getValue(),
          },
          {
            accessorFn: row => row.size,
            id: 'size',
            header: () => <span>Size</span>,
            cell: info => info.getValue(),
          },
        ],
      },
      {
        header: 'Info',
        columns: [
          {
            accessorKey: 'name',
            header: 'Name',
            cell: info => info.getValue(),
          },
          {
            accessorKey: 'modelno',
            header: 'Model',
            cell: info => info.getValue(),
          }
        ],
      },
      {
        header: 'Details',
        columns: [
          {
            accessorKey: 'rate',
            header: 'Rate',
            cell: info => info.getValue(),
          },
          {
            accessorKey: 'qnty',
            header: 'Qnty',
            cell: info => info.getValue(),
          },
        ],
      },
    ];

  const [data, setData] = useState([]);

  async function pullDataFromFirebase() {
    const colRef = collection(db, "Products");
    onSnapshot(colRef, (snapshot) => {
      setData(snapshot.docs.map(doc => (doc.data())));
    })
    console.log(data)
  }
  useEffect(() => {
    pullDataFromFirebase();
}, [])

  const [grouping, setGrouping] = useState<GroupingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
    },
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
  })

  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
    <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
      
    <div>
      <table className='shadow-md w-[100%]'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {header.column.getCanGroup() ? (
                          // If the header can be grouped, let's add a toggle
                          <button
                            {...{
                              onClick: header.column.getToggleGroupingHandler(),
                              style: {
                                cursor: 'pointer',
                              },
                            }}
                          >
                            {/* {header.column.getIsGrouped()
                              ? `🛑(${header.column.getGroupedIndex()}) `
                              : `👊 `} */}
                              {header.column.getIsGrouped()
                              ? <GroupWorkIcon fontSize="small" color="primary"/>
                              : <WorkspacesIcon fontSize="small" color="primary"/>}
                          </button>
                        ) : null}{' '}
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      {...{
                        key: cell.id,
                        style: {
                          background: cell.getIsGrouped()
                            ? '#FFDCAE'
                            : cell.getIsAggregated()
                            ? '#CED89E'
                            : cell.getIsPlaceholder()
                            ? '#ADCF9F'
                            : 'white',
                        },
                      }}
                    >
                      {cell.getIsGrouped() ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <button
                            {...{
                              onClick: row.getToggleExpandedHandler(),
                              style: {
                                cursor: row.getCanExpand()
                                  ? 'pointer'
                                  : 'normal',
                              },
                            }}
                          >
                            {row.getIsExpanded() ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}{' '}
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}{' '}
                            ({row.subRows.length})
                          </button>
                        </>
                      ) : cell.getIsAggregated() ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        flexRender(
                          cell.column.columnDef.aggregatedCell ??
                            cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* <div className="h-2" /> */}
      <div className=' shadow-md w-[100%]'>
      <div className="flex justify-center	gap-2 items-center h-32">
        <IconButton color="primary" 
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton color="primary" 
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
         <KeyboardArrowLeftIcon />
      </IconButton>
      <h3>{table.getState().pagination.pageIndex + 1}</h3>
        <IconButton color="primary" 
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <KeyboardArrowRightIcon />
      </IconButton>
        <IconButton color="primary" 
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
         <KeyboardDoubleArrowRightIcon />
      </IconButton>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
      </div>
      </div>
      {/* <div>{table.getRowModel().rows.length} Rows</div>

      <pre>{JSON.stringify(grouping, null, 2)}</pre> */}
    </div>
      

    </div>
  </Scrollbars>
  )
}
