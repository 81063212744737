import { React, useState, useEffect, useCallback, forwardRef } from "react";
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm, Controller } from "react-hook-form";

import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc,limit, getDoc, addDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  addresstitle: z.string().min(3, { message: 'Atleast 3 characters' }),
  addresstype: z.string(),
  //isprimary: z.string(),
  addressline1: z.string().min(3, { message: 'Atleast 3 characters' }),
  addressline2: z.string().min(3, { message: 'Atleast 3 characters' }),
  landmark: z.string(),
  locality: z.string(),
  pincode: z.string(),
  district: z.string(),
  state: z.string(),
  pincode: z.string()
  .regex(new RegExp("[0-9]*"), "Invalid phone number")
  .length(6, { message: 'six digits required' }),
});

export default function Addressaddform(props) {
  const { open,onClose } = props;

  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState();

  const [addresstype, setAddresstype] = useState('home');
  //const [isprimary, setIsprimary] = useState('no');
  const [isfirstaddress, setIsfirstaddress] = useState('yes');

const { control, formState: { isSubmitSuccessful,errors }, reset, handleSubmit, register,setValue, getValues} = useForm({
  resolver: zodResolver(schema),
  defaultValues: {
    addresstitle: "",
    addresstype:"home",
    //isprimary:'no',
    addressline1: "",
    addressline2: "",
    landmark: "",
    locality: "",
    pincode: "",
    district: "",
    state: ""
  },
});

useEffect(() => {
  
  onSnapshot(
    collection(db, "Customers",props.customerid,"addresses"),
    snap => {
      setIsfirstaddress(snap.size==0 ? 'yes' : 'no')
   }
)

  if (isSubmitSuccessful) {
    reset();
    setDistrict('');
          setState('');
          setPincode('');
          setAddresstype('home');
          //setIsprimary('no');
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isSubmitSuccessful]);

 //const onSubmit = data => console.log(data);
const onSubmit = async data => {
if(district!='' && state!=''){
  const newId = doc(collection(db, 'addresses')).id;
  const docRef = query(doc(db, "Customers",props.customerid,"addresses",newId));
  setDoc(docRef, {
    KEYID: newId,
    addresstitle: data.addresstitle,
    addresstype: addresstype,
    //isprimary: isprimary,
    isprimary: isfirstaddress=='yes' ? 'yes' : 'no',
    addressline1: data.addressline1,
    addressline2: data.addressline2,
    landmark: data.landmark,
    locality: data.locality,
    pincode: data.pincode,
    district: district,
    state: state,
    createdOn:Timestamp.now()
  },{merge:true}).then(()=>{
    console.log("submitted");
    setDistrict('');
          setState('');
          setPincode('');
          setAddresstype('home');
          //setIsprimary('no');
  }).then(()=>{
    props.parentCallback(false)
  })
}
else{
  console.log("pincode not found");
}
};


function refreshpincode(e){
  console.log(e.target.value)
  if(e.target.value.length==6){
    axios.get('https://api.postalpincode.in/pincode/'+e.target.value)
    .then((res) => {
      // handle success
      if(res.data[0].Status=="Success"){
          setDistrict(res.data[0].PostOffice[0].District);
          setState(res.data[0].PostOffice[0].State);
          setPincode(e.target.value);
        }
        else{
          console.log("Invalid Pincode");
          setDistrict('');
          setState('');
        }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      setDistrict('');
      setState('');
      setPincode('');
    })
    .then(function () {
      // always executed
    });
  }
  else{
      setDistrict('');
      setState('');
      setPincode('');
  }
}


  return (
    <Dialog open={open} PaperProps={{
      style: { borderRadius: 10 } }}>
      <DialogTitle sx={{px:2,py:1, backgroundColor:"blue", color:"white"}}>
        <div className='flex flex-row justify-between items-center'>
        <div>Add New Address</div>
        <div> 
      {/* <Button type="button" onClick={onClose} color="white">
        Close
      </Button> */}
      <IconButton onClick={onClose} aria-label="close"  style={{ color: 'white' }}>
  <CloseIcon />
</IconButton>
      </div>
      </div>
        </DialogTitle>

      <div className='p-3'>

           <form onSubmit={handleSubmit(onSubmit)}>

           <div className="pt-1 flex flex-row justify-between items-center flex-wrap">
       {<Controller
          name="addresstype" {...register("addresstype")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='business'){
        setAddresstype('business')
          }
       else{ 
        setAddresstype('home')}
        }
        }
    >
      <ToggleButton selected={addresstype=='home' ? true : false} value="home">Home</ToggleButton>
      <ToggleButton selected={addresstype=='business' ? true : false} value="business">Business</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}

{/* <div>
  Is this Primary Address?&nbsp;
{<Controller
          name="isprimary" {...register("isprimary")}
          control={control} 
          ref={null}
          render={({ field }) => (
<ToggleButtonGroup  {...field}
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); if(e.target.value=='no'){
        setIsprimary('no')
          }
       else{ 
        setIsprimary('yes')}
        }
        }
    >
      <ToggleButton selected={isprimary=='yes' ? true : false} value="yes">Yes</ToggleButton>
      <ToggleButton selected={isprimary=='no' ? true : false} value="no">No</ToggleButton>
    </ToggleButtonGroup>
          )}
          />}
          </div> */}
</div>

<div className="pt-4 flex flex-row justify-between flex-wrap">
        <Controller
        name="addresstitle" {...register("addresstitle")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[100%]" autoComplete="off"
        error={!!errors.addresstitle}
        helperText={errors.addresstitle ? errors.addresstitle.message : ''}
       label="Address Title" variant="outlined" {...field} />}
      />
</div>

<div className="pt-4 flex flex-row justify-between flex-wrap">
        <Controller
        name="addressline1" {...register("addressline1")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[49%]" autoComplete="off"
        error={!!errors.addressline1}
        helperText={errors.addressline1 ? errors.addressline1.message : ''}
        label="Address Line 1" variant="outlined" {...field} />}
      />
        <Controller
        name="addressline2" {...register("addressline2")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[49%]"  autoComplete="off"
        error={!!errors.addressline2}
        helperText={errors.addressline2 ? errors.addressline2.message : ''}
        label="Address Line 2" variant="outlined" {...field} />}
      />
</div>

<div className="pt-4 flex flex-row justify-between flex-wrap">
<Controller
        name="landmark" {...register("landmark")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[49%]" autoComplete="off" label="Landmark" variant="outlined" {...field} />}
      />

<Controller
        name="locality" {...register("locality")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[49%]" autoComplete="off" label="Locality" variant="outlined" {...field} />}
      />
</div>

<div className="pt-4 flex flex-row justify-between flex-wrap">
<Controller
        name="pincode" {...register("pincode")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" className="w-[49%]" value={field.value} onChange={(e) => {field.onChange(e);refreshpincode(e)}} autoComplete="off"
        error={!!errors.pincode}
        helperText={errors.pincode ? errors.pincode.message : ''}
        label="Pincode" variant="outlined" inputProps={{ maxLength: 6}} />}
      />
      <div className="w-[49%]">
      <div>{district}</div>
      <div>{state}</div>
</div>
      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
      <Button type="submit" variant="contained" color="primary" disabled={district=='' ? true : false}>
        Submit
      </Button>
</div>

    </form>

    </div>
    </Dialog>
  );
}
