import Billdialog from './Billdialog';
import Billscollection from './Billscollection';
import Billsearch from './Billsearch';
import Billslist from './Billslist';
import Billstableview from './Billstableview';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React from 'react';

function Bills() {
    return (
        <>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
                <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
                    {/* <BillformatA5print /> */}
                    {/* <Billstableview /> */}
                    <Billscollection />
                </div>
            </Scrollbars>
        </>
    );
}

export default Bills;
