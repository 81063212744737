import { React, useState, useEffect, useCallback, forwardRef } from "react";
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import defaultavatarmale from "../assets/images/maleicon.png"
import defaultavatarfemale from "../assets/images/femaleicon.png"
import defaultavatarbusiness from "../assets/images/businessicon.png"
import Dldatepick from "../components/Dldatepick";
import ImageConverter from "../components/ConvertImageToBase64";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useForm, Controller } from "react-hook-form";

import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc,limit, getDoc, addDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import moment from "moment";
import { format } from 'date-fns'
import Billdialog from "../bills/Billdialog";

const schema = z.object({
    billnumber: z.string(),
    billnumbero: z.string(),
  });

export default function Billfind(props) {
  const { open,onClose } = props;

  const [bdate, setBdate] = useState(new Date());
  const [showbill, setShowbill] = useState(false);

  const [billnumber, setBillnumber] = useState('');
  const [billnumbero, setBillnumbero] = useState('');

  const handleChange = (newValue) => {
    setBdate(newValue);
  };

const { control, formState: { isSubmitSuccessful,errors }, reset, handleSubmit, register,setValue, getValues} = useForm({
  resolver: zodResolver(schema),
  defaultValues: {
    billnumber: "",
    billnumbero: "",
  },
});

useEffect(() => {

  if (isSubmitSuccessful) {
    reset();
    setBdate(new Date());
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isSubmitSuccessful]);

const callback2 = useCallback((c) => {
    setShowbill(c);
});

 //const onSubmit = data => console.log(data);
const onSubmit = async data => {
    if (data.billnumber !== '' && data.billnumbero !== '') {
        setBillnumbero(data.billnumbero)
        setBillnumber(0)
        setShowbill(true);
    }

    if (data.billnumber == '' && data.billnumbero !== '') {
        setBillnumbero(data.billnumbero)
        setBillnumber(0)
        setShowbill(true);
    }
    if (data.billnumbero == '' && data.billnumber !== '') {
        setBillnumbero(0)
        setBillnumber(data.billnumber)
        setShowbill(true);
    }
    

      console.log("submitted");


    
};
console.log(errors);

  return (
<>
      <div className='p-3'>

           <form onSubmit={handleSubmit(onSubmit)}>


           <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[41.35%] pt-[55px]">  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
            <Controller
        name="billnumbero" {...register("billnumbero")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" autoComplete="off"
           label="Bill Number" variant="outlined" {...field} />}
      />
</div>
      </div>


<div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[41.35%]">
    <LocalizationProvider dateAdapter={AdapterDateFns}>
   <MobileDatePicker
          label="Billed On"
          inputFormat="dd-MM-yyyy"
          value={bdate}
          onChange={handleChange}
          maxDate={new Date()}
          disableFuture={true}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        </LocalizationProvider>
</div>

      </div>
      

      <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[41.35%]">
            <Controller
        name="billnumber" {...register("billnumber")}
        control={control}
        ref={null}
        render={({ field }) => <TextField size="small" autoComplete="off"
           label="Bill Id" variant="outlined" {...field} />}
      />
</div>
      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
</div>

    </form>

    {/* <div className="flex flex-row justify-start">
    <div>
<Addressadd />
</div>
<div className="m-1  w-[100%] border-dashed border-2 border-indigo-600">
<div className="h-[100%] w-[100%] flex justify-center items-center">
    No Address
</div>
</div>
</div> */}
    </div>
    {showbill==true && <Billdialog  date={format(new Date((moment(bdate, 'DD-MM-YYYY').toISOString())), 'dd-MM-yyyy')} billnumber={Number(billnumber).toString()} billnumbero={Number(billnumbero)} parentCallback2={callback2} />}

    </>
  );
}
