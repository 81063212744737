import { useEffect, useState } from "react";

import { collection, onSnapshot, doc, setDoc, getDocs,increment, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import CheckIcon from '@mui/icons-material/Check';


function Finaldiscount(props) {
  const [odiscountinput, setOdiscountinput] = useState(0);
  const [radioval, setRadioval] = useState('incost');
  const [tafterodiscountval, setTafterodiscountval] = useState(0);


  async function pullDiscountFromFirebase() {
    const docRef = doc(db, "SHOP1LIVECART","CART1");
    onSnapshot(docRef, (doc) => {
      if(doc.exists && doc.data().overalldiscounttype!==undefined){
        setRadioval(doc.data().overalldiscounttype);
        }
        if(doc.exists && doc.data().overalldiscount!==undefined){
          setOdiscountinput(doc.data().overalldiscount);
          }
})
  }
  

  useEffect(() => {
    pullDiscountFromFirebase();
  }, [])

  const handleOnChangeinput = (e) => {

    if(Number(e.target.value)>=0 && Number(e.target.value)<=props.billcostsub){

     setOdiscountinput(Number(e.target.value));

    let _manualdicinput = Number(e.target.value) || 0;

    if (radioval === 'incost') {

      setTafterodiscountval(props.billcostdata - _manualdicinput)
    }

    else {
      setTafterodiscountval(props.billcostdata - (_manualdicinput / 100))
    }

    const docRef = doc(db, "SHOP1LIVECART", "CART1");
    setDoc(docRef, {
      overalldiscounttype: radioval,
      overalldiscount: _manualdicinput,
      tafteroveralldiscount: (radioval === 'incost') ? (props.billcostdata - _manualdicinput) : (props.billcostdata - (_manualdicinput / 100)),
      isoveralldiscount:false
    }, { merge: true });
  }
else{
  setOdiscountinput(0);
}
  }

  const handleChangeradio = (e) => {
    setOdiscountinput(0)
    setTafterodiscountval(props.billcostdata)
    setRadioval(e.target.value);

    let _radioval = e.target.value;
    const docRef = doc(db, "SHOP1LIVECART", "CART1");
    setDoc(docRef, {
      overalldiscounttype: _radioval,
      overalldiscount: 0,
      tafteroveralldiscount: props.billcostdata,
    }, { merge: true });
  }

const handleFocus = (event) => event.target.select();


async function handleOnClickEditdiscokbn(e) {

const querySnapshot=await getDocs(collection(db, "SHOP1LIVECART", "CART1", "PRODUCTS"));
querySnapshot.forEach(async(docz) => {

  //if 50 rupees discount find 50 is what percent of subtotal
   let discval= (radioval === 'incost') ? ((((docz.data().rate*docz.data().qnty)*((props.overalldiscount*100)/props.billcostsub))/100)) : (props.overalldiscount);

   if((radioval !== 'incost' && Number(props.overalldiscount)>0 && Number(props.overalldiscount)<=100) || radioval === 'incost'){
          
   const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS",docz.id);
   setDoc(docRef, {
    ismanualdiscount: true,
    // discount:(radioval==='incost') ? discval : (docz.data().rate*docz.data().qnty)*(discval/100),
    // discounttype: 'incost',
    discount: discval,
    discounttype: radioval,
    // amount:(docz.data().isgstincl) ? (docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval*docz.data().qnty : (docz.data().rate*docz.data().qnty)*(discval/100)) : ((docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval*docz.data().qnty : (docz.data().rate*docz.data().qnty)*(discval/100)))+(((docz.data().rate*docz.data().qnty))*(docz.data().gst/100)),
    // taxamount : (docz.data().isgstincl) ? (docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval*docz.data().qnty : (docz.data().rate*docz.data().qnty)*(discval/100))-(((docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval*docz.data().qnty : (docz.data().rate*docz.data().qnty)*(discval/100)))*(100/(100+docz.data().gst))) : ((docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval*docz.data().qnty : (docz.data().rate*docz.data().qnty)*(discval/100)))*(docz.data().gst/100),
   
    amount:(docz.data().isgstincl) ? (docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval : (docz.data().rate*docz.data().qnty)*(discval/100)) : ((docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval : (docz.data().rate*docz.data().qnty)*(discval/100)))+(((docz.data().rate*docz.data().qnty))*(docz.data().gst/100)),
    taxamount : (docz.data().isgstincl) ? (docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval : (docz.data().rate*docz.data().qnty)*(discval/100))-(((docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval : (docz.data().rate*docz.data().qnty)*(discval/100)))*(100/(100+docz.data().gst))) : ((docz.data().rate*docz.data().qnty)-((radioval === 'incost') ? discval : (docz.data().rate*docz.data().qnty)*(discval/100)))*(docz.data().gst/100),
   
    modified:Timestamp.now()
   }, { merge: true }).then( async() => {
     const docRefz = doc(db, "SHOP1LIVECART","CART1");
     setDoc(docRefz, {
       //isoveralldiscount:true,
       isoveralldiscount: odiscountinput!==0 ? true : false,
     },{merge:true}
     )
    });
  }
else{
}
})
};


  return (
    <>
    {props.billcostdata!==0 && <div className="flex flex-row items-end justify-center space-x-2 w-[550px]">
    <div>
          <label htmlFor="exampleNumber0" className="form-label inline-block mb-2 text-gray-700"
          >Discount</label
          >
          <input
            type="number"
            value={odiscountinput || ''} onFocus={handleFocus}
            onChange={handleOnChangeinput}
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            id="exampleNumber0"
            placeholder="Amount"
          />
          </div>
          <div>
                <button type="button" onClick={(e) => handleOnClickEditdiscokbn(e)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <CheckIcon />
        {/* OK */}
      </button>
      </div>

        <div>
          <div className="form-check">
            <input value="incost" checked={radioval === 'incost'} onChange={handleChangeradio} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-white checked:border-4 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
            <label className="form-check-label inline-block text-gray-800 cursor-pointer" htmlFor="flexRadioDefault1">
              In Cost
            </label>
          </div>
          <div className="form-check">
            <input value="inpercent" checked={radioval === 'inpercent'} onChange={handleChangeradio} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-white checked:border-4 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
            <label className="form-check-label inline-block text-gray-800 cursor-pointer" htmlFor="flexRadioDefault2">
              In Percent
            </label>
          </div>
        </div>
    </div>}
    </>
  );

}
export default Finaldiscount;