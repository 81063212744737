import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useEffect, useState } from "react";
import { collection, query, onSnapshot,doc, getDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";

function Autocomplete(props) {
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [cartdata, setCartdata] = useState([]);

    async function pullDataFromFirebase() {
        const colRef = collection(db, "Products");
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(data)
      }

      async function pullcartDataFromFirebase() {
        const colRef = collection(db, "SHOP1LIVECART","CART1","PRODUCTS");
        onSnapshot(colRef, (snapshot) => {
          //setCartdatalength(snapshot.docs.length)
          setCartdata(snapshot.docs.map(doc => (doc.data())))

  })
      }


    useEffect(() => {
        pullDataFromFirebase();
        pullcartDataFromFirebase();
    }, [])
  // note: the id field is mandatory
  const items = data

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
    setSearchString(string);
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect  = async(item)  => {
    // the item selected
    console.log(item)
    clearSearchBox("")
    



    const prodRef = doc(db, "SHOP1LIVECART","CART1","PRODUCTS",item.KEYID);
    const docSnap= await getDoc(prodRef)
    if(!docSnap.exists()){
      console.log("if");
      let discval=(props.passdata==='REGULAR') ? (item.discountregular) : 
      (props.passdata==='PRIME') ? (item.discountprime) :
      (props.passdata==='INSTITUTE') ? (item.discountinstitute) :
      (props.passdata==='WHOLESALE') ? (item.discountwholesale) :0;
      if(!discval){discval=0} //undefined to 0

      console.log(item.rate)
      console.log(item.rate-discval)
      console.log(((item.rate-discval)*(100/(100+item.gst))))
      console.log(item.rate-discval-((item.rate-discval)*(100/(100+item.gst))))

    setDoc(prodRef, {      
      KEYID:item.KEYID,
       name: item.name,
       qnty:1,
       isgstincl:true,
       discounttype:'incost',
       rate:(item.isgstincl) ? item.rate : item.rate+(item.rate*(item.gst/100)),
       discount:discval,
       custtypediscount:discval,
       ismanualdiscount:false,
       amount:(item.isgstincl) ? item.rate-discval : (item.rate-discval)+((item.rate)*(item.gst/100)),
       taxamount : (item.isgstincl) ? item.rate-discval-((item.rate-discval)*(100/(100+item.gst))) : (item.rate-discval)*(item.gst/100),
       gst: item.gst,
       brand:item.brand,
       category: item.category,
       color:item.color,
       size:item.size,
       addedon:Timestamp.now()
      },{merge:true}).then( async() => {
    pullcartDataFromFirebase();
   });
  }

  else{
    console.log("else");

    let discval=(props.passdata==='REGULAR') ? (item.discountregular) : 
    (props.passdata==='PRIME') ? (item.discountprime) :
    (props.passdata==='INSTITUTE') ? (item.discountinstitute) :
    (props.passdata==='WHOLESALE') ? (item.discountwholesale) :0;
    if(!discval){discval=0} //undefined to 0

  //   console.log(discval);
  //   setDoc(prodRef, {      
  //     KEYID:item.KEYID,
  //      name: item.name,
  //      qnty:increment(1),
  //      isgstincl:true,   
  //      discounttype:'incost',    
  //      rate:(item.isgstincl) ? item.rate : item.rate+(item.rate*(item.gst/100)),
  //      discount:increment(discval),
  //      amount:increment((item.isgstincl) ? item.rate-discval : (item.rate-discval)+((item.rate)*(item.gst/100))),
  //      taxamount : increment((item.isgstincl) ? item.rate-discval-((item.rate-discval)*(100/(100+item.gst))) : (item.rate-discval)*(item.gst/100)),
  //      modifiedon:Timestamp.now()
  //  },{merge:true}).then( async() => {
  //   pullcartDataFromFirebase();
  //  });

    console.log(discval);
    setDoc(prodRef, {      
            discount:(docSnap.data().discounttype === 'incost') ? increment(docSnap.data().discount / docSnap.data().qnty) : docSnap.data().discount,
            amount: (docSnap.data().isgstincl) ? (docSnap.data().rate * docSnap.data().qnty) - ((docSnap.data().discounttype === 'incost') ? (docSnap.data().discount+(docSnap.data().discount / docSnap.data().qnty)) : (docSnap.data().rate * docSnap.data().qnty) * (docSnap.data().discount / 100)) : ((docSnap.data().rate * docSnap.data().qnty) - ((docSnap.data().discounttype === 'incost') ? (docSnap.data().discount+(docSnap.data().discount / docSnap.data().qnty)) : (docSnap.data().rate * docSnap.data().qnty) * (docSnap.data().discount / 100))) + (((docSnap.data().rate * docSnap.data().qnty)) * (docSnap.data().gst / 100)),
            taxamount: (docSnap.data().isgstincl) ? (docSnap.data().rate * docSnap.data().qnty) - ((docSnap.data().discounttype === 'incost') ? (docSnap.data().discount+(docSnap.data().discount / docSnap.data().qnty)) : (docSnap.data().rate * docSnap.data().qnty) * (docSnap.data().discount / 100)) - (((docSnap.data().rate * docSnap.data().qnty) - ((docSnap.data().discounttype === 'incost') ? (docSnap.data().discount+(docSnap.data().discount / docSnap.data().qnty)) : (docSnap.data().rate * docSnap.data().qnty) * (docSnap.data().discount / 100))) * (100 / (100 + docSnap.data().gst))) : ((docSnap.data().rate * docSnap.data().qnty) - ((docSnap.data().discounttype === 'incost') ? (docSnap.data().discount+(docSnap.data().discount / docSnap.data().qnty)) : (docSnap.data().rate * docSnap.data().qnty) * (docSnap.data().discount / 100))) * (docSnap.data().gst / 100),
      KEYID:item.KEYID,
       name: item.name,
       qnty:increment(1),
       //isgstincl:true,   
       //discounttype:'incost',    
       //rate:(item.isgstincl) ? item.rate : item.rate+(item.rate*(item.gst/100)),
      //  discount:increment(discval),
      //  amount:increment((item.isgstincl) ? item.rate-discval : (item.rate-discval)+((item.rate)*(item.gst/100))),
      //  taxamount : increment((item.isgstincl) ? item.rate-discval-((item.rate-discval)*(100/(100+item.gst))) : (item.rate-discval)*(item.gst/100)),
       modifiedon:Timestamp.now()
   },{merge:true}).then( async() => {
    pullcartDataFromFirebase();
   });

  }
  

  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.KEYID}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
      </>
    )
  }

  const clearSearchBox = () => {
    setSearchString("");
  };

  return (
      <>
    <div>
      <header className="App-header">
        <div style={{ width: 400 }}>
          <ReactSearchAutocomplete fuseOptions={{ keys: ["name"] }}
            items={items}
            resultStringKeyName={"name"}
            inputSearchString={searchString}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{zIndex:"1000"}}
          />
        </div>
      </header>
    </div>
    <div>
    </div>


    </>
  )
}

export default Autocomplete