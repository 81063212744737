import { React, useState, useEffect, useCallback } from "react";
import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,orderBy,} from "firebase/firestore";
import {db} from "../firebase-config";
import PaginationComp from '../components/PaginationComp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Supplierdelete from "./Supplierdelete";
import Supplieredit from "./Supplieredit";
import { Link } from 'react-router-dom'

import { capitalize } from '@material-ui/core';
import { upperCase,titleCase } from "text-case";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Settings from "../settings/Settings";
import Supplieradd2 from "./Supplieradd2";

function Supplierslist() {



    const [data, setData] = useState([]);

    async function pullDataFromFirebase() {
        const colRef = query(collection(db, "Suppliers"),orderBy('name'));
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(JSON.stringify(data))
      }
      useEffect(() => {
          pullDataFromFirebase();    
      },[])

      const [currentPage, setCurrentPage] = useState(1)
      const [search, setSearch] = useState('')

      const [supplier, setSupplier] = useState('all');

      const handleSupplier = (value) => {
        setSupplier(value)
        setCurrentPage(1)
      }
  
      const [suppliertype, setSuppliertype] = useState('all');

      const handleSuppliertype = (value) => {
        setSuppliertype(value)
        setCurrentPage(1)
      }

    const SearchedSupplierlist = search ? data.filter(item => ((item.name.toLowerCase().includes(search.toLowerCase())) || ((item.suppliertype.toLowerCase()===search.toLowerCase())))) : data
    const filteredSupplierlist0 = suppliertype !== 'all' ? SearchedSupplierlist.filter(item => item.suppliertype === suppliertype) : SearchedSupplierlist
    const filteredSupplierlist = supplier !== 'all' ? filteredSupplierlist0.filter(item => item.supplier === supplier) : filteredSupplierlist0
    const pageSize = 15
    const totalItems = filteredSupplierlist.length
    const totalPages = Math.ceil((totalItems / pageSize))
    const startIndex = (currentPage - 1) * pageSize
    let pageNumbers = []
    for (let i = 1; i <= totalPages; i++)
    {
        pageNumbers.push(i)
    }
    const paginatedSupplierlist = filteredSupplierlist.slice(startIndex, startIndex+pageSize)

    const callback = useCallback((c) => {
        console.log(c)
        setCurrentPage(c)
    })
 
    let delay_index = -1;

    return (
      <>
      <Supplieradd2 />
      <div className="py-1 px-4 text-4xl">Supplier Search</div>

<div className="px-4 py-2 flex justify-between space-x-4 flex-wrap">
<div className="flex space-x-2 items-center">
<div className="text-lg">Supplier Name:</div>
<div>
              <TextField size="small" autoComplete="off"  onChange={(e) => setSearch(e.target.value)}
         label="Search Supplier" variant="outlined" />
</div>
</div>
<div className="flex space-x-4 flex-wrap"> 
<div className="flex space-x-2 items-center">
<div className="text-lg">Supplier Type:</div>
              <ToggleButtonGroup
    size="small"
    color="primary"
    exclusive
    onChange={(e)=>{console.log(e.target.value); 
      if(e.target.value==='all'){
          handleSupplier('all');
            }
      else if(e.target.value==='company'){
          handleSupplier('company');
        }
     else{ 
      handleSupplier('individual');
     }
      }
      }
  >
            <ToggleButton selected={supplier==='all' ? true : false} value="all">All</ToggleButton>
    <ToggleButton selected={supplier==='individual' ? true : false} value="individual">Individual</ToggleButton>
    <ToggleButton selected={supplier==='company' ? true : false} value="company">Company</ToggleButton>
  </ToggleButtonGroup>
  </div>

  <div className="flex space-x-2 items-center">
<div className="text-lg">Supplier Category:</div>
  <ToggleButtonGroup
    size="small"
    color="primary"
    exclusive
    onChange={(e)=>{console.log(e.target.value); 
      if(e.target.value==='all'){
          handleSuppliertype('all');
            }
      else if(e.target.value==='regular'){
          handleSuppliertype('regular');
        }
        else if(e.target.value==='prime'){
          handleSuppliertype('prime');
        }
        else if(e.target.value==='institute'){
          handleSuppliertype('institute');
        }
     else{ 
      handleSuppliertype('wholesale');
     }
      }
      }
  >
            <ToggleButton selected={suppliertype==='all' ? true : false} value="all">All</ToggleButton>
    <ToggleButton selected={suppliertype==='regular' ? true : false} value="regular">Regular</ToggleButton>
    <ToggleButton selected={suppliertype==='prime' ? true : false} value="prime">Prime</ToggleButton>
    <ToggleButton selected={suppliertype==='institute' ? true : false} value="institute">Institute</ToggleButton>
    <ToggleButton selected={suppliertype==='wholesale' ? true : false} value="wholesale">Wholesale</ToggleButton>
  </ToggleButtonGroup>
  </div>
  </div>
  </div>

<div className="flex flex-wrap justify-center">

  {/* <div>{data.length}</div>
  <div>{data.filter(el => el.supplier==="individual").length}</div>
  <div>{data.filter(el => el.supplier==="company").length}</div>
  <div>{data.filter(el => el.suppliertype==="regular").length}</div>
  <div>{data.filter(el => el.suppliertype==="prime").length}</div>
  <div>{data.filter(el => el.suppliertype==="institute").length}</div>
  <div>{data.filter(el => el.suppliertype==="wholesale").length}</div>
  <div>{paginatedSupplierlist.length}</div>
  <div>{paginatedSupplierlist.filter(el => el.supplier==="individual").length}</div>
  <div>{paginatedSupplierlist.filter(el => el.supplier==="company").length}</div>
  <div>{paginatedSupplierlist.filter(el => el.suppliertype==="regular").length}</div>
  <div>{paginatedSupplierlist.filter(el => el.suppliertype==="prime").length}</div>
  <div>{paginatedSupplierlist.filter(el => el.suppliertype==="institute").length}</div>
  <div>{paginatedSupplierlist.filter(el => el.suppliertype==="wholesale").length}</div> */}

                          {paginatedSupplierlist.map((el, index) => {
                                           delay_index += (index!==0) ? 10 : 0;
                                           const delay = Math.max(0, delay_index*100);
                                          return(
                                              <Grow key={el.KEYID}
                                        in={true}
                                        style={{ transformOrigin: '0 0 0' }}
                                        {...({ timeout: 1000+delay })}
                                      >
                                       <Card key={el.KEYID} className="w-[370px] m-1 border border-indigo-500" >
                                    <CardContent sx={{ p:1, '&:last-child': { pb: 1 }}}>
                                        <div className="flex flex-row justify-between items-center h-[35px]">
                                            <div className="flex flex-row items-center">
                                            <div>{el.supplier==="individual" ? <HomeIcon color="action"/> : <BusinessIcon color="action"/>}</div>
                                             <div className="pt-1 pl-2 font-bold text-sm " >{upperCase(el.name)}</div> 
                                              </div>
                                            <div>
                              {/* <Supplieredit index={index} passdata={el.KEYID} /> */}
                              {/* <IconButton sx={{ p:1}}  component={Link} to= {'/supplierprofile/'+el.KEYID }
color="secondary" aria-label="Edit" size="small">
<EditIcon fontSize="small" />
</IconButton>  */}
                              {/* <Supplierprofile index={index} passdata={el.KEYID} /> */}
                              <Supplierdelete index={index} passdata={el.KEYID} />
                              </div>
                                        </div>
                                        <hr className="my-1"/>
                                        <div className="flex flex-row justify-between">                                         
                                    <div>{capitalize(el.title)+'. '+titleCase(el.name)}</div>
                                    <div>{el.supplier==="individual" && capitalize(el.gender)}</div>
                                    </div>
                                    {el.supplier==="individual" ? el.dob2 : upperCase(el.gstnumber)}<br/>
                                    <div className="flex flex-row justify-between">
                                    <div>{capitalize(el.supplier)}</div>
                                    <div>{capitalize(el.suppliertype)}</div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                    <div>{el.mobilenumber}</div>
                                    <div>{el.email}</div>
                                    </div>
                                    </CardContent>
                                    <CardActions>
                                      <div className="flex justify-between w-[100%]">
                                    <Supplieredit index={index} passdata={el.KEYID} />
                                      <Button variant="outlined"  component={Link} to= {'/supplierprofile/'+el.KEYID }  startIcon={<VisibilityIcon />}>
  <div fontSize="15px">&nbsp;VIEW PROFILE</div>
</Button>
</div>
                                    </CardActions>
                                  </Card>
                                      </Grow>
                              
                                          )
                          })}
                      </div>

          <PaginationComp passeddata={pageNumbers.length} parentCallback={callback}/>
          </>
    );
}

export default Supplierslist