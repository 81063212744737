import { useEffect, useState, useId } from "react";
import { collection, query, onSnapshot, doc, getDoc, getDocs, setDoc, deleteDoc, orderBy, increment, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import SimpleToast from '../components/Toast/SimpleToast';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

function Cartdiscount({ propz }, index) {

    const [editdiscinput, setEditdiscinput] = useState(0);
    const [editdiscinputV, setEditdiscinputV] = useState(true);
    const [editdiscinputVIndexClicked, setEditdiscinputVIndexClicked] = useState();

    const [inddiscval, setInddiscval] = useState(0);
    const [radioval, setRadioval] = useState("");

    const handleChangeradio = async (e, kid, rate) => {
        setRadioval(e.target.value);
        setInddiscval(Number(editdiscinput) || 0);
    }

    const handleOnChangeinput = (e, rate, kid) => {
        setEditdiscinput(e.target.value)
        setInddiscval(Number(e.target.value) || 0);
    }

    const handleOnClickEditdiscokbn = async (KEYID, q, d, amnt, tamnt, gst, rate, isgstincl, e) => {

        setInddiscval(Number(editdiscinput) || 0);
        
        if(Number(editdiscinput)<=purrate*q && Number(editdiscinput)>=0){
if((radioval !== 'incost' && Number(editdiscinput)>0 && Number(editdiscinput)<=100) || radioval === 'incost'){
        //isgstincl always true
        const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", KEYID);

        setDoc(docRef, {
            discounttype: radioval,
            discount: Number(editdiscinput),
            ismanualdiscount: true,
            amount: (isgstincl) ? (rate * q) - ((radioval === 'incost') ? inddiscval : (rate * q) * (inddiscval / 100)) : ((rate * q) - ((radioval === 'incost') ? inddiscval : (rate * q) * (inddiscval / 100))) + (((rate * q)) * (gst / 100)),
            taxamount: (isgstincl) ? (rate * q) - ((radioval === 'incost') ? inddiscval : (rate * q) * (inddiscval / 100)) - (((rate * q) - ((radioval === 'incost') ? inddiscval : (rate * q) * (inddiscval / 100))) * (100 / (100 + gst))) : ((rate * q) - ((radioval === 'incost') ? inddiscval : (rate * q) * (inddiscval / 100))) * (gst / 100),
        }, { merge: true }).then(()=>{
            const docRef2 = doc(db, "SHOP1LIVECART", "CART1");
        setDoc(docRef2, {
            isoveralldiscount: true,
        }, { merge: true })
        })
            .then(() => {
                setEditdiscinputV(true);

            }) 
        }
            else{
                setEditdiscinputV(true);
            }
           }
            else{
                setEditdiscinputV(true);
            }
    }

    const handleOnClickEditdiscbtn = async (q, i, kid, rate) => {
        setEditdiscinputV(false);
        setEditdiscinput(q);
        setEditdiscinputVIndexClicked(i);

        const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", kid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setEditdiscinput(docSnap.data().discount);
            setRadioval(docSnap.data().discounttype);
        } else {
            console.log("No such document!");
        }
    }

    const handleOnClickEditrestorediscbtn = async (q, i, kid, rate,amnt,d) => {
        const docRef2 = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", kid);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
                const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", kid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const docRef1 = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", kid);
                    setDoc(docRef1, {
                        ismanualdiscount: false,
                        discounttype: 'incost',
                        amount: (docSnap.data().rate - docSnap.data().custtypediscount) * docSnap.data().qnty,
                        discount: docSnap.data().qnty * docSnap.data().custtypediscount,
                        taxamount: ((docSnap.data().rate - docSnap.data().custtypediscount) * docSnap.data().qnty) - ((((docSnap.data().rate - docSnap.data().custtypediscount) * docSnap.data().qnty)) * (100 / (100 + docSnap.data().gst))),
                        modified: Timestamp.now()
                    }, { merge: true }).then(()=>{
                        const docRef2 = doc(db, "SHOP1LIVECART", "CART1");
                    setDoc(docRef2, {
                        isoveralldiscount: false,
                    }, { merge: true })
                    })
                }
            
        }

    }

    const [purrate, setPurrate] = useState();
    async function checkdiscrate(kid) {  
        const docRef = doc(db,"Products",kid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { 
            setPurrate(docSnap.data().purchaserate)
      }
    }
      useEffect(() => {
        checkdiscrate(propz.KEYID);
     }, [])

     const id=useId();


     
    
    const [isdiscounteditTrue, setisdiscounteditTrue] = useState(false);
    const [isdiscountrestoreTrue, setisdiscountrestoreTrue] = useState(false);
    async function pullDataFromFirebase2() {
      const docRef = doc(db, "Settings", "cartcolumncntrls");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          setTimeout(() => {
              setisdiscounteditTrue(docSnap.data().ssdiscountedit)
              setisdiscountrestoreTrue(docSnap.data().ssdiscountrestore)
          }, 2000)

      } else {
          console.log("No such document!")
      }    
    }
    
    useEffect(() => {
        pullDataFromFirebase2();
    }, [])

    return (
        <>
            <div className="disccol" key={id}>
                <div style={index === editdiscinputVIndexClicked ? (editdiscinputV ? {} : { display: 'none' }) : {}}  className="disccoldisc">{((propz.discounttype === 'incost') ? propz.discount : (propz.discount / 100) * (propz.rate * propz.qnty))}</div>
                <input type="number" style={index === editdiscinputVIndexClicked ? (editdiscinputV ? { display: 'none' } : {}) : { display: 'none' }} value={editdiscinput} onChange={(e) => handleOnChangeinput(e, propz.rate, propz.KEYID)}
                    className="form-control block w-12 px-1.5 py-1.5 mx-0.5 text-base font-normal  text-center  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                ></input>
                <div className="flex justify-center">
                    <div style={index === editdiscinputVIndexClicked ? (editdiscinputV ? { display: 'none' } : {}) : { display: 'none' }} >
                        <div className="form-check">
                            <input value="incost" checked={radioval === 'incost'} onChange={(e) => handleChangeradio(e, propz.KEYID, propz.rate)} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-white checked:border-4 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name={"a" + index} id="flexRadioDefaultindidsc1" />
                            <label className="form-check-label inline-block text-gray-800 cursor-pointer" htmlFor="flexRadioDefaultindidsc1">
                                In ₹
                            </label>
                        </div>
                        <div className="form-check">
                            <input value="inpercent" checked={radioval === 'inpercent'} onChange={(e) => handleChangeradio(e, propz.KEYID, propz.rate)} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-white checked:border-4 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name={"b" + index} id="flexRadioDefaultindidsc2" />
                            <label className="form-check-label inline-block text-gray-800 cursor-pointer" htmlFor="flexRadioDefaultindidsc2">
                                In %
                            </label>
                        </div>
                    </div>
                </div>
                <div><span className="hidden sm:block">
                    {isdiscounteditTrue && <button id={index} type="button" style={index === editdiscinputVIndexClicked ? (editdiscinputV ? {} : { display: 'none' }) : {}} onClick={() => handleOnClickEditdiscbtn(propz.discount, index, propz.KEYID, propz.rate)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <EditIcon />
                        {/* Edit */}
                    </button>}
                    {isdiscountrestoreTrue && propz.ismanualdiscount && <button id={index} type="button" style={index === editdiscinputVIndexClicked ? (editdiscinputV ? {} : { display: 'none' }) : {}} onClick={() => handleOnClickEditrestorediscbtn(propz.qnty, index, propz.KEYID, propz.rate,propz.amount,propz.discount)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <SettingsBackupRestoreIcon />
                    </button>}
                    <button id={index} type="button" style={index === editdiscinputVIndexClicked ? (editdiscinputV ? { display: 'none' } : {}) : { display: 'none' }} onClick={(e) => handleOnClickEditdiscokbn(propz.KEYID, propz.qnty, propz.discount, propz.amount, propz.taxamount, propz.gst, propz.rate, propz.isgstincl, e)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <CheckIcon />
                        {/* OK */}
                    </button>
                    <button id={index} type="button" style={index === editdiscinputVIndexClicked ? (editdiscinputV ? { display: 'none' } : {}) : { display: 'none' }} onClick={() => setEditdiscinputV(true)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <ClearIcon />
                    </button>
                </span></div>
            </div>
        </>
    )
}
export default Cartdiscount