import { useEffect, useState, useId } from "react";
import { collection, query, onSnapshot, doc, getDoc, getDocs, setDoc, deleteDoc, orderBy, increment, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import SimpleToast from '../components/Toast/SimpleToast';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

function Cartqnty({ propz }, index) {

    const [editqntyinput, setEditqntyinput] = useState(0);
    const [editqntyinputV, setEditqntyinputV] = useState(true);
    const [editqntyinputVIndexClicked, setEditqntyinputVIndexClicked] = useState();

    const handleOnClickAdd = async(KEYID, q, d, amnt, tamnt, distype) => {
        const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", KEYID);
        setDoc(docRef, {
            qnty: increment(1),
            discount: distype=='incost' ? increment(d / q) : increment(0),
            amount: increment(amnt / q),
            taxamount: increment(tamnt / q),
        }, { merge: true })
    }

    const handleOnClickRemove = (KEYID, q, d, amnt, tamnt, distype) => {
        const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", KEYID);
        setDoc(docRef, {
            qnty: increment(-1),
            discount: distype=='incost' ? increment(-d / q) : increment(0),
            amount: increment(-amnt / q),
            taxamount: increment(-tamnt / q),
        }, { merge: true })
    }




    const handleOnClickEditqntyokbn = async (KEYID, q, d, amnt, tamnt) => {
        if(Number(editqntyinput)<=stckqnty && Number(editqntyinput)>0){
        const docRef = doc(db, "SHOP1LIVECART", "CART1", "PRODUCTS", KEYID);
        setDoc(docRef, {
            qnty: Number(editqntyinput),
            discount: editqntyinput * (d / q),
            amount: editqntyinput * (amnt / q),
            taxamount: editqntyinput * (tamnt / q),
        }, { merge: true })
            .then(() => {
                setEditqntyinputV(true);
            })
        }
        else{
            setEditqntyinputV(true);
        }
    }


    const handleOnClickEditbtn = (q, i) => {
        setEditqntyinputV(false);
        setEditqntyinput(q);
        setEditqntyinputVIndexClicked(i);
    }

    const [isDisabledIndexClicked, setisDisabledIndexClicked] = useState();
    const [stckqnty, setStckqnty] = useState();

    async function checkprdqnty(kid,i) {  
        setisDisabledIndexClicked(i)
        const docRef = doc(db,"Products",kid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { 
            setStckqnty(docSnap.data().qnty)
      }
    }
      useEffect(() => {
        checkprdqnty(propz.KEYID, index);
     }, [])

     const id=useId();

       
    const [isqntyeditTrue, setisqntyeditTrue] = useState(false);
    const [isqntypmTrue, setisqntypmTrue] = useState(false);
    async function pullDataFromFirebase2() {
      const docRef = doc(db, "Settings", "cartcolumncntrls");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          setTimeout(() => {
              setisqntyeditTrue(docSnap.data().ssqntyedit)
              setisqntypmTrue(docSnap.data().ssqntypm)
          }, 2000)

      } else {
          console.log("No such document!")
      }    
    }
    
    useEffect(() => {
        pullDataFromFirebase2();
    }, [])

    return (
        <>
        <div className="qntycol" key={id}>
        <div style={{display:'none'}}>{stckqnty}</div>
        {isqntypmTrue && <IconButton color="secondary" aria-label="add" disabled={index === isDisabledIndexClicked ? propz.qnty>=stckqnty : false} style={index === editqntyinputVIndexClicked ? (editqntyinputV ? {} : { display: 'none' }) : {}} onClick={() => handleOnClickAdd(propz.KEYID, propz.qnty, propz.discount, propz.amount, propz.taxamount, propz.discounttype)}>
                <AddIcon />
            </IconButton>}
            <div style={index === editqntyinputVIndexClicked ? (editqntyinputV ? {} : { display: 'none' }) : {}} className="qntycolqnty">{propz.qnty}</div>
            <input type="number" step={1} style={index === editqntyinputVIndexClicked ? (editqntyinputV ? { display: 'none' } : {}) : { display: 'none' }} value={editqntyinput} onChange={(e) => setEditqntyinput(e.target.value)} className="form-control block w-12 px-1.5 py-1.5 mx-0.5 text-base font-normal  text-center  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"></input>
            <div>
                <span className="hidden sm:block">
                {isqntyeditTrue && <button id={index} type="button" style={index === editqntyinputVIndexClicked ? (editqntyinputV ? {} : { display: 'none' }) : {}} onClick={() => handleOnClickEditbtn(propz.qnty, index)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <EditIcon />
                    </button>}
                    <button id={index} type="button" style={index === editqntyinputVIndexClicked ? (editqntyinputV ? { display: 'none' } : {}) : { display: 'none' }} onClick={() => handleOnClickEditqntyokbn(propz.KEYID, propz.qnty, propz.discount, propz.amount, propz.taxamount, propz.discounttype)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <CheckIcon />
                    </button>
                    <button id={index} type="button" style={index === editqntyinputVIndexClicked ? (editqntyinputV ? { display: 'none' } : {}) : { display: 'none' }} onClick={() => setEditqntyinputV(true)} className="inline-flex items-center px-4 py-2 mx-0.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <ClearIcon />
                    </button>
                </span>
            </div>
            {isqntypmTrue && <IconButton color="secondary" aria-label="remove" disabled={propz.qnty<=1} style={index === editqntyinputVIndexClicked ? (editqntyinputV ? {} : { display: 'none' }) : {}} onClick={() => handleOnClickRemove(propz.KEYID, propz.qnty, propz.discount, propz.amount, propz.taxamount, propz.discounttype)}>
                <RemoveIcon />
            </IconButton>}
            </div>
        </>
    )
}
export default Cartqnty;