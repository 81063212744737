import { React, useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "@material-ui/core/Input";
import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc, getDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import PaginationComp from '../components/PaginationComp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { format } from 'date-fns'
import Customerdelete from "./Customerdelete";
import Customeredit from "./Customeredit";
import Customerprofile from "./Customerprofile";
import { Link } from 'react-router-dom'

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { capitalize } from '@material-ui/core';
import { upperCase,titleCase } from "text-case";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Customeradd2 from "./Customeradd2";

function Customerslist() {



    const [data, setData] = useState([]);

    async function pullDataFromFirebase() {
        const colRef = query(collection(db, "Customers"),orderBy('name'));
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(JSON.stringify(data))
      }
      useEffect(() => {
          pullDataFromFirebase();    
      },[])

      const [currentPage, setCurrentPage] = useState(1)
      const [search, setSearch] = useState('')

      const [customer, setCustomer] = useState('all');

      const handleCustomer = (value) => {
        setCustomer(value)
        setCurrentPage(1)
      }
  
      const [customertype, setCustomertype] = useState('all');

      const handleCustomertype = (value) => {
        setCustomertype(value)
        setCurrentPage(1)
      }

    const SearchedCustomerlist = search ? data.filter(item => ((item.name.toLowerCase().includes(search.toLowerCase())) || ((item.customertype.toLowerCase()===search.toLowerCase())))) : data
    const filteredCustomerlist0 = customertype !== 'all' ? SearchedCustomerlist.filter(item => item.customertype === customertype) : SearchedCustomerlist
    const filteredCustomerlist = customer !== 'all' ? filteredCustomerlist0.filter(item => item.customer === customer) : filteredCustomerlist0
    const pageSize = 15
    const totalItems = filteredCustomerlist.length
    const totalPages = Math.ceil((totalItems / pageSize))
    const startIndex = (currentPage - 1) * pageSize
    let pageNumbers = []
    for (let i = 1; i <= totalPages; i++)
    {
        pageNumbers.push(i)
    }
    const paginatedCustomerlist = filteredCustomerlist.slice(startIndex, startIndex+pageSize)

    const callback = useCallback((c) => {
        console.log(c)
        setCurrentPage(c)
    })
 
    let delay_index = -1;

    return (
        <>
        <Customeradd2 />
        <div className="py-1 px-4 text-4xl">Customer Search</div>

<div className="px-4 py-2 flex justify-between space-x-4 flex-wrap">
<div className="flex space-x-2 items-center">
<div className="text-lg">Customer Name:</div>
<div>
                <TextField size="small" autoComplete="off"  onChange={(e) => setSearch(e.target.value)}
           label="Search Customer" variant="outlined" />
</div>
</div>
<div className="flex space-x-4 flex-wrap"> 
<div className="flex space-x-2 items-center">
<div className="text-lg">Customer Type:</div>
                <ToggleButtonGroup
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); 
        if(e.target.value=='all'){
            handleCustomer('all');
              }
        else if(e.target.value=='company'){
            handleCustomer('company');
          }
       else{ 
        handleCustomer('individual');
       }
        }
        }
    >
              <ToggleButton selected={customer=='all' ? true : false} value="all">All</ToggleButton>
      <ToggleButton selected={customer=='individual' ? true : false} value="individual">Individual</ToggleButton>
      <ToggleButton selected={customer=='company' ? true : false} value="company">Company</ToggleButton>
    </ToggleButtonGroup>
    </div>

    <div className="flex space-x-2 items-center">
<div className="text-lg">Customer Category:</div>
    <ToggleButtonGroup
      size="small"
      color="primary"
      exclusive
      onChange={(e)=>{console.log(e.target.value); 
        if(e.target.value=='all'){
            handleCustomertype('all');
              }
        else if(e.target.value=='regular'){
            handleCustomertype('regular');
          }
          else if(e.target.value=='prime'){
            handleCustomertype('prime');
          }
          else if(e.target.value=='institute'){
            handleCustomertype('institute');
          }
       else{ 
        handleCustomertype('wholesale');
       }
        }
        }
    >
              <ToggleButton selected={customertype=='all' ? true : false} value="all">All</ToggleButton>
      <ToggleButton selected={customertype=='regular' ? true : false} value="regular">Regular</ToggleButton>
      <ToggleButton selected={customertype=='prime' ? true : false} value="prime">Prime</ToggleButton>
      <ToggleButton selected={customertype=='institute' ? true : false} value="institute">Institute</ToggleButton>
      <ToggleButton selected={customertype=='wholesale' ? true : false} value="wholesale">Wholesale</ToggleButton>
    </ToggleButtonGroup>
    </div>
    </div>
    </div>

<div className="flex flex-wrap justify-center">
  
    {/* <div>{data.length}</div>
    <div>{data.filter(el => el.customer==="individual").length}</div>
    <div>{data.filter(el => el.customer==="company").length}</div>
    <div>{data.filter(el => el.customertype==="regular").length}</div>
    <div>{data.filter(el => el.customertype==="prime").length}</div>
    <div>{data.filter(el => el.customertype==="institute").length}</div>
    <div>{data.filter(el => el.customertype==="wholesale").length}</div>
    <div>{paginatedCustomerlist.length}</div>
    <div>{paginatedCustomerlist.filter(el => el.customer==="individual").length}</div>
    <div>{paginatedCustomerlist.filter(el => el.customer==="company").length}</div>
    <div>{paginatedCustomerlist.filter(el => el.customertype==="regular").length}</div>
    <div>{paginatedCustomerlist.filter(el => el.customertype==="prime").length}</div>
    <div>{paginatedCustomerlist.filter(el => el.customertype==="institute").length}</div>
    <div>{paginatedCustomerlist.filter(el => el.customertype==="wholesale").length}</div> */}

                            {paginatedCustomerlist.map((el, index) => {
                                             delay_index += (index!==0) ? 10 : 0;
                                             const delay = Math.max(0, delay_index*100);
                                            return(
                                                <Grow key={el.KEYID}
                                          in={true}
                                          style={{ transformOrigin: '0 0 0' }}
                                          {...({ timeout: 1000+delay })}
                                        >
                                         <Card key={el.KEYID} className="w-[370px] m-1 border border-indigo-500" >
                                      <CardContent sx={{ p:1, '&:last-child': { pb: 1 }}}>
                                          <div className="flex flex-row justify-between items-center h-[35px]">
                                              <div className="flex flex-row items-center">
                                              <div>{el.customer=="individual" ? <HomeIcon color="action"/> : <BusinessIcon color="action"/>}</div>
                                               <div className="pt-1 pl-2 font-bold text-sm " >{upperCase(el.name)}</div> 
                                                </div>
                                              <div>
                                {/* <Customeredit index={index} passdata={el.KEYID} /> */}
                                {/* <IconButton sx={{ p:1}}  component={Link} to= {'/customerprofile/'+el.KEYID }
 color="secondary" aria-label="Edit" size="small">
  <EditIcon fontSize="small" />
</IconButton>  */}
                                {/* <Customerprofile index={index} passdata={el.KEYID} /> */}
                                <Customerdelete index={index} passdata={el.KEYID} />
                                </div>
                                          </div>
                                          <hr className="my-1"/>
                                          <div className="flex flex-row justify-between">                                         
                                      <div>{capitalize(el.title)+'. '+titleCase(el.name)}</div>
                                      <div>{el.customer==="individual" && capitalize(el.gender)}</div>
                                      </div>
                                      {el.customer==="individual" ? el.dob2 : upperCase(el.gstnumber)}<br/>
                                      <div className="flex flex-row justify-between">
                                      <div>{capitalize(el.customer)}</div>
                                      <div>{capitalize(el.customertype)}</div>
                                      </div>
                                      <div className="flex flex-row justify-between">
                                      <div>{el.mobilenumber}</div>
                                      <div>{el.email}</div>
                                      </div>
                                      </CardContent>
                                      <CardActions>
                                        <div className="flex justify-between w-[100%]">
                                      <Customeredit index={index} passdata={el.KEYID} />
                                        <Button variant="outlined"  component={Link} to= {'/customerprofile/'+el.KEYID }  startIcon={<VisibilityIcon />}>
    <div fontSize="15px">&nbsp;VIEW PROFILE</div>
</Button>
</div>
                                      </CardActions>
                                    </Card>
                                        </Grow>
                                
                                            )
                            })}
                        </div>

            <PaginationComp passeddata={pageNumbers.length} parentCallback={callback}/>

            </>
    );
}

export default Customerslist