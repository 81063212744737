import { Scrollbars } from 'react-custom-scrollbars-2';
import Billdialog from '../../bills/Billdialog';
import Dayend from './Dayend';
import Hello from './Hello';
import Hello2 from './Hello2';


function Todaysales() {
  return (
    <>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
<Dayend />
{/* <Productsale /> */}
        </div>
      </Scrollbars>
    </>
  );
}

export default Todaysales;
