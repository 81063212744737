import { React, useState, useEffect, useCallback } from "react";
import { collection, query, onSnapshot,doc, getDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Addressadd from "./Addressadd";
import Addressdelete from "./Addressdelete";
import SimpleToast from '../components/Toast/SimpleToast';
import Addresseditform from "./Addresseditform";
import Addressedit from "./Addressedit";
import Addresssetasprimary from "./Addresssetasprimary";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';

function Addresseslist(props) {

    const [data, setData] = useState([]);

    async function pullDataFromFirebase() {
        const colRef = query(collection(db, "Employees",props.employeeid,"addresses"),orderBy('createdOn','asc'));
        onSnapshot(colRef, (snapshot) => {
          setData(snapshot.docs.map(doc => (doc.data())))
        })
        console.log(JSON.stringify(data))
      }
      useEffect(() => {
          pullDataFromFirebase();    
      },[])

      let delay_index = -1;

    return (
        
        <>
                     <SimpleToast />

         <Box className="flex flex-row justify-start flex-wrap w-[100%] ">
{/* <Card className="w-[200px] m-1 border-2 border-indigo-500">
<CardContent sx={{ p:1, '&:last-child': { pb: 1 }}} className="flex flex-col justify-center items-center h-[100%]">
<IconButton sx={{ m:1, border: 2, borderStyle:"dashed", borderColor:"indigo"}} color="secondary" aria-label="Add" size="small">
  <AddIcon fontSize="small" />
</IconButton>
<p className="m-0">Add Address</p>
</CardContent>
</Card> */}
{/* <Addressadd /> */}
        {data.map((el, index) => {
             delay_index += (index!==0) ? 10 : 0;
             const delay = Math.max(0, delay_index*100);
            return(
                <Grow key={el.KEYID}
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...({ timeout: 1000+delay })}
        >
         {/* <div key={index}>{el.addressline1}</div> */}
         <Card key={el.KEYID} className="w-[320px] m-1 border-2 border-indigo-500" >
      <CardContent sx={{ p:1, '&:last-child': { pb: 1 }}}>
          <div className="flex flex-row justify-between items-center h-[35px]">
              <div>
              {el.addresstype=="home" ? <HomeIcon color="action"/> : <BusinessIcon color="action"/>}
&nbsp;
                {el.addresstitle}
                </div>
              <div>
              {/* <IconButton sx={{ p:1}} color="secondary" aria-label="Edit" size="small">
  <EditIcon fontSize="small" />
</IconButton> */}
<Addressedit index={index} passdata={el.KEYID} employeeid={props.employeeid} />
{el.isprimary!="yes" && <Addressdelete index={index} passdata={el.KEYID}  employeeid={props.employeeid}/>}
{el.isprimary!="yes" && <Addresssetasprimary index={index} passdata={el.KEYID}  employeeid={props.employeeid}/>}
{el.isprimary!="no" && <Chip icon={<CheckCircleIcon />} label="Primary" />}

</div>
          </div>
          <hr className="my-1"/>

      {el.addressline1+","}<br/>
      {el.addressline2+","}<br/>
      {el.landmark!=="" && el.landmark}{el.landmark!=="" && ","}{el.landmark!=="" &&<br/>}
      {el.locality!=="" && el.locality}{el.locality!=="" && ","}{el.locality!=="" &&<br/>}
      {el.district+","}<br/>
      {el.state} - {el.pincode}<br/>
      </CardContent>
    </Card>
        </Grow>

            )
        })}        
                            </Box>
    
        </>
    );
}
export default Addresseslist