import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Billentryform from "./Billentryform";
import Chip from '@mui/material/Chip';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';

Billentryform.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Billentry(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});

  return (
    <>
<Chip icon={<AddIcon />} label="Add New Bill"  onClick={handleClickOpen}/>

      <Billentryform
        open={open}
        onClose={handleClose}
        KEYID={props.passdata}
        parentCallback={callback}
      />
    </>
  );
}