import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import Addresseditform from "./Addresseditform";
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';

Addresseditform.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Addressedit(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});

  return (
    <>
<IconButton sx={{ p:1}}  onClick={handleClickOpen} color="secondary" aria-label="Edit" size="small">
  <EditIcon fontSize="small" />
</IconButton> 
      <Addresseditform supplierid={props.supplierid}
        open={open}
        onClose={handleClose}
        KEYID={props.passdata}
        parentCallback={callback}
      />
    </>
  );
}