import { React, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Footnotes from "../components/Footnotes";
import { capitalize } from '@material-ui/core';
import Billsearch from './Billsearch';

export default function Billdialog(props) {
  const [open, setOpen] = useState(true);

  const searchbillnumbero = props.billnumbero;  //search by billnumbero
  const searchbillnumberdate = props.date;  //search by billnumber and date
  const searchbillnumber = props.billnumber;  
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.parentCallback2(false);
  };

  const callback = useCallback((c) => {
    setOpen(c);
});

  return (
    <>
<Dialog open={open} PaperProps={{
      style: { overflow:'overlay', borderRadius: 10,  minWidth:'625px' }}}>
      <DialogTitle sx={{px:2,py:1, backgroundColor:"blue", color:"white"}}>
        <div className='flex flex-row justify-between items-center'>
        <div>Bill View</div>
        <div> 
      <IconButton onClick={handleClose} aria-label="close"  style={{ color: 'white' }}>
  <CloseIcon />
</IconButton>
      </div>
      </div>
        </DialogTitle>
         <div className="flex flex-col items-center">
<Billsearch date={searchbillnumberdate} billnumbero={searchbillnumbero} billnumber={searchbillnumber.toString()}/>
</div>
    </Dialog>
    </>
  );
}