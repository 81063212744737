import { useEffect, useState, useCallback } from "react";
import { collection, query, onSnapshot,doc, getDoc, getDocs, setDoc, deleteDoc, orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import SimpleToast from '../components/Toast/SimpleToast';
import toast from 'react-hot-toast';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Cartremoveall from "./Cartremoveall";
import Cartremove from "./Cartremove";
import { numbertoCurrency } from "../components/NumbertoCurrency";
import { ToWords } from 'to-words';
import Skeleton from '@mui/material/Skeleton';
import Soldbyemp from "../components/Soldbyemp";

import { shopshortcode } from '../appGlobal.ts'
import Cartqnty from "./Cartqnty";
import Cartdiscount from "./Cartdiscount";
import Billpay from "./Billpay";
import Productnotes from "./Productnotes";

function Cart() {
  const [data, setData] = useState([]);

  const toWords = new ToWords();

  const [tippyData, setTippyData] = useState({
    status: 'unknown',
  });    
  const passDataT = (data) => {
    setTippyData(data);
  };

  async function pullDataFromFirebase() {
      const colRef = query(collection(db, "SHOP1LIVECART","CART1","PRODUCTS"));
      onSnapshot(colRef, (snapshot) => {
        setData(snapshot.docs.map(doc => (doc.data())))
      })    
    }
    
    useEffect(() => {
        pullDataFromFirebase();
    }, [])

    
    const [isdiscountcolTrue, setisdiscountcolTrue] = useState(false);
    const [isqntycolTrue, setisqntycolTrue] = useState(false);
    const [issoldbycolTrue, setissoldbycolTrue] = useState(false);

    async function pullDataFromFirebase2() {
      const docRef = doc(db, "Settings", "cartcolumncntrls");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          setTimeout(() => {
              setisdiscountcolTrue(docSnap.data().ssdiscountcol)
              setisqntycolTrue(docSnap.data().ssqntycol)
              setissoldbycolTrue(docSnap.data().sssoldbycol)
          }, 2000)

      } else {
          console.log("No such document!")
      }    
    }
    
    useEffect(() => {
        pullDataFromFirebase2();
    }, [])

      const handleFocus = (event) => event.target.select();


  return (
    <>
             <SimpleToast />
<div className="flex flex-row flex-wrap">
    <div>
    <div style={(data.length===0) ? {display:'none'} : {} }>
    <TableContainer component={Paper}
        style={{ maxWidth:1350,       
          minHeight: ((5 * 72.5) + 75.5),  
          maxHeight: ((10 * 72.5) + 75.5)  //(maxRows to display * ROW_HEIGHT + HEAD_ROW_HEIGHT)
        }}>
      <Table stickyHeader sx={{ minWidth: 1350 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={340}>Name</TableCell>
            {issoldbycolTrue && <TableCell align="center" width={200}>Sold By</TableCell>}
            <TableCell align="right" width={120}>Rate</TableCell>
            {isqntycolTrue && <TableCell align="center" width={250}>Qnty</TableCell>}
            {isdiscountcolTrue && <TableCell align="center" width={320}>Discount</TableCell>}
            <TableCell align="right" width={120}>Amount</TableCell>
            {/* <TableCell align="right">Tax Amount</TableCell> */}
            <TableCell align="center" width={50}>     
      <Cartremoveall  width={500}/>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,index) => (
            <TableRow
              key={row.KEYID}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              {issoldbycolTrue && <TableCell align="center"><Soldbyemp key={row.KEYID} passdata={row} /></TableCell>}
              <TableCell align="right">{row.rate}</TableCell>
              {isqntycolTrue &&               
              <TableCell>
              {row.location!='Others' && <Cartqnty key={row.KEYID} propz={row} index={index} />}
              </TableCell>}
              {isdiscountcolTrue &&
              <TableCell>
              <Cartdiscount key={row.KEYID} propz={row} index={index} />
              </TableCell>}
              <TableCell align="right">{row.amount}</TableCell>
              <TableCell align="center">
              <Cartremove key={row.KEYID} propz={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    <Skeleton style={(data.length!==0) ? {display:'none'} : {} } variant="rectangular" width={1350} height={(5 * 72.5) + 75.5} />
    </div>
    <Billpay/>
    </div>

    {/* <p>{(Math.round(billcost))-billcost}</p>

<p>{numbertoCurrency(Math.round(billcost))}</p>
<p>{toWords.convert(Math.round(billcost), { currency: true, ignoreZeroCurrency: true })}</p>
<PVinput2 billcostdata={Math.round(billcost)}  overalldiscount={overalldiscount} />
<p>{overalldiscount || ''}</p>
*/}


</>
  )
}

export default Cart;
