import { useEffect, useState, useCallback, useRef } from "react";
import { db } from "../../firebase-config";
import { collection, query, where, onSnapshot, doc, getDoc, getDocs, setDoc, deleteDoc, orderBy, increment, Timestamp } from "firebase/firestore";

function Dayend() {
  const [billdetails, setBilldetails] = useState(0);
  const [billprds, setBillprds] = useState(0);
  const [totalbillcost, setTotalbillcost] = useState(0);
  const [totalpcash, setTotalpcash] = useState(0);
  const [totalpcard, setTotalpcard] = useState(0);
  const [totalpupi, setTotalpupi] = useState(0);
  const [totalpfinance, setTotalpfinance] = useState(0);

  async function pullDataFromFirebase() {

    const DATE='30-08-2022';
    const q = query(collection(db, "SHOP1BILLS"));
    let totbills = 0;
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total=0;
        let pcash=0;
        let pcard=0;
        let pupi=0;
        let pfinance=0;
        setTotalbillcost(0)

        querySnapshot.forEach(async(docz) => {
        totbills=docz.data().totalbills;
        for(let i=1;i<=docz.data().totalbills;i++){
            const docRef = doc(db, "SHOP1BILLS",DATE,i.toString(),"CART1");
            const docSnap = await getDoc(docRef);         

            if (docSnap.exists()){
                total=(total + (docSnap.data().billcost));
                pcash=(pcash + (docSnap.data().pcash));
                pcard=(pcard + (docSnap.data().pcard));
                pupi=(pupi + (docSnap.data().pupi));
                pfinance=(pfinance + (docSnap.data().pfinance));
            }
        }
        setTotalbillcost(total)
        setTotalpcash(pcash)
        setTotalpcard(pcard)
        setTotalpupi(pupi)
        setTotalpfinance(pfinance)
    });

    })

    return () => {
      unsubscribe();
    }
  }
  useEffect(() => {
    pullDataFromFirebase();
  }, [])


  return (
    <>
    <div className="p-5 flex justify-between">
    <div className="text-3xl font-bold">Total Sale Amount: {totalbillcost}</div>
    <div className="text-3xl font-bold">CASH Amount: {totalpcash}</div>
    <div className="text-3xl font-bold">CARD Amount: {totalpcard}</div>
    <div className="text-3xl font-bold">UPI Amount: {totalpupi}</div>
    <div className="text-3xl font-bold">FINANCE Amount: {totalpfinance}</div>
    </div>
    </>
  )
}
export default Dayend