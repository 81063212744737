import { React, useState, useEffect } from "react";
import { collection, onSnapshot, doc, getDocs, setDoc, updateDoc, deleteDoc, writeBatch, increment, getDoc, Timestamp } from "firebase/firestore";
import {db} from "../firebase-config";
import Switch from '@mui/material/Switch';

export default function Footnotesinclude() {
    const [isTrue, setIsTrue] = useState(false);

    const handleOnChecked = (e) => {
        const custRef = doc(db, "SHOP1LIVECART","CART1"); //props.passdata cart product id
        setDoc(custRef, {      
          isfootnotesincluded:e.target.checked,
       },{merge:true}).then(()=>{
        setIsTrue(isTrue==true ? false : true)

       });
      }

    async function pullDataFromFirebase() {
        const docRef = doc(db, "SHOP1LIVECART", "CART1");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setTimeout(() => {
                setIsTrue(docSnap.data().isfootnotesincluded)
            }, 2000)

        } else {
            console.log("No such document!")
        }
    }
    useEffect(() => {
        pullDataFromFirebase();    
    },[])

      
  return (
<Switch edge="end"
   checked={isTrue}
   onChange={(e)=> {handleOnChecked(e)}}
  inputProps={{ 'aria-label': 'controlled' }}
/>
    )
}
