import './App.css';
import Billpage from './billpage/Billpage.tsx';
import Bills from './bills/Bills.tsx';
import Customers from './customers/Customers.tsx';

import { Route,Routes,Outlet,Navigate } from "react-router-dom"
import Navbar from './Navbar';
import { React, useState } from "react";
import Productentry from './productentry/Productentry.tsx';
import DlTable from './customers/Table/DlTable.tsx';
import { Offline, Online, Detector } from "react-detect-offline";
import Customerprofile from './customers/Customerprofile';
import Todaysales from './reports/todaysales/Todaysales.tsx';
import Employees from './employees/Employees.tsx';
import Employeeprofile from './employees/Employeeprofile';
import Suppliers from './suppliers/Suppliers.tsx';
import Supplierprofile from './suppliers/Supplierprofile';
import Billdescription from './productentry/Billdescription';
import Billglance from './billdetail/Billglance';
import Customeroverview from './customers/Customeroverview';
import Gstsummary from './reports/gstsummary/Gstsummary.tsx';
import Productaggregation from './productentry/Productaggregation.tsx';
import Productoverview from './productentry/Productoverview';
import Productsale from './reports/todaysales/Productsale.tsx';
import Employeeoverview from './employees/Employeeoverview';
import Supplieroverview from './suppliers/Supplieroverview';
import Billfind from './bills/Billfind';
import Walkouts from './walkouts/Walkouts';
import Settings from './settings/Settings';

function App() {
  
  const [role, setRole] = useState('Manager');

  const  ProtectedAdminRoute = () => {
    return role=="Admin" ? <Outlet /> : <Navigate to="/" />;
}

const  ProtectedManagerRoute = () => {
  return role=="Manager" ? <Outlet /> : <Navigate to="/" />;
}

  return (
    <>
    {/* <Detector
  render={({ online }) => (
    <div className={online ? "normal" : "warning"}>
      You are currently {online ? "online" : "offline"}
    </div>
  )} /> */}
    <Online>
<Navbar passrole={role}/>
    <Routes>
      <Route path="/" element={<Customers />} />

      {/* <Route exact path="/" element={<ProtectedAdminRoute />}>
      <Route path="/bills" element={<Bills />} />
      </Route> */}

      <Route exact path="/" element={<ProtectedManagerRoute />}>
      <Route path="/billpage" element={<Billpage />} />

      <Route path="/customers" element={<Customers />} />
      <Route path="/customeroverview" element={<Customeroverview />} />

      <Route path="/employees" element={<Employees />} />
      <Route path="/employeeoverview" element={<Employeeoverview />} />

      <Route path="/suppliers" element={<Suppliers />} />
      <Route path="/supplieroverview" element={<Supplieroverview />} />

      <Route path="/productentry" element={<Productentry />} />
      <Route path="/productaggregation" element={<Productaggregation />} />
      <Route path="/productoverview" element={<Productoverview />} />
      <Route path="/productsale" element={<Productsale />} />

      <Route path="/dltable" element={<DlTable />} />
      <Route path="/bills" element={<Bills />} />
      <Route path="/todaysales" element={<Todaysales />} />
      <Route path="/billglance" element={<Billglance />} />
      <Route path="/billfind" element={<Billfind />} />

      <Route path="/gstsummary" element={<Gstsummary />} />
      
      <Route path="/walkouts" element={<Walkouts />} />
      <Route path="/settings" element={<Settings />} />

      <Route exact path="/customerprofile/:id" element={<Customerprofile />} />
      <Route exact path="/employeeprofile/:id" element={<Employeeprofile />} />
      <Route exact path="/supplierprofile/:id" element={<Supplierprofile />} />

      <Route exact path="/billdescription/:id" element={<Billdescription />} />

      </Route>

      {/* <Route exact path="/" element={<ProtectedManagerRoute />}>
      <Route path="/productentry" element={<Productentry />} />
      </Route> */}

    </Routes>
    </Online>
    <Offline>OFFLINE</Offline>

    </>
  );
}

export default App;
