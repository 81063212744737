
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale.css';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { doc, setDoc, deleteDoc, increment } from "firebase/firestore";
import { db } from "../firebase-config";
import toast from 'react-hot-toast';

function Employeedelete(propz) {

    const hideOnInnerButtonPress = {
        name: "hideOnInnerButtonPress",
        defaultValue: true,
        fn(instance) {
            return {
                onCreate() {
                    instance.popper.addEventListener("click", (event) => {
                        if (
                            instance.props.hideOnInnerButtonPress &&
                            event.target.getAttribute("data-hide-menu-on-press") !== "false"
                        ) {
                            setTimeout(() => instance.hide(), 50);
                            return event;
                        }
                    });
                }
            };
        }
    }

    const handleOnClickDelete = () => {
        const docRef = doc(db, "Employees", propz.passdata);
        deleteDoc(docRef).then(() => {
                toast("Removed Successfully!");
            })
    }

    return (
        <Tippy content={
            <div className={" bg-white border border-solid border-red-500 ml-3 z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"}>
                <div className={"bg-text-black opacity-75 font-semibold p-3 mb-0 border-b border-solid border-slate-900 uppercase rounded-t-lg"}>
                    COFIRMATION
                </div>
                <div className="text-black p-3">
                    Are you sure, you want to delete this Employee?
                    
                </div>

                <div className="flex space-x-2 justify-end">
                    <div>
                        <button type="button" onClick={() => handleOnClickDelete()} className="inline-block px-6 py-2.5 mx-2 my-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out">YES</button>
                        <button type="button" className="inline-block px-6 py-2.5 mr-2 my-3 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">NO</button>
                    </div>
                </div>

            </div>
        }
            animation="scale"
            interactive="true"
            placement='bottom-end'
            inertia='true'
            trigger='click'
            plugins={[hideOnInnerButtonPress]}
            duration={[100, 125]}
        >
            <IconButton color="secondary" aria-label="clear">
                <ClearIcon />
            </IconButton>
        </Tippy>
    );
}

export default Employeedelete;
