import Purchasedetail from './Purchasedetail';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Billentry from './Billentry';
import Billentrylist from './Billentrylist';
import React from 'react';

function Productentry() {
    return (
               <>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
          <Billentry />
          <Billentrylist />
        </div>
      </Scrollbars>
               </>
    );
}

export default Productentry;
