import { useEffect, useState, useRef, forwardRef } from 'react';
import { collection, query, onSnapshot,doc,where, getDocs, getDoc, deleteDoc, orderBy, increment} from "firebase/firestore";
import {db} from "../firebase-config";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';


export default function Employeesold(props) {

  const [date, setDate] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    enumerateDaysBetweenDates(start, end);

  };


  var enumerateDaysBetweenDates = function(startDate, endDate) {

    var FirstDate = moment(startDate).startOf('day').subtract(1, "days");
    var lastDate = moment(endDate).startOf('day').add(1, "days");

    const newCheckeddates = [];
    if(moment(startDate).diff(endDate,'days')<0){
    while(FirstDate.add(1, 'days').diff(lastDate) < 0) {
        // date.push(moment(FirstDate.clone().toDate()).format("DD-MM-YYYY").toString());
        newCheckeddates.push(moment(FirstDate.clone().toDate()).format("DD-MM-YYYY").toString());
    }
  }
  if(moment(startDate).diff(endDate,'days')==0){
    newCheckeddates.push(moment(startDate).format("DD-MM-YYYY").toString());
  }
    setDate(newCheckeddates)
};


    const [billdetails, setBilldetails] = useState([]);

    const [totalbills,setTotalbills] = useState([]);
    const [deletedbills, setDeletedbills] = useState([]);


    async function pullDataFromFirebase() {
        setBilldetails([]);

        for(let d=0; d<date.length;d++){
              const docRef =doc(db, "SHOP1BILLS",date[d]);
            onSnapshot(docRef, (doc) => {
              setTotalbills(doc.data().totalbills);
              doc.data().deletedbills!=undefined ? setDeletedbills(doc.data().deletedbills) :setDeletedbills([]);
          });
   
   for(let i=1; i<=totalbills;i++){
    if(!deletedbills.includes(i)){
    fnbilldetails(date[d],i)
    }
   }
}
        }
    
useEffect(() => {
    pullDataFromFirebase();
  }, [totalbills,date])

    async function fnbilldetails(date,bno){

        const colRef = query(collection(db, "SHOP1BILLS",String(date),String(bno),"CART1","PRODUCTS"),where('soldbyKEYID', '==', props.employeeid));
          onSnapshot(colRef,async(snapshot) => {
            let newTasks = [];
            snapshot.docChanges().forEach(change => {
                    const taskData = change.doc.data();
                    newTasks.push(taskData);
            });
            setBilldetails(currentList => currentList.concat(newTasks));
        });

     }

     useEffect(() => {
        console.log(billdetails)
      }, [billdetails])
    


  return (
    <>
        <div>Total Products: {billdetails.length}</div>
        <div>Worth of Sold out:{billdetails.reduce((a,b)=>a+(b.amount || 0),0)}</div>
    <div className='flex'>
    <div className='min-w-[260px]'>
      <div className='fixed'>
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      maxDate={new Date()}
      selectsRange
      inline
    />
</div>
</div>
<div>
      {totalbills>0 && <div className="flex flex-col w-[560px]">
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className=" min-w-full sm:px-6 lg:px-8">

      <div>

          {billdetails.map((row,index) => {
         return (
          <div key={Number(index)+1}>
            <div className='flex p-2 border-2 border-indigo-500 w-[350px]'>
            <div className='w-[100px]'>
            <div>DATE</div>
            <div>BRAND</div>
            <div>CATEGORY</div>
            <div>SIZE</div>
            <div>COLOR</div>
<div>NAME</div>
<div>RATE</div>
<div>QNTY</div>
<div>AMOUNT</div>
</div>
            <div>
            <div>{moment(row.addedon.toDate()).format("DD-MM-YYYY").toString()}</div>
            <div>{row.brand}</div>
            <div>{row.category}</div>
            <div>{row.size}</div>
            <div>{row.color}</div>
<div>{row.name}</div>
<div>{row.rate}</div>
<div>{row.qnty}</div>
<div>{row.amount}</div>
</div>
</div>
           </div>
         )
       })}
</div>
</div>
</div>
</div>}
</div>
</div>
</>
  )
}
