import { useState } from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { NavLink, Link, useMatch, useResolvedPath } from "react-router-dom"

import { AnimateSharedLayout, motion } from "framer-motion";

import Fab from '@mui/material/Fab';
import { useHotkeys } from 'react-hotkeys-hook'
import Hamburger from 'hamburger-react'

import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import { brandname } from './appGlobal.ts'


import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import GroupIcon from '@mui/icons-material/Group';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ApiIcon from '@mui/icons-material/Api';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import GradingIcon from '@mui/icons-material/Grading';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import InventoryIcon from '@mui/icons-material/Inventory';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import DoNotStepIcon from '@mui/icons-material/DoNotStep';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

//const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

function Navbar(props) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
      <li className={isActive ? "selectednavitem" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    )
  }

  const navItems = ['Home', 'About', 'Contact'];

  useHotkeys('ctrl+z', () => {
    setOpen(prevState =>
      prevState == false ? true : false
    )
  },// Add your hotkeys options in here
    { enableOnTags: ["TEXTAREA", "INPUT", "SELECT"] } // To enable on ['TEXTAREA', 'INPUT'] 
  )

  const classNameFunc= ({ isActive }) => (isActive ? "activ_link" : "non_activ_link");

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav">
          <Toolbar>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {brandname}
            </Typography>
            {/* <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ color: '#fff' }}>
                  {item}
                </Button>
              ))}
            </Box> */}
          </Toolbar>
        </AppBar>
      </Box>
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '#menu.MuiDrawer-root > .MuiPaper-root': {
              // height: `calc(70% - ${drawerBleeding}px)`,
              height: `70%`,
              overflow: 'visible',
            },
            '#menu.MuiDrawer-root > .MuiBackdrop-root': {
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(4px)',
            }

          }}
        />
        <SwipeableDrawer id="menu"
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          // swipeAreaWidth={drawerBleeding}
          swipeAreaWidth={0}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <nav className="nav mt-3">
            {/* <Typography variant="appnametxt">We are using {process.env.REACT_APP_NAME}</Typography>               */}
            <Typography variant="appnametxt">{brandname}</Typography>              

<div className="flex flex-row h-full justify-start items-center flex-wrap gap-5">

<div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Home</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
              {props.passrole == "Manager" ? <NavLink to="/dashborad" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <DashboardIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Dashboard</Typography>
               </div>
              </NavLink > : null}
              </div>
              </div>



              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Customers</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
              {props.passrole == "Manager" ? <NavLink to="/customeroverview" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <TableRowsIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Overview</Typography>
               </div>
              </NavLink > : null}
              {props.passrole == "Manager" ? <NavLink to="/customers" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <GroupIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Details</Typography>
               </div>
              </NavLink > : null}
              </div>
              </div>


              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Employees</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
          {props.passrole == "Manager" ? <NavLink to="/employeeoverview" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <TableRowsIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Overview</Typography>
               </div>
              </NavLink > : null}
              {props.passrole == "Manager" ? <NavLink to="/employees" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <AssignmentIndIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Employees</Typography>
               </div>
              </NavLink > : null}
              </div>
              </div>



              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Suppliers</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
          {props.passrole == "Manager" ? <NavLink to="/supplieroverview" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <TableRowsIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Overview</Typography>
               </div>
              </NavLink > : null}
              {props.passrole == "Manager" ? <NavLink to="/suppliers" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <RecentActorsIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Suppliers</Typography>
               </div>
              </NavLink > : null}
              </div>
              </div>

              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Bills</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
              {props.passrole == "Manager" ? <NavLink to="/billpage" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <ReceiptIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Billpage</Typography>
               </div>
              </NavLink > : null}

              {props.passrole == "Manager" ? <NavLink to="/billglance" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <ContentPasteSearchIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Bill Glance</Typography>
               </div>
              </NavLink > : null}

              {/* {props.passrole == "Manager" ? <NavLink to="/billedit" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <ReceiptIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Edit Bill</Typography>
               </div>
              </NavLink > : null}

              {props.passrole == "Manager" ? <NavLink to="/billreturn" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <ReceiptIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Returns</Typography>
               </div>
              </NavLink > : null} */}

              {props.passrole == "Manager" ? <NavLink to="/bills" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <GradingIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">View Bills</Typography>
               </div>
              </NavLink > : null}
              
              {props.passrole == "Manager" ? <NavLink to="/billfind" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <FindInPageIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Find Bill</Typography>
               </div>
              </NavLink > : null}


              </div>
              </div>

              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Products</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
 
              {props.passrole == "Manager" ? <NavLink to="/productentry" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <InventoryIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Entry</Typography>
               </div>
              </NavLink > : null}

              {props.passrole == "Manager" ? <NavLink to="/productaggregation" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <ApiIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Aggregation</Typography>
               </div>
              </NavLink > : null}

              {props.passrole == "Manager" ? <NavLink to="/productoverview" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <ViewQuiltIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Overview</Typography>
               </div>
              </NavLink > : null}
              
              </div>
              </div>


              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Reports</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
              {props.passrole == "Manager" ? <NavLink to="/todaysales" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <TouchAppIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Today Sales</Typography>
               </div>
              </NavLink > : null}

              {props.passrole == "Manager" ? <NavLink to="/gstsummary" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <SummarizeIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Tax Summary</Typography>
               </div>
              </NavLink > : null}

              </div>
              </div>


              <div>
                <div className="pt-3 w-fit" >
                <Typography variant="menugrouptxt">Others</Typography>
                </div>
          <div className="border-t-2 border-indigo-500 w-fit py-3 flex flex-row justify-start items-center flex-wrap gap-5">
              {props.passrole == "Manager" ? <NavLink to="/walkouts" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <DoNotStepIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Walkouts</Typography>
               </div>
              </NavLink > : null}

              {props.passrole == "Manager" ? <NavLink to="/settings" onClick={toggleDrawer(false)} className= {classNameFunc}>
              <div className="text-center">
             <motion.div className="indiv mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
             <div className="flex flex-col h-full justify-center items-center flex-wrap">
              <SettingsSuggestIcon fontSize="large"/>
              </div>
               </motion.div>
               <Typography variant="menutxt">Settings</Typography>
               </div>
              </NavLink > : null}

              </div>
              </div>


              </div>
              {/* <ul>
                <CustomLink to="/">Home</CustomLink>
                {props.passrole == "Admin" ? <CustomLink to="/bills">Bills</CustomLink> : null}
                {props.passrole == "Manager" ? <CustomLink to="/billpage" onClick={toggleDrawer(false)}><div>Billpage</div></CustomLink> : null}
                {props.passrole == "Manager" ? <CustomLink to="/customers" onClick={toggleDrawer(false)}><div>Customers</div></CustomLink> : null}
                {props.passrole == "Manager" ? <CustomLink to="/productentry">Product Entry</CustomLink> : null}
              </ul> */}
            </nav>
          </StyledBox>
        </SwipeableDrawer>
      </Root>
      <Fab sx={{
        position: "fixed",
        bottom: '20px',
        right: '20px',
        zIndex: '2000',
        width: '130px'
      }} variant="extended" color="primary" onClick={open == false ? toggleDrawer(true) : toggleDrawer(false)}>
        <Hamburger toggled={open} size={20} />
        {open == false ? 'MENU' : 'CLOSE'}
      </Fab>
    </>
  );
}


export default Navbar;
