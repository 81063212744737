import { useEffect, useState, useRef } from "react";
import { collection, onSnapshot, query, doc, getDoc } from "firebase/firestore";
import {db} from "../firebase-config";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { shop1addressary, website } from '../appGlobal.ts'
import logo from "../assets/images/logo/logo.png"
import Typography from '@mui/material/Typography';
import QRCode from 'react-qr-code';
import {QRCodeSVG} from 'qrcode.react';
import moment from "moment";
import Taxsummarybill from './Taxsummarybill';
import { useReactToPrint } from 'react-to-print';
import Footnotes from "../components/Footnotes";
import { capitalize } from '@material-ui/core';

export default function Billsearch(props) {

    const [billprds, setBillprds] = useState([]);
const [billdetails,setBilldetails] = useState([]);

const searchbillnumbero = props.billnumbero;  //search by billnumbero
const searchbillnumberdate = props.date;  //search by billnumber and date
const searchbillnumber = props.billnumber;  

    async function pullDataFromFirebase() {
      console.log(props.billnumbero)
      if(props.billnumbero!==undefined && props.billnumbero!==0){
        const q = query(collection(db, "SHOP1BILLS"));
        let totbills = 0;
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach(async(docz) => {
            totbills=docz.data().totalbills;
            for(let i=1;i<=docz.data().totalbills;i++){
                const docRef = doc(db, "SHOP1BILLS",docz.id,i.toString(),"CART1");
                const docSnap = await getDoc(docRef);         

                if (docSnap.exists() && docSnap.data().billnumbero===searchbillnumbero){
                    //setBilldetails(docSnap.data())

                    const docRefBills = collection(db, "SHOP1BILLS",docz.id,i.toString());
                    onSnapshot(docRefBills, (snapshot) => {
                        setBilldetails(snapshot.docs.map(doc => (doc.data())))
                        })

                    const docRefBillsz = collection(db, "SHOP1BILLS",docz.id,i.toString(),"CART1","PRODUCTS");
                    onSnapshot(docRefBillsz, (snapshot) => {
                        setBillprds(snapshot.docs.map(doc => (doc.data())))
                        })

                }
            }
        });
        
        })
      }
      else{
        const docRefBills = collection(db, "SHOP1BILLS",searchbillnumberdate,searchbillnumber);
        onSnapshot(docRefBills, (snapshot) => {
            setBilldetails(snapshot.docs.map(doc => (doc.data())))
            })

        const docRefBillsz = collection(db, "SHOP1BILLS",searchbillnumberdate,searchbillnumber,"CART1","PRODUCTS");
        onSnapshot(docRefBillsz, (snapshot) => {
            setBillprds(snapshot.docs.map(doc => (doc.data())))
            })
      }
    }

    
useEffect(() => {
    pullDataFromFirebase();
  }, [])

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>

         {billprds.length>0 && <button onClick={handlePrint}>Print this out!</button>}

    {billdetails.map((row,i) => {
return (
          <div className='w-[595px]'  key={i}>
<div ref={componentRef} className='w-[100%]'>

  <div className='flex flex-row items-center justify-between'>
    <div className='flex flex-row items-center'>
<div className='w-[100px] flex justify-center'>
  <img className="p-1 w-20 h-20 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
 src={logo}
  alt="logo" />
  </div>
  <div>
  {shop1addressary.map(
      (el)=>{
          return(               
              <div>
                <div className='text-lg font-medium'>{el.shopname}</div>
                <div>{el.al1}</div>
                <div>{el.al2}</div>
                <div>{el.mobile}</div>
                <div>{el.gstin}</div>
              </div>
          )
      }
  )}
  </div>
  </div>

  {billdetails.map((row,i) => (
  <div className='flex flex-col items-center p-5'>
    <QRCodeSVG size={64} value={row.billnumbero.toString()} />
    <p>{row.billnumbero}</p>
  </div>))}

  </div>
  <hr className="my-1 border-1 border-black"/>


  <div className='flex flex-row justify-between pt-1 px-4 w-[100%]'>
    <div>Bill No.{row.billnumbero}/Bill Id:{row.billid}</div>
    <div>{moment(row.billedon.toDate()).format("DD-MM-YYYY hh:mm:ss A").toString()}</div>
  </div>

  {row.custname!='' && <div className='flex flex-row justify-between pt-1 px-4 w-[100%]'>
    Customer Name: {capitalize(row.custname)}
    </div>}

      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={200}>Name</TableCell>
            <TableCell align="right" width={100}>Rate</TableCell>
            <TableCell align="center" width={50}>Qnty</TableCell>
            {/* <TableCell align="right" width={120}>Discount</TableCell> */}
            <TableCell align="right" width={100}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billprds.map((row,index) => (
            <TableRow
              key={row.KEYID}
              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.rate}</TableCell>
              <TableCell align="center" >{row.qnty}</TableCell>
              {/* <TableCell align="right">{row.amount}</TableCell> */}
              <TableCell align="right">{(row.rate * row.qnty).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>


      <Table aria-label="simple table">
        <TableBody sx={{ 'td, th': { padding: '5px 16px' } }}>
          {billdetails.map((row,i) => (
            <>
            <div className='flex justify-between'>
              <div>
              <TableRow key={i+'a'}>
              <TableCell align="right"><div  className='font-medium'>Total Items</div></TableCell>
              <TableCell align="right" width={100}>{row.totalitems}</TableCell>
            </TableRow>
            <TableRow key={i+'b'}>
              <TableCell align="right"><div  className='font-medium'>Total Quantities</div></TableCell>
              <TableCell align="right" width={100}>{row.totalqnty}</TableCell>
            </TableRow>
              </div>
            <div>
            <TableRow key={i}>
              <TableCell align="right"><div  className='font-medium'>Total Amount</div></TableCell>
              <TableCell align="right" width={100}>{(row.billcostsub)}</TableCell>
            </TableRow>
            {row.isoveralldiscount===true ? <TableRow key={i+1}>
              <TableCell align="right"><div  className='font-medium'>Discount</div></TableCell>
              <TableCell align="right" width={100}>{row.overalldiscount}</TableCell>
            </TableRow> : 
             <TableRow key={i+1}>
            <TableCell align="right"><div  className='font-medium'>Discount</div></TableCell>
            <TableCell align="right" width={100}>{row.totaldiscount}</TableCell>
          </TableRow>
            }
            <TableRow key={i+2}>
                <TableCell align="right"><div  className='font-medium'>Amount to Pay</div></TableCell>
                <TableCell align="right" width={100}>{row.billcost}</TableCell>
              </TableRow>
              </div>
              </div>

              {row.isfootnotesincluded && <Footnotes />}

              <TableRow key={i+3}>
              <TableCell colSpan={2}>

              <Taxsummarybill key={row.id} billdate={row.date}  billnumber={row.billnumber.toString()} taxtype={row.taxtype} />
              <div className='flex justify-between items-center'>
              <table className="border text-center">
        <thead className="border-b">
            {Number(row.pcash)!==0 && <th className="px-1 py-1 border-r"><td className='text-sm text-center'>CASH PAY</td></th>}
            {Number(row.pcard)!==0 && <th className="px-1 py-1 border-r"><td className='text-sm text-center'>CARD PAY</td></th>}
            {Number(row.pupi)!==0 && <th className="px-1 py-1 border-r"><td className='text-sm text-center'>UPI PAY</td></th>}
            {Number(row.pfinance)!==0 && <th className="px-1 py-1 border-r"><td className='text-sm text-center'>FINANCE PAY</td></th>}
        </thead>
        <tbody>
            <tr>
            {Number(row.pcash)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(row.pcash)}</td>}
            {Number(row.pcard)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(row.pcard)}</td>}
            {Number(row.pupi)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(row.pupi)}</td>}
            {Number(row.pfinance)!==0 && <td className='text-sm text-center text-gray-900 whitespace-nowrap border-r'>{Number(row.pfinance)}</td>}
            </tr>
        </tbody>
    </table>
                <div>
              <div>Thank You!</div>
              <div>visit: {website}</div>
              </div>
              <div className='p-2'>
              <QRCode size={32} value={website}/>
              </div>
              </div>
              </TableCell>
</TableRow>

              </>
              
          ))}


        </TableBody>
      </Table>
      </div>
      </div>
    )})}
    </>
  )
}
