import { React, useState, useEffect, useCallback, forwardRef } from "react";
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import defaultavatarmale from "../assets/images/maleicon.png"
import defaultavatarfemale from "../assets/images/femaleicon.png"
import defaultavatarbusiness from "../assets/images/businessicon.png"
import Dldatepick from "../components/Dldatepick";
import ImageConverter from "../components/ConvertImageToBase64";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useForm, Controller } from "react-hook-form";

import { TextField, Button } from "@mui/material";
import { collection, query, onSnapshot,doc,limit, getDoc, addDoc, updateDoc, setDoc,orderBy, increment, Timestamp} from "firebase/firestore";
import {db} from "../firebase-config";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import moment from "moment";
import { format } from 'date-fns'
import Supplierautocomplete from "./Supplierautocomplete";

const schema = z.object({
    invoicereference: z.string()
    .min(3, { message: 'invoicereference must be greater than 3 characters' }),
    info:z.string(),
  });

export default function Billentryform(props) {
  const { open,onClose } = props;

  const [pdate, setPdate] = useState(new Date());
  const [invref, setInvref] = useState('');
  const [suppid, setSuppid] = useState('');
  const [suppname, setSuppname] = useState('');

  const handleChange = (newValue) => {
    setPdate(newValue);
  };

const { control, formState: { isSubmitSuccessful,errors }, reset, handleSubmit, register,setValue, getValues} = useForm({
  resolver: zodResolver(schema),
  defaultValues: {
    dop:pdate,
    invoicereference:invref,
    supplierid:suppid,
    info:""
  },
});

useEffect(() => {

  if (isSubmitSuccessful) {
    reset();
    setPdate(new Date());
    setInvref('')
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isSubmitSuccessful]);

 //const onSubmit = data => console.log(data);
const onSubmit = async data => {
  if(suppid!='' && data.invoicereference!=''){
    const newId = doc(collection(db, 'Billentry')).id;
    const docRef = query(doc(db, "Billentry",newId));
    setDoc(docRef, {
      KEYID: newId,
      invoicereference: data.invoicereference,
      supplierid: suppid,
      suppliername: suppname,
      info: data.info || "",
      dop: pdate,
      dop2:format(new Date((moment(pdate, 'DD-MM-YYYY').toISOString())), 'dd-MM-yyyy'),
      createdon:Timestamp.now()
    },{merge:true}).then(()=>{
      console.log("submitted");
    }).then(()=>{
      props.parentCallback(false)
    })
  }
};
console.log(errors);

const callbacksuppkeyid = useCallback((id,name) => {
  console.log(id)
  setSuppid(id)
  setSuppname(name)
  })

  return (
    <Dialog open={open} PaperProps={{
      style: { borderRadius: 10 } }}>
      <DialogTitle sx={{px:2,py:1, backgroundColor:"blue", color:"white"}}>
        <div className='flex flex-row justify-between items-center'>
        <div>Add New Bill</div>
        <div> 
      <IconButton onClick={onClose} aria-label="close"  style={{ color: 'white' }}>
  <CloseIcon />
</IconButton>
      </div>
      </div>
        </DialogTitle>

      <div className='p-3'>

           <form onSubmit={handleSubmit(onSubmit)}>

<Supplierautocomplete parentCallback={callbacksuppkeyid}/>
{/* {suppid} */}

<div className="pt-5 flex flex-row justify-between flex-wrap">
    <div className="w-[49%]">
    <LocalizationProvider dateAdapter={AdapterDateFns}>
   <MobileDatePicker
          label="Date of Birth"
          inputFormat="dd-MM-yyyy"
          value={pdate}
          onChange={handleChange}
          maxDate={new Date()}
          disableFuture={true}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        </LocalizationProvider>
</div>

    <div className="w-[49%]">
    <Controller
        name="invoicereference" {...register("invoicereference")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="Invoice Number" variant="outlined" {...field} inputProps={{ maxLength:40 }}/>}
      />
</div>
      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
    <div className="w-[100%]">
    <Controller
        name="info" {...register("info")}
        control={control}
        ref={null}
        render={({ field }) => <TextField type="tel" className="w-[100%]" size="small" autoComplete="off" 
          label="Info" variant="outlined" {...field} inputProps={{ maxLength:40 }}/>}
      />
</div>
      </div>

      <div className="pt-4 flex flex-row justify-between flex-wrap">
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
</div>

    </form>
    </div>
    </Dialog>
  );
}
