import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { collection, query, onSnapshot,doc, getDocs, getDoc, deleteDoc, orderBy, increment} from "firebase/firestore";
import {db} from "../firebase-config";
import Billsearch from './Billsearch';

import isWeekend from 'date-fns/isWeekend';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from "moment";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Getbilldetailsbydatenumber from '../components/Getbilldetailsbydatenumber';

import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Zoom from '@mui/material/Zoom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Paper } from '@mui/material';
export default function Billscollection() {

    const [selectedddate, setSelectedddate] = useState(new Date());
    const [checkedbills, setCheckedbills] = useState([]);

    const [totbills, setTotbills] = useState(0);
    const [deletedbills, setDeletedbills] = useState([]);

    const [selecteddatedetails, setSelecteddatedetails] = useState([]);
    const [selectedbillprds, setSelectedbillprds] = useState([]);
    

      const billhandleToggle = (value) => () => {
        const currentIndex = checkedbills.indexOf(value);
        const newCheckedbills = [...checkedbills];
    
        if (currentIndex === -1) {
          newCheckedbills.push(value);
          newCheckedbills.sort();

        } else {
          newCheckedbills.splice(currentIndex, 1);
          selectedbillprds.splice(currentIndex, 1);
        }
    
        setCheckedbills(newCheckedbills);
  
     callbills(newCheckedbills.toString());
      };

      async function callbills(newCheckedbill){
        if(newCheckedbill.length>0){
  
  for(let i=0;i<newCheckedbill.length;i++){
        const docRef = doc(db, "SHOP1BILLS", String(selectedddate));
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    setSelecteddatedetails(docSnap.data());
      const colRef = collection(db, "SHOP1BILLS", String(selectedddate), newCheckedbill[i],"CART1","PRODUCTS");
      onSnapshot(colRef, (snapshot) => {
        setSelectedbillprds(snapshot.docs.map(doc => (doc.data())))
      })
  
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
  }
        }
        else{
          setSelecteddatedetails()
        }
  
      }

      async function calltotalbillsonthisdate(newChecked){  
        setCheckedbills([]);
if(newChecked.length>0){
  const docRefBills =doc(db, "SHOP1BILLS",String(newChecked));
  const docSnap = await getDoc(docRefBills);
  if (docSnap.exists()) {
  onSnapshot(docRefBills, (doc) => {
    setTotbills(doc.data().totalbills);
    doc.data().deletedbills!=undefined ? setDeletedbills(doc.data().deletedbills) :setDeletedbills([]);
});
  }
  else{
    setTotbills(0);
  }
}
else{
  setTotbills(0);
}
      }

      const [selectedValue, setSelectedValue] = useState();
      const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };

    return (
        <>
        <div className='flex'>
        <div className='min-w-[600px]'>
<div className='fixed'>
<div>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        orientation="landscape"
        openTo="day"
        disableFuture="true"
        value={new Date((moment(selectedddate, 'DD-MM-YYYY').toISOString()))}
        onChange={(newValue) => {
          setSelectedddate(moment(newValue).format('DD-MM-YYYY'));
          setCheckedbills([]);
        calltotalbillsonthisdate(moment(newValue).format('DD-MM-YYYY'))
        //console.log(selectedddate)
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
    </div>

    <div style={{maxHeight: 'calc(100vh - 400px)', overflow: 'auto'}}>

      <List row >
      {[...Array(totbills)].map((x,index) => {
        if(!deletedbills.includes(index+1)){
                  const labelId = `checkbox-list-label-${index+1}`;
                  return (
                    <>
        <Zoom in={true}>
          <ListItem >
          <ListItemButton  role={undefined} onClick={billhandleToggle(index+1)} dense>
            <ListItemIcon>
              
            <Checkbox hide
             icon={<CircleUnchecked />}
             checkedIcon={<CircleCheckedFilled />}
                    edge="start"
                    checked={checkedbills.indexOf(index+1) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
            </ListItemIcon>
            <Zoom in={true}>
            <ListItemText key={index+1} id={labelId} primary={<Getbilldetailsbydatenumber date={selectedddate} billnumber={index+1} />} />
         </Zoom>
          </ListItemButton>
        </ListItem>
        </Zoom> 
        </>
                  )
        }
        })}
        </List>
        </div>

</div>
      </div>
      <div className='min-w-[600px]'>
      {checkedbills.map((x,index) => {
        console.log(selectedddate,x)

              return ( 
                              <Billsearch key={x} date={selectedddate} billnumber={x.toString()}/>
              )
            })}
            </div>
</div>
      {/* <div>{JSON.stringify(selectedddate)}</div>
      <div>{totbills}</div>
      <div>{JSON.stringify(checkedbills)}</div>
      <div>{JSON.stringify(selectedbillprds)}</div>  */}
      </>
    );
  }
