import Currentbillid from '../billpage/Currentbillid';
import Cart from '../billpage/Cart';
import { Scrollbars } from 'react-custom-scrollbars-2';

function Billpage() {
  return (
    <>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={'100vh'} autoHide>
        <div className='pt-[55px] px-[5px]'>  {/* padding 48px (+7px safe padding) top - height of muitoolbar definde in App.css .MuiToolbar-root */}
          <Currentbillid />
          <Cart />
        </div>
      </Scrollbars>
    </>
  );
}

export default Billpage;
